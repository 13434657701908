import React, { useState, useEffect, useContext } from "react";
import { trackPromise } from "react-promise-tracker";
import { customFormDataPost } from '../../helper/api-helper/apiHerlpers';
import { ReactSortable } from "react-sortablejs";
import $ from 'jquery';
import apiUrls from '../../constants/apiUrls';
import commonParams from '../../constants/apiCommonParams';
import apis from '../../services/apis'
import { getTaskList } from "../../helper/context/actions";
import { toast } from "react-toastify";
import { useSmarkDispatch } from "../../Context/KeycloakContext";
import { Link } from 'react-router-dom';
import './userStory.css';
import { useKeycloakState } from '../../Context/KeycloakContext';

const UserStory = (props) => {

    const dispatch = useSmarkDispatch();
    const keycloackValue = useKeycloakState();
    const [isOpened, setIsOpened] = useState(false);
    const [userStories, setUserStories] = useState([]);
    const storedToken = localStorage.getItem('keycloakToken');
    useEffect(() => { getUserStory(); }, []);

    const getUserStory = async () => {
        let releaseId = props.header_data['masterReleaseId'];
        trackPromise(
            apis.getUserStories(releaseId,storedToken).then((response) => {
                if (response) {
                    setUserStories(response.userStoryDetailList,storedToken);
                }
            }).catch((error) => {
                console.log(error);
            })
        );
    }

    const toggleStoryTasks = (event) => {
        const storyTasks = $(event.target).hasClass("show-hide-task") ? $(event.target).next() : $(event.target).parent().next();
        storyTasks.toggleClass('task-details-collapse')
        setIsOpened(isOpened => !isOpened);
    }

    const editStory = (userStory) => {
        props.editUserStory(true, userStory, props.header_data, 'edit');
    }

    const showStoryTasksInBoard = (userStoryId) => {
        let data = {
            masterProjectId: props.header_data['masterProjectId'],
            masterReleaseId: props.header_data['masterReleaseId'],
            masterUserId: props.header_data['masterUserId'],
            userStoryId: userStoryId ? userStoryId : 0,
            filterStartDate: props.header_data['filterStartDate'],
            filterEndDate: props.header_data['filterEndDate']
        }

        trackPromise(
            getTaskList(dispatch, data,storedToken).then((response) => {
                if (response && response.taskDetails && response.taskDetails.length > 0) {
                    toast.success('Task list fetch successfully!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    props.hideUserStoryList(false, userStoryId, props.header_data, 'showTask');
                } else {
                    toast.warning('No record found!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
        );
    }

    const sortStoryEnd = async function (order, sortable, evt) {
        if (order.item) {
            var selectElement = $(order.item);
            var prev = selectElement.prev().data("priority");
            var next = selectElement.next().data("priority");
            var priority = 5;
            if (prev === undefined) {
                priority = 1;
            }
            else if (next === undefined) {
                if (prev < 5) priority = prev + 1;
                else priority = 5;
            }
            else if (prev === next) {
                priority = prev;
            }
            else if (next - prev >= 1) {
                priority = prev + 1;
            }
            else if (next === 5) {
                priority = 5;
            }

            var newState = userStories;
            setUserStories([]);
            newState[order.newIndex].userStoryTO.priority = priority;
            setUserStories(newState);
            var storyId = newState[order.newIndex].userStoryTO.userStoryId;

            var formData = new FormData();
            formData.append('userstoryid', storyId);
            formData.append('priority', priority);
            formData.append('applicationid', commonParams.applicationid);
            formData.append('siteid', commonParams.siteid);
            formData.append('userid', commonParams.userid);
            formData.append('swcmTicket', commonParams.swcmTicket);

            await customFormDataPost(apiUrls.updateStoryPriority, formData,storedToken);
        }
    }

    return (
        <div className="form-sec shadow-lg add-form-sec-left">
            <div className="form-blog w-100 float-left position-relative" style={{ overflowX: 'auto' }}>
                <span onClick={() => { props.hideUserStoryList(false, null) }} className="close-form-icon"><i className="fa fa-times" aria-hidden="true"></i></span>
                <h2>User Story List</h2>
                <div id="sortStory" className="w-100 float-left pl-2 pr-2">
                    <ReactSortable
                        list={userStories}
                        setList={setUserStories}
                        animation={150}
                        onEnd={sortStoryEnd}
                    >
                        {userStories && userStories.map((story) =>
                            <div key={story.userStoryTO.userStoryId} style={{ backgroundColor: story.userStoryTO.colorCode }} data-key={story.userStoryTO.userStoryId} data-priority={story.userStoryTO.priority} className="w-100 float-left block shadow-sm storyBox">
                                <div className="block-box w-100 float-left position-relative">
                                    <div className="heading-tp-left show-hide-task" onClick={toggleStoryTasks}>
                                        <span className="float-left">{story.userStoryTO.title}</span>
                                        <span className="float-right" style={{ color: story.userStoryTO.colorCode }}>{story.userStoryTO.priority}</span>
                                    </div>
                                    <div className="my-1 w-100 float-left task-details-collapse" >
                                        <div className="cmt-box w-100 float-left">
                                            <div className="open-task border-bottom w-100 float-left pb-1 mb-1">
                                                <p className="font-14 pb-1"><span><strong className="time red-clr">Open tasks</strong></span></p>
                                                {
                                                    story.taskList.filter(x => x.status.toLowerCase() !== "accepted").length > 0 ?
                                                        story.taskList.map((task) =>
                                                            task.status.toLowerCase() !== "accepted" ?
                                                                <p key={task.taskId} className="w-100 float-left p-btm-1">
                                                                    <span className="float-left">
                                                                        <strong className="time">{task.title} : </strong>
                                                                        {task.description}
                                                                    </span>
                                                                    <span className="float-right"> 
                                                                        <Link to={"/dashboard?t=" + task.taskId} className="btn btn-sm btn-light border border-dark text-right py-0 px-1"  target="_blank">
                                                                            <small>View</small>
                                                                        </Link>
                                                                    </span>
                                                                </p>
                                                                : "")
                                                        :
                                                        <p className="w-100 float-left p-btm-1">
                                                            <span className="float-left">No record found.</span>
                                                        </p>
                                                }
                                            </div>
                                            <div className="closed-task w-100 float-left">
                                                <p className="font-14 pb-1"><span><strong className="time red-clr">Closed tasks</strong></span></p>
                                                {
                                                    story.taskList.filter(x => x.status.toLowerCase() === "accepted").length > 0 ?
                                                        story.taskList.map((task) =>
                                                            task.status.toLowerCase() === "accepted" ?
                                                                <p key={task.taskId} className="w-100 float-left p-btm-1">
                                                                    <span className="float-left"><strong className="time">{task.title} : </strong>{task.description}</span>
                                                                    <span className="float-right">
                                                                    <Link to={"/dashboard?t=" + task.taskId} className="btn btn-sm btn-light border border-dark text-right py-0 px-1"  target="_blank">
                                                                        <small>View</small>
                                                                    </Link>
                                                                    </span>
                                                                </p> : "")
                                                        :
                                                        <p className="w-100 float-left p-btm-1">
                                                            <span className="float-left">No record found.</span>
                                                        </p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="assignee w-100 float-left">
                                        <button type="button" onClick={() => { editStory(story) }} className="btn btn-sm btn-light border-dark mr-1"><small>Edit</small></button>
                                        <button type="button" onClick={() => { showStoryTasksInBoard(story.userStoryTO.userStoryId) }} className="btn btn-sm btn-light border-dark"><small>Show Task</small></button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </ReactSortable>

                </div>
                <p className="text-center">{userStories && userStories.length === 0 ? 'No record found!' : ''}</p>
            </div>
        </div >
    )

}

export default UserStory;