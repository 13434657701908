import React, { useState,useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import apiUrls from '../../constants/apiUrls';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import { customFormDataPost } from '../../helper/api-helper/apiHerlpers';
import apiCommonParams from '../../constants/apiCommonParams';
import './project.css';
import { useKeycloakState } from '../../Context/KeycloakContext';

const Project = (props) => {

    const onHideProject = () => {
        props.onHideProjectEvent(false);
    }
    const keycloackValue = useKeycloakState();
    const storedToken = localStorage.getItem('keycloakToken');
    const [projectName, setProjectName] = useState('');
    const [error, setError] = useState(false);
    const projectNameChangeHandler = (event) => {
        setProjectName(event.target.value);
        setError(false);

    }
    const submitButtonHandler = (event) => {
        event.preventDefault();
        if (projectName === '') {
            setError(true);
            return;
        }
        var body = new FormData();
        const data = {
            "projectId": 0,
            "projectName": projectName,
            "applicationId": apiCommonParams.applicationid,
            "siteId": apiCommonParams.siteid,
            "userCreated": apiCommonParams.userCreated,
            "userModified": apiCommonParams.userModified,
            "createdTime": apiCommonParams.createdTime,
            "updatedTime": apiCommonParams.updatedTime

        }

        body.append("project", JSON.stringify(data));
        body.append("swcmTicket", apiCommonParams.swcmTicket);

        trackPromise(
            customFormDataPost(apiUrls.saveProject, body,storedToken).then((rerult) => {
                if (rerult.response === 'success') {
                    props.loadMasterProjectDdl(storedToken);
                    toast.success('Project added successfully', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    props.onHideProjectEvent(false);
                } else {
                    toast.warning(rerult.response, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }).catch((error) => {
                toast.error("Error", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        );
    }

    return (
        <Modal show={true} onHide={onHideProject}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="form-group">
                        <label htmlFor="">Project name</label>
                        <input required
                            type="text"
                            onChange={projectNameChangeHandler}
                            className="form-control required-field"
                            placeholder="Project name" />
                        {error && <span className="text-danger">Project name is required</span>}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" onClick={submitButtonHandler}>
                    Submit
                </Button>

            </Modal.Footer>
        </Modal>
    );
}
export default Project;
