export const customFetch = async function (url,bearerToken) {
    return new Promise(function (resolve) {
        fetch(url, {
            headers: { 'Content-Type': 'application/json',
            Authorization: bearerToken }
        }).then(response => {
            return response.json();
        }).then(data => resolve(data))
            .catch((e, i, o) => {
                resolve({ status: false })
            });
    })
}

export const customFetch3 = async function (url,bearerToken) {
    return new Promise(function (resolve) {
        fetch(url,{
            headers: { 
            Authorization: bearerToken }
        }).then(response => response.text())
            .then(data => {
                resolve(data)
            })
        // .catch((e) => {
        //     console.log(e);
        //     resolve({ status: false })
        // });
    });
}

export const customFetch2 = async function (url) {
    return new Promise(function (resolve) {
        fetch(url, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            return response.json();
        }).then(data => resolve(data))
            .catch((e, i, o) => {
                resolve({ status: false })
            });
    })
}
export const customFetch1 = async function (url,bearerToken) {
    return new Promise(function (resolve) {
        fetch(url,{
            headers: { 
            Authorization: bearerToken }
        })//, { credentials: 'include' })
            .then(response => { console.log(response.headers); return response.json() })
            .then(data => {
                resolve(data)
            })
        // .catch((e) => {
        //     console.log(e);
        //     resolve({ status: false })
        // });
    });
}
export const customPost = async function (url, formData) {
    return new Promise(function (resolve) {
        fetch(url, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: formData
        }).then(response => response.json())
            .then(data => resolve(data))
            .catch(() => resolve({ status: false }));
    })
}

export const customFormDataPost = async function (url, formData,key) {
    var requestOptions = {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: key
         },
        redirect: 'follow'
    };
    return new Promise(function (resolve) {
        fetch(url, requestOptions).then(response => response.json())
            .then(data => resolve(data))
            .catch((e) => {
                console.log(e);
                resolve({ status: false });
            });
    })
}

export const customFormDataPost1 = async function (url, formData) {
    var requestOptions = {
        method: 'POST',
        body: formData,
        redirect: 'follow'
    };
    return new Promise(function (resolve) {
        fetch(url, requestOptions).then(response => response.json())
            .then(data => resolve(data))
            .catch((e) => {
                console.log(e);
                resolve({ status: false });
            });
    })
}

export const customFormDataPost2 = async function (url, formData,key) {
    var requestOptions = {
        method: 'POST',
        headers: {
            Authorization: key
         },
        body: formData,
        redirect: 'follow'
    };
    return new Promise(function (resolve) {
        fetch(url, requestOptions).then(response => response.text())
            .then(data => resolve(data))
            .catch((e) => {
                console.log(e);
                resolve({ status: false });
            });
    })
}