import React, { useEffect, useState,useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Dropdown } from 'primereact/dropdown';
import apiUrls from '../../constants/apiUrls';
import apis from '../../services/apis';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import { customFormDataPost } from '../../helper/api-helper/apiHerlpers';
import apiCommonParams from '../../constants/apiCommonParams';
import '../project/project.css';
import { useKeycloakState,useSmarkState } from '../../Context/KeycloakContext';

const Release = (props) => {
  const userDetails = useSmarkState()
  const onHideRelease = () => {
    props.onHideReleaseEvent();
  }
  const keycloackValue = useKeycloakState();
  const storedToken = localStorage.getItem('keycloakToken');
  const [projectId, setProjectId] = useState(0);
  useEffect(() => {
    getProjects(storedToken);
    setTimeout(() => {
      const ele = document.getElementsByClassName('modal show');
      if (ele && ele.length > 0) {
        ele[0].removeAttribute("tabindex");
      }
    }, 0);
  }, []);

  const [masterProjects, setMasterProject] = useState([]);
  const getProjects = async () => {
    setMasterProject(await apis.getProjects(userDetails.userId,userDetails.isAdminUser,storedToken));
  }

  const projectDropdownChangeHandler = (event) => {
    setProjectId(event.target.value)
    setErrorProject(false);
  }
  const [releseName, setRelease] = useState('');
  const [errorRelease, setErrorRelease] = useState(false);
  const [errorProject, setErrorProject] = useState(false);

  const releseChangeHandler = (event) => {
    setRelease(event.target.value);
    setErrorRelease(false);
  }

  const submitButtonHandler = (event) => {
    event.preventDefault();
    if (!releseName) {
      setErrorRelease(true);
    }
    if (!projectId) {
      setErrorProject(true);
    }
    if (!releseName || !projectId) {
      return;
    }

    var body = new FormData();
    const data = {
      "releaseId": 0,
      "releaseName": releseName,
      "releaseNumber": "1.0.1",
      "projectId": projectId,
      "applicationId": apiCommonParams.applicationid,
      "siteId": apiCommonParams.siteid,
      "userCreated": apiCommonParams.userCreated,
      "userModified": apiCommonParams.userModified,
      "createdTime": apiCommonParams.createdTime,
      "updatedTime": apiCommonParams.updatedTime,
      "startDate": apiCommonParams.startDate,
      "endDate": apiCommonParams.endDate,
      "type": "regular",
      "status": "in progress"
    }

    body.append("release", JSON.stringify(data));
    body.append("swcmTicket", apiCommonParams.swcmTicket);

    trackPromise(
      customFormDataPost(apiUrls.saveRelease, body,storedToken).then((res) => {
        if (res.response === 'success') {
          props.loadMasterReleaseDdl(storedToken);
          toast.success("Release Added", {
            position: toast.POSITION.TOP_RIGHT
          });
          props.onHideReleaseEvent();
        } else {
          toast.warning(res.response, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }).catch((error) => {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    );
  }

  return (
    <Modal show={true} onHide={onHideRelease}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Release</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="">Project</label>
          <div className="w-100">
            <Dropdown filter placeholder="--Select project--" value={projectId} onChange={projectDropdownChangeHandler} style={{ width: '100%', zIndex: '99999' }} options={masterProjects} />
            {errorProject && <span className="form-error required text-danger">project is required</span>}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="">Release</label>
          <input
            type="text"
            className="form-control required-field"
            placeholder="Release name"
            onChange={releseChangeHandler} />
          {errorRelease && <span className="form-error required text-danger">Release name is required</span>}
        </div>
      </Modal.Body>
      <Modal.Footer>

        <Button variant="primary" onClick={submitButtonHandler}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Release;