import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'font-awesome/css/font-awesome.min.css';
import { ToastContainer } from 'react-toastify';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Spinner from './components/spinner/spinner';
import  KeycloackContextProvider from '../src/Context/KeycloakContext';

ReactDOM.render(
  <KeycloackContextProvider>
 
    <App />
    <ToastContainer />
    <Spinner />
  
  </KeycloackContextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
