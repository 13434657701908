import React, { useState,useEffect } from 'react';
import './reportheader.css';
import './Report1.css';
import Loader from "react-loader-spinner";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 



const Report1= (props) => {
    const [report1, setReport1] = useState(props.rvalue);
    
   
    //const report1 = props.rvalue;
    //console.log("RRRRRRRRR=",report1)
    useEffect(() => {
     setReport1(props.rvalue);
    }, [props.rvalue]);

    $(document).ready(function () {
      setTimeout(function(){
      $('#yelpTable1').DataTable();
       } ,10);
  });

  $(document).ready(function () {
    setTimeout(function(){
    $('#yelpTable2').DataTable();
     } ,10);
});
$(document).ready(function () {
  setTimeout(function(){
  $('#yelpTable3').DataTable();
   } ,10);
});
$(document).ready(function () {
  setTimeout(function(){
  $('#yelpTable4').DataTable();
   } ,10);
});

    return (
      <>
    
        <div className="inner-scn">
         <div className="container report-container">
         
          
         <div className="row ">
         <div className="col-md-12 fxt-bx mar-t">
         <h4>Report 1</h4>
         </div>
         </div>	</div>
         {(!props.loading) ?
         <>
         <div className="report-table top-table">
	  <div id="tableArea">
    <table id="yelpTable1" className="display dataTable no-footer" style={{width: "100%"}} role="grid" aria-describedby="yelpTable_info">
		<thead>
			<tr className="head-style" role="row">
									<th className="select-checkbox sorting_disabled" rowSpan="1" colSpan="1" aria-label="" style={{width: "14px"}}></th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable1" rowSpan="1" colSpan="1" aria-label="Work Hours by Roles: activate to sort column ascending" style={{width: "216px"}} >Work Hours by Roles</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable1" rowSpan="1" colSpan="1" aria-label="Original estimate 1: activate to sort column ascending" style={{width: "260px"}}>Original estimate</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable1" rowSpan="1" colSpan="1" aria-label="Time taken: activate to sort column ascending" style={{width: "150px"}} >Time taken</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable1" rowSpan="1" colSpan="1" aria-label="Remaining: activate to sort column ascending" style={{width: "150px"}} >Remaining</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable1" rowSpan="1" colSpan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Net</th>
			</tr>
		</thead>
		<tbody>
		{
              report1.section1.map((x,index) =>{
                    return(
                <tr key={index} id="kvcQrj5CutHBfSgcg236EA" className="busList selected" role="row">
                  <td className=""></td>
				  <td>{x.type}</td>
                        <td>{x.estimateTime}</td>
                        <td>{x.timeTaken}</td>
                        <td>{x.timeRemaining}</td>
                        <td>{x.netTime}</td>
                   </tr>)})
            
           }
		</tbody>
		
</table>
</div>
</div>
<div className="report-table">
<div id="tableArea">
<table id="yelpTable2" className="display dataTable no-footer" style={{width: "100%"}} role="grid" aria-describedby="yelpTable_info">
		<thead>
						
				<tr className="head-style" role="row">
					<th className="rowspan=&quot;1&quot;" colSpan="1" aria-label="" style={{width: "14px"}}></th>
					
					<th className="sorting" tabIndex="0" aria-controls="yelpTable2" rowSpan="1" colSpan="1" aria-label="Name: activate to sort column ascending" style={{width: "261px"}}>Net Work Time by People</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable2" rowSpan="1" colSpan="1" aria-label="Original estimate 1: activate to sort column ascending" style={{width: "260px"}}>Original estimate</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable2" rowSpan="1" colSpan="1" aria-label="Time taken: activate to sort column ascending" style={{width: "150px"}}>Time taken</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable2" rowspan="1" colSpan="1" aria-label="Remaining: activate to sort column ascending" style={{width: "150px"}}>Remaining</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable2" rowSpan="1" colSpan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Net</th>
				</tr>
				</thead>
				<tbody>
				{ 
         report1.section3.map((x,index)=>{
            return(
         <tr key={index} id="GbKN-edIpqhNXH_iItk04w" className="busList selected" role="row">
		 <td className=""></td>
         <td>{x.user}</td>
         <td>{x.estimateTime}</td>
         <td>{x.timeTaken}</td>
         <td>{x.timeRemaining}</td>
         <td>{x.netTime}</td>
         </tr>
         )})
         }
		 </tbody>
		 </table>
     </div>
		 </div>
     <div className="report-table">
     <div id="tableArea">
	<table id="yelpTable3" className="display dataTable no-footer" style={{width: "100%"}} role="grid" aria-describedby="yelpTable_info">
<thead>	
				<tr  className="head-style" role="row">
					<th className="rowspan=&quot;1&quot;" colSpan="1" aria-label="" style={{width: "14px"}}></th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable3" rowSpan="1" colSpan="1" aria-label="Name: activate to sort column ascending" style={{width: "261px"}}>Development</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable3" rowSpan="1" colSpan="1" aria-label="Original estimate 1: activate to sort column ascending" style={{width: "260px"}} >Original estimate</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable3" rowSpan="1" colSpan="1" aria-label="Time taken: activate to sort column ascending" style={{width: "150px"}}>Time taken</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable3" rowSpan="1" colSpan="1" aria-label="Remaining: activate to sort column ascending" style={{width: "150px"}}>Remaining</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable3" rowSpan="1" colSpan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Net</th>
				</tr>
				</thead>
				<tbody>
				{report1.section2.filter(develop => develop.type === "Development").map((x,index) =>{
              return(<tr key={index} id="GbKN-edIpqhNXH_iItk04w" className="busList selected" role="row">
			  <td className=""></td>
             <td>{x.user}</td>
             <td>{x.estimateTime}</td>
             <td>{x.timeTaken}</td>
             <td>{x.timeRemaining}</td>
             <td>{x.netTime}</td>
             </tr>)})}
			 </tbody>
			 </table>
       </div>
       </div>
       <div className="report-table">
       <div id="tableArea">
			 <table id="yelpTable4" className="display dataTable no-footer bot-table" style={{width: "100%"}} role="grid" aria-describedby="yelpTable_info">
			 <thead>
				<tr  className="head-style" role="row">
					<th className="rowspan=&quot;1&quot;" colSpan="1" aria-label="" style={{width: "14px"}}></th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable4" rowSpan="1" colSpan="1" aria-label="Name: activate to sort column ascending" style={{width: "261px"}}>Quality Assurance</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable4" rowSpan="1" colSpan="1" aria-label="Original estimate 1: activate to sort column ascending" style={{width: "260px"}}>Original estimate</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable4" rowSpan="1" colSpan="1" aria-label="Time taken: activate to sort column ascending" style={{width: "150px"}}>Time taken</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable4" rowSpan="1" colSpan="1" aria-label="Remaining: activate to sort column ascending" style={{width: "150px"}}>Remaining</th>
					<th className="sorting" tabIndex="0" aria-controls="yelpTable4" rowSpan="1" colSpan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}} >Net</th>
				</tr>
				</thead>
<tbody>				
							{report1.section2.filter(develop => develop.type === "Quality assurance").map((x,index) =>{
      return(<tr key={index} id="GbKN-edIpqhNXH_iItk04w" className="busList selected" role="row">
	  <td className=""></td>
             <td>{x.user}</td>
             <td>{x.estimateTime}</td>
             <td>{x.timeTaken}</td>
             <td>{x.timeRemaining}</td>
             <td>{x.netTime}</td>
             </tr>)})}
							
		</tbody>
	</table>
</div>
</div> 
  </>:
         <div className="spinner">
         <Loader type="Oval" color="#191f39" height="70" width="70" />
       </div>}  
       </div>
</>
)
}


export default Report1;
