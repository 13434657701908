import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './SignUp.css';
import '../signIn/common-style.css';
import '../signIn/login.css';
import { isValidEmail, passwordExpression } from '../../helper/utils/utility';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import apiUrls from '../../constants/apiUrls';
import apiCommonParams from '../../constants/apiCommonParams';
import ReCAPTCHA from 'react-google-recaptcha';

const SignUp = () => {
    const key = localStorage.getItem("keycloak1");
    console.log("Signup key",key);
    const history = useHistory();
    const initialState = {
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
        captchaResponse :'',
    };
    const [formState, setFormState] = useState({ ...initialState });
    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
        captchaResponse :''
    });
    const { firstName, lastName, userName, email, password, confirmPassword  } = formState;

    const onChangeReCaptcha= (value)=> {
        setFormState({ ...formState, captchaResponse: value?value:'' });
        let errors = { ...formErrors }
        if(value){
            errors.captchaResponse='';
        }
        setFormErrors({ ...formErrors, ...errors });
    }

    const onChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });
        let errors = { ...formErrors }
        switch (name) {
            case 'firstName':
                errors.firstName = value.length < 1 ? 'This field is required!' : '';
                break;
            case 'lastName':
                errors.lastName = value.length < 1 ? 'This field is required!' : '';
                break;
            case 'userName':
                errors.userName = value.length < 1 ? 'This field is required!' : '';
                break;
            case 'email':
                errors.email = value.length < 1 ? 'This field is required!' : isValidEmail(value) ? '' : 'Email is not valid';
                break;
            case 'password':
                errors.password = value.length < 1 ? 'This field is required!' : passwordExpression(value) ? '' : 'Must contain at least one number, one Uppercase letter, one lowercase letter and one special character, and at least 8 characters';
                break;
            case 'confirmPassword':
                errors.confirmPassword = value.length < 1 ? 'This field is required!' : (formState.password === value) ? '' : 'Password not match';
                break;
            default:
                break;
        }
        setFormErrors({ ...formErrors, ...errors });
    }

    const isFormValid = () => {
        let valid = true;
        if (formState.firstName.length === 0 || formState.lastName.length === 0 || formState.userName.length === 0 || formState.email.length === 0 || formState.password.length === 0 || formState.confirmPassword.length === 0 || formState.captchaResponse.length === 0) {
            let errors = { ...formErrors }
            if (formState.firstName.length === 0) {
                errors.firstName = 'This field is required!';
            }

            if (formState.lastName.length === 0) {
                errors.lastName = 'This field is required!';
            }

            if (formState.userName.length === 0) {
                errors.userName = 'This field is required!';
            }

            if (formState.email.length === 0) {
                errors.email = 'This field is required!';
            }

            if (formState.password.length === 0) {
                errors.password = 'This field is required!';
            }

            if (formState.confirmPassword.length === 0) {
                errors.confirmPassword = 'This field is required!';
            }
            if (formState.captchaResponse.length === 0) {
                errors.captchaResponse = 'This field is required!';
            }
            setFormErrors({ ...formErrors, ...errors });
            return false;
        }
        return valid;
    }

    function submitMethod(event) {
        event.preventDefault();
        if (isFormValid()) {
            // let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyAP-x4GzxTaU51CFXifRyJDRa9PQf1woVU';

            let payload = {
                "applicationId": 252,
                "username": formState.userName,
                "email": formState.email,
                "password": formState.password,
                "firstName": formState.firstName,
                "lastName": formState.lastName,
                "state": null,
                "enabled": false,
                "source": null,
                "userVerifiationUUID": null,
                "paswordResetUUID": null,
                "profileImageType": null,
                "updatedTime": null,
                "address1": null,
                "isdCode1": null,
                "createdTime": null,
                "userModified": 0,
                "phone2": null,
                "address2": null,
                "userCreated": 0,
                "city": null,
                "zipCode": null,
                "profileImageUrl": null,
                "parentEmail": null,
                "isdCode2": null,
                "userId": 0,
                "userStatus": null,
                "phone1": null,
                "userType": null,
                "passwordExpiryTime": null
            }

            let formdata = new FormData();
            formdata.append("siteId",  apiCommonParams.siteid);;
            formdata.append("captchaResponse", formState.captchaResponse);
            formdata.append("swcmTicket", apiCommonParams.swcmTicket);
            formdata.append("userData", JSON.stringify(payload));

            const requestBody = {
                method: 'POST',
                redirect: 'follow',
                headers: {
                    Authorization: key
                 },
                body: formdata
            };

            trackPromise(
                fetch(apiUrls.signUpPath, requestBody).then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        return res.json().then((data) => {
                            let errorMessage = 'Authentication failed!';
                            if (data && data.error && data.error.message) {
                                errorMessage = data.error.message;
                            }

                            throw new Error(errorMessage);
                        });
                    }
                }).then((data) => {
                    if(!data.status){
                        toast.warning(data.errorDetails[0], {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }else{
                        toast.success('You have signed up successfully.', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        history.push("/");
                    }
                }).catch((err) => {
                    toast.error(err.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            );
        }
    }

    const signinMethod = (event) => {
        event.preventDefault();
        history.push("/");
    }

    const forgotPwdMethod = (event) => {
        event.preventDefault();
        history.push("/forgot-password");
    }

    const forgotUsernameMethod = (event) => {
        event.preventDefault();
        history.push("/forgot-username");
    }

    return (
        <div className="login-back login-top">
            <div className="row">
                <div className="container">
                    <div className="col-lg-10 col-md-12 col-md-offset-1 row-eq-height">
                        <div className="col-md-5 col-sm-12 col-xs-12 login-inner login-screen ">
                            <h2>Hello...</h2>
                            <p className="text-left login-P-text">Don't have an account? Create your account. It take less than a minute</p>
                            <hr />
                            <h4>Already have an account?</h4>
                            <span className="account-button" onClick={signinMethod}>
                                <i className="fa fa-user" aria-hidden="true"></i> Sign in
                            </span>
                            <span onClick={forgotPwdMethod} className="account-bm-button">
                                <i className="fa fa-unlock-alt" aria-hidden="true"></i> Forgot Password
                            </span>
                            {/* <span onClick={forgotUsernameMethod} className="account-bm-button">
                                <i className="fa fa-user" aria-hidden="true"></i> Forgot Username
                            </span> */}
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12 account-create login-screen">
                            <h2>Sign Up</h2>
                            <form name="userRegister" id="userRegister" method="post">
                                <div className="form-group">
                                    <input type="text" name="firstName" value={firstName} className="form-control" id="firstName" onChange={onChange} placeholder="First Name" />
                                    {/* <label id="firstName-error" class ="error" htmlFor="firstName"></label> */}
                                    {formErrors.firstName.length > 0 && <small className="text-danger">{formErrors.firstName}</small>}
                                </div>
                                <div className="form-group mt-4">
                                    <input type="text" className="form-control" name="lastName" value={lastName} id="lastName" onChange={onChange} placeholder="Last Name" />
                                    {/* <label id="lastName-error" class ="error" htmlFor="lastName"></label> */}
                                    {formErrors.lastName.length > 0 && <small className="text-danger">{formErrors.lastName}</small>}
                                </div>
                                <div className="form-group mt-4">
                                    <input type="text" className="form-control"  name="userName" value={userName} id="userName" onChange={onChange} placeholder="Username" />
                                    {/* <label id="username-error" class ="error" htmlFor="username"></label> */}
                                    {formErrors.userName.length > 0 && <small className="text-danger">{formErrors.userName}</small>}
                                </div>
                                <div className="form-group mt-4">
                                    <input className="form-control" type="email" name="email" value={email} id="email" onChange={onChange} placeholder="Email" />
                                    {/* <label id="email-error" class ="error" htmlFor="email"></label> */}
                                    {formErrors.email.length > 0 && <small className="text-danger">{formErrors.email}</small>}
                                </div>
                                <div className="form-group mt-4">
                                    <input type="password" name="password" value={password} id="pwd" onChange={onChange} className="form-control" placeholder="Password" />
                                    {/* <label id="password-error" class ="error" htmlFor="password"></label> */}
                                    {formErrors.password.length > 0 && <small className="text-danger">{formErrors.password}</small>}
                                </div>
                                <div className="form-group mt-4">
                                    <input className="form-control" name="confirmPassword" value={confirmPassword} id="confirmPassword" onChange={onChange} type="password" placeholder="Confirm Password" />
                                    {formErrors.confirmPassword.length > 0 && <small className="text-danger">{formErrors.confirmPassword}</small>}
                                </div>
                                <div className="form-group mt-4">
                                    <ReCAPTCHA sitekey="6LdoBoUeAAAAAC7ZQNII5NXJpTbg41dktbNDp6Wo" onChange={onChangeReCaptcha} />
                                    {formErrors.captchaResponse.length > 0 && <small className="text-danger">{formErrors.captchaResponse}</small>}
                                    <br /> 
                                </div>
                                <button type="submit" id="submit" className="btn btn-primary" onClick={submitMethod}>Register</button>
                                <div className="col-md-12">
                                    <img src={process.env.PUBLIC_URL + 'images/smark.png'} alt="Logo" width="150" className="img-responsive pull-right" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;
