import { customFetch } from "../helper/api-helper/apiHerlpers";
import apiUrls from "../constants/apiUrls";
import { format } from 'react-string-format';

const getProjects = async (id,isadmin,key) => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let loggedInUserId = 0;
    if (id && !isadmin) {
        loggedInUserId = id;
    }
    console.log("currentid:",loggedInUserId);
    let projects = await customFetch((format(apiUrls.project, loggedInUserId)),key);
    return (projects && projects.projectsList && projects.projectsList.length > 0) && projects.projectsList.map((x) => { return { value: x.projectId, label: x.projectName } });
}
const getProjectsAllData = async (key) => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let loggedInUserId = 0;
    if (currentUser && !currentUser.isAdminUser) {
        loggedInUserId = currentUser.userId;
    }
    let projects = await customFetch((format(apiUrls.project, loggedInUserId)),key);
    return (projects && projects.projectsList && projects.projectsList.length > 0) && projects.projectsList.map((x) => { return x });
}

const getUsers = async function (key) {
    let users = await customFetch(apiUrls.users,key);
    return (users && users.usersList && users.usersList.length > 0) && users.usersList.map((x) => { return { value: x.userId, label: `${x.firstName} ${x.lastName}` } });
}

const getReleaseList = async function (projectId,key) {
    var releaseList = await customFetch((format(apiUrls.release, projectId)),key);
    return (releaseList && releaseList.releaseList && releaseList.releaseList.length > 0) && releaseList.releaseList.map((x) => { return { value: x.releaseId, label: x.releaseName } });
}
const getReleaseListAllData = async function (projectId,key) {
    var releaseList = await customFetch((format(apiUrls.release, projectId)),key);
    return (releaseList && releaseList.releaseList && releaseList.releaseList.length > 0) && releaseList.releaseList.map((x) => { return x });
}

const getUserStories = async function (releaseId,key) {
    return await customFetch((format(apiUrls.stories, releaseId)),key);
}
const getReport1List = async function (projectId,releaseId,key) {
    var report1List = await customFetch((format(apiUrls.report1, projectId,releaseId)),key);
    return (report1List && report1List.report1List && report1List.report1List.length > 0) && report1List.report1List.map((x) => { return { x} });
}

const getTaskEditStories = async function (projectId, search,key) {
    var userStories = await customFetch((format(apiUrls.taskEditStories, projectId, search)),key);
    return (userStories && userStories.userStoryList && userStories.userStoryList.length > 0) && userStories.userStoryList.map((x) => { return { value: x.userStoryId, label: x.title } });
}

const getUserStoryById = async (userStoryId,key) => {
    return await customFetch((format(apiUrls.userStoryById, userStoryId)),key);
}

const getStoryCommentsById = async (userStoryId,key) => {
    return await customFetch((format(apiUrls.storyComments, userStoryId)),key);
}

const statusList = [
    { value: 'defined', label: 'Defined' },
    { value: 'in-progress', label: 'In-Progress' },
    { value: 'in-qa', label: 'In-QA' },
    { value: 'completed', label: 'Completed' },
    { value: 'accepted', label: 'Accepted' }
]

const storyStatusList = [
    { value: 'Open', label: 'Open' },
    { value: 'Closed', label: 'Closed' }
]

const exportedObject = {
    getProjects,
    getProjectsAllData,
    getReleaseList,
    getReleaseListAllData,
    getUsers,
    getUserStories,
    getTaskEditStories,
    getStoryCommentsById,
    statusList,
    storyStatusList,
    getUserStoryById,
    getReport1List
}
export default exportedObject
