import React, { useEffect, useState,useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "primereact/dropdown";
import Button from "react-bootstrap/Button";
import apis from "../../services/apis";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { customFormDataPost } from "../../helper/api-helper/apiHerlpers";
import { confirmDialog } from "primereact/confirmdialog";
import apiUrls from "../../constants/apiUrls";
import apiCommonParams from "../../constants/apiCommonParams";
import { useKeycloakState, useSmarkState } from '../../Context/KeycloakContext'

const EditRelease = (props) => {
  const userDetails = useSmarkState()
  const [projectId, setProjectId] = useState(0);
  const onHideEditRelease = () => {
    props.onHideEditReleaseEvent(false);
    setShowList(false);
  };
  const [showList, setShowList] = useState(props.val);
  const [editRelease, setEditRelease] = useState(false);
  const [releaseName, setReleaseName] = useState("");
  const [release, setRelease] = useState(0);
  const keycloackValue = useKeycloakState();
  const storedToken = localStorage.getItem('keycloakToken');
  const editReleaseHandler = (items) => {

    setEditRelease(true);
    setShowList(false);
    setReleaseName(items.releaseName);
    setRelease(items);
    setError(false);
    setExist(false);
  };
  const [masterReleaseList, setMasterReleaseList] = useState([]);
  useEffect(() => {
    getProjects(storedToken);
    setTimeout(() => {
      const ele = document.getElementsByClassName("modal show");
      if (ele && ele.length > 0) {
        ele[0].removeAttribute("tabindex");
      }
    }, 0);
  }, []);

  const [masterProjects, setMasterProjects] = useState([]);
  const getProjects = async () => {
    setMasterProjects(await apis.getProjects(userDetails.userId,userDetails.isAdminUser,storedToken));
  };
  const releaseDeleteconfirm = async (item) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => acceptFunc(item.releaseId),
      reject,
    });
  };
  const acceptFunc = (release_id) => {
    var body = new FormData();
    body.append("swcmTicket", apiCommonParams.swcmTicket);
    body.append("applicationid", apiCommonParams.applicationid);
    body.append("siteid", apiCommonParams.siteid);
    body.append("releaseid", release_id);
    trackPromise(
      customFormDataPost(apiUrls.deleteRelease, body,storedToken)
        .then((response) => {
          props.loadMasterReleaseDdl();
          toast.success(response.response, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
    );
  };
  const reject = () => { };

  const projectDropdownChangeHandler = async (event) => {
    await setProjectId(event.target.value);
    await getReleaseList(event.target.value,storedToken);
  };
  const getReleaseList = async (projectId) => {
    await setMasterReleaseList(
      await apis.getReleaseListAllData(projectId,storedToken)
    );
  };
  const items =
    masterReleaseList.length > 0 &&
    masterReleaseList.map((x, index) => (
      <tr key={index}>
        <td>{x.releaseName}</td>
        <td style={{ textAlign: "right" }}>
          <span onClick={() => editReleaseHandler(x)}>
            <i className="fas fa-pencil-alt"></i>
          </span>
          &nbsp;&nbsp;
          <span onClick={() => releaseDeleteconfirm(x)}>
            <i className="fas fa-trash-alt"></i>
          </span>
        </td>
      </tr>
    ));
  const [error, setError] = useState(false);
  const [exist, setExist] = useState(false);
  const releaseNameChangeHandler = (event) => {
    setReleaseName(event.target.value);
    setError(false);
    setExist(false);
  };
  const submitButtonHandler = (event) => {
    event.preventDefault();
    if (releaseName === "") {
      setError(true);
      return;
    }

    var checkDuplicate = new FormData();
    checkDuplicate.append("projectid", release.projectId);
    checkDuplicate.append("release", releaseName);
    checkDuplicate.append("applicationid", apiCommonParams.applicationid);
    checkDuplicate.append("siteid", apiCommonParams.siteid);
    checkDuplicate.append("swcmTicket", apiCommonParams.swcmTicket);
    trackPromise(
      customFormDataPost(apiUrls.editRelease, checkDuplicate,storedToken)
        .then((response) => {
          if (response.response === "success") {
            setExist(false);
            var body = new FormData();
            const data = {
              releaseId: release.releaseId,
              releaseName: releaseName,
              releaseNumber: "1.0.1",
              projectId: release.projectId,
              applicationId: apiCommonParams.applicationid,
              siteId: apiCommonParams.siteid,
              userCreated: release.userCreated,
              userModified: apiCommonParams.userModified,
              createdTime: release.createdTime,
              updatedTime: apiCommonParams.updatedTime,
              startDate: apiCommonParams.startDate,
              endDate: apiCommonParams.endDate,
              type: "regular",
              status: "in progress",
            };
            body.append("release", JSON.stringify(data));
            body.append("swcmTicket", apiCommonParams.swcmTicket);
            trackPromise(
              customFormDataPost(apiUrls.saveRelease, body,storedToken)
                .then((response) => {
                  if (response) {
                    props.loadMasterReleaseDdl(storedToken);
                    getReleaseList(projectId);
                    cancelButtonHandler();
                    toast.success("Release name updated", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }
                })
                .catch((error) => {
                  toast.error("Error", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                })
            );
          } else {
            setExist(true);
          }
        })
        .catch((error) => {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
    );
  };
  const onHideRelease = () => {
    props.onHideEditReleaseEvent(false);
    setShowList(false);
    setEditRelease(false);
  };
  const cancelButtonHandler = () => {
    setShowList(true);
    setEditRelease(false);
  };
  return (
    <React.Fragment>
      <Modal show={showList} onHide={onHideEditRelease}>
        <Modal.Header closeButton>
          <Modal.Title>All Release for projects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ margin: "10px" }}>
            <Dropdown
              filter
              placeholder="--Select project--"
              value={projectId}
              onChange={projectDropdownChangeHandler}
              style={{ width: "100%", zIndex: "99999" }}
              options={masterProjects}
            />
          </div>
          <hr></hr>

          <div>
            {projectId > 0 ? (
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th>Release name</th>
                    <th style={{ textAlign: "right" }}>Actions</th>
                  </tr>
                </thead>

                {masterReleaseList && masterReleaseList.length > 0 ? (
                  <tbody>{items}</tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="no-record-row" colSpan={2}>
                        No record found.
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={editRelease} onHide={onHideRelease}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Release</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="">Release name</label>
              <input
                required
                type="text"
                value={releaseName}
                onChange={releaseNameChangeHandler}
                className="form-control required-field"
                placeholder="Release name"
              />
              {error && (
                <span className="text-danger">Release name is required</span>
              )}
              {exist && (
                <span className="text-danger">Release name is exist</span>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelButtonHandler}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" onClick={submitButtonHandler}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default EditRelease;
