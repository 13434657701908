import React from 'react';
import $ from 'jquery';

const Task = (props) => {

    const getRTPercent = (ot, tt, rt) => {
        tt = tt < 0 ? 0 : tt;
        rt = rt < 0 ? 0 : rt;
        var total = ot > (tt + rt) ? ot : (tt + rt);
        return (rt / total * 100).toFixed(0) + "%";
    }

    const getTTPercent = (ot, tt, rt) => {
        tt = tt < 0 ? 0 : tt;
        rt = rt < 0 ? 0 : rt;
        var total = ot > (tt + rt) ? ot : (tt + rt);
        return (tt / total * 100).toFixed(0) + "%";
    }

    const getOTPercent = (ot, tt, rt) => {
        tt = tt < 0 ? 0 : tt;
        rt = rt < 0 ? 0 : rt;
        var total = ot > (tt + rt) ? ot : (tt + rt);
        return (ot / total * 100).toFixed(0) + "%";
    }

    const getDateFromTicks = (ticks) => {
        var date = new Date(ticks);
        var monthNames = ["JAN", "FEB", "MAR", "APRIL", "MAY", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"];
        var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        var hh = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        hh = hh < 10 ? '0' + hh : hh;
        var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        return date.getFullYear() + '-' + monthNames[date.getMonth()] + '-' + dd + ' ' + hh + ':' + mm + (date.getHours() > 12 ? 'PM' : 'AM');
    }

    const getNameOfUser = (userId) => {
        if (props.usersList && props.usersList.length) {
            var user = props.usersList.length !== null ? props.usersList.filter(x => x.value === userId) : [];
            return user.length > 0 ? user[0].label : "";
        }
        return "";
    }

    const getLocation = (href) => {
        var l = document.createElement("a");
        l.href = href;
        return l;
    }

    const linkify = (comment) => {
        const reg = /\bhttps?:\/\/\S+/gi;
        return comment.replace(reg, (url) => { return "<a href='" + url + "' target='_blank'>" + getLocation(url).hostname + "</a>"; });
    }

    return (
        <div id={`dropableTask${props.task.taskId}`} data-taskid={props.task.taskId} key={props.task.taskId} className="float-left taskDragable">
            <div id={`task${props.task.taskId}`} onClick={(e) => props.editTask(!e.target.classList.contains("changeTaskStatusCompletedAccepted"), props.task, props.header_data)} className="task-item w-100 float-left block shadow-sm" style={{ backgroundColor: props.task.colorCode }}>
                <div className="block-box w-100 float-left position-relative">
                    <div className="heading-tp">
                        <span className="user-heading float-left storytitle">
                            {/* {props.task.status.toLowerCase() === 'accepted' ? <i className="fa fa-check-circle text-success taskStatusCompletedAccepted"></i> : ""} */}
                            {props.task.userStoryTitle}
                            {props.task.status.toLowerCase() === 'completed' || props.task.status.toLowerCase() === 'accepted' ? <input className='changeTaskStatusCompletedAccepted' type="checkbox" checked={props.task.status.toLowerCase() === 'accepted'} onChange={(e) => props.markTaskAcceptedCompleted(props.task.taskId, $(e.target).is(":checked") ? "accepted" : "completed")} /> : ""}
                        </span>
                    </div>
                    <span className="user-heading float-left">{props.task.title}</span>
                    <div className="clock-tp float-left">
                        <span><img src={process.env.PUBLIC_URL + '/images/clock.png'} alt="" /></span>
                        <span className="text-ot">DOE: {props.task.devEstimateTime}H</span>
                        <span className="text-tt">DTT: {props.task.devTimeTaken}H</span>
                        <span className="text-rt">DTR: {props.task.devRemainingTime > 0 ? props.task.devRemainingTime : 0}H</span>
                    </div>
                    <div className="w-100 float-left">
                        <div className="progress" style={{ height: '5px' }}>
                            <div className="progress-bar ctm-bg-ot" role="progressbar" style={{ width: getOTPercent(props.task.devEstimateTime, props.task.devTimeTaken, props.task.devRemainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                {props.task.devEstimateTime ? <span style={{ width: getOTPercent(props.task.devEstimateTime, props.task.devTimeTaken, props.task.devRemainingTime) }}>{props.task.devEstimateTime}</span>
                                    : ""}
                            </div>
                        </div>
                        <div className="progress" style={{ height: '5px' }}>
                            <div className="progress-bar ctm-bg-tt" role="progressbar" style={{ width: getTTPercent(props.task.devEstimateTime, props.task.devTimeTaken, props.task.devRemainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                {props.task.devTimeTaken ? <span style={{ width: getTTPercent(props.task.devEstimateTime, props.task.devTimeTaken, props.task.devRemainingTime) }}>{props.task.devTimeTaken}</span>
                                    : ""}
                            </div>
                            <div className="progress-bar ctm-bg-rt" role="progressbar" style={{ width: getRTPercent(props.task.devEstimateTime, props.task.devTimeTaken, props.task.devRemainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                {props.task.devRemainingTime ? <span style={{ width: getRTPercent(props.task.devEstimateTime, props.task.devTimeTaken, props.task.devRemainingTime) }}>{props.task.devRemainingTime}</span>
                                    : ""}
                            </div>
                        </div>
                    </div>
                    <div className="description-block w-100 float-left" dangerouslySetInnerHTML={{ __html: linkify(props.task.description) }} />
                    <div className="assignee w-100 float-left">
                        <div className="primery w-100 float-left">
                            <div className="w-60 float-left">
                                <span className="circle-box float-left">PA</span> <span className="assign-name">{props.task.primaryAssignee !== null ? props.task.primaryAssignee.user.firstName + " " + props.task.primaryAssignee.user.lastName : ""}</span>
                            </div>
                            <div className="w-40 float-left">{props.task.primaryAssignee.remainingTime > 0 ? props.task.primaryAssignee.remainingTime : 0}H, {props.task.primaryAssignee.timeTaken}H of {props.task.primaryAssignee.estimateTime}H</div>
                        </div>
                        <div className="w-100 float-left mb-1">
                            <div className="progress" style={{ height: '5px' }}>
                                <div className="progress-bar ctm-bg-ot" role="progressbar" style={{ width: getOTPercent(props.task.primaryAssignee.estimateTime, props.task.primaryAssignee.timeTaken, props.task.primaryAssignee.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    {props.task.primaryAssignee.estimateTime ? <span style={{ width: getOTPercent(props.task.primaryAssignee.estimateTime, props.task.primaryAssignee.timeTaken, props.task.primaryAssignee.remainingTime) }}>{props.task.primaryAssignee.estimateTime}</span>
                                        : ""}
                                </div>
                            </div>
                            <div className="progress" style={{ height: '5px' }}>
                                <div className="progress-bar ctm-bg-tt" role="progressbar" style={{ width: getTTPercent(props.task.primaryAssignee.estimateTime, props.task.primaryAssignee.timeTaken, props.task.primaryAssignee.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    {props.task.primaryAssignee.timeTaken ? <span style={{ width: getTTPercent(props.task.primaryAssignee.estimateTime, props.task.primaryAssignee.timeTaken, props.task.primaryAssignee.remainingTime) }} >{props.task.primaryAssignee.timeTaken}</span>
                                        : ""}
                                </div>
                                <div className="progress-bar ctm-bg-rt" role="progressbar" style={{ width: getRTPercent(props.task.primaryAssignee.estimateTime, props.task.primaryAssignee.timeTaken, props.task.primaryAssignee.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    {props.task.primaryAssignee.remainingTime ? <span style={{ width: getRTPercent(props.task.primaryAssignee.estimateTime, props.task.primaryAssignee.timeTaken, props.task.primaryAssignee.remainingTime) }}  >{props.task.primaryAssignee.remainingTime}</span>
                                        : ""}
                                </div>
                            </div>
                        </div>
                        {props.task.secondaryAssignees !== null ?
                            props.task.secondaryAssignees.map((secondaryAssignee, i) =>
                                <React.Fragment key={i}>
                                    <div className="primery secondry w-100 float-left">
                                        <div className="w-60 float-left">
                                            <span className="circle-box box-red float-left">SA</span> <span className="assign-name">{secondaryAssignee.user.firstName} {secondaryAssignee.user.lastName}</span>
                                        </div>
                                        <div className="w-40 float-left">{secondaryAssignee.remainingTime > 0 ? secondaryAssignee.remainingTime : 0}H,{secondaryAssignee.timeTaken}H of {secondaryAssignee.estimateTime}H</div>
                                    </div>
                                    <div className="w-100 float-left mb-1">
                                        <div className="progress" style={{ height: '5px' }}>
                                            <div className="progress-bar ctm-bg-ot" role="progressbar" style={{ width: getOTPercent(secondaryAssignee.estimateTime, secondaryAssignee.timeTaken, secondaryAssignee.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                {secondaryAssignee.estimateTime ? <span style={{ width: getOTPercent(secondaryAssignee.estimateTime, secondaryAssignee.timeTaken, secondaryAssignee.remainingTime) }} >{secondaryAssignee.estimateTime}</span>
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="progress" style={{ height: '5px' }}>
                                            <div className="progress-bar ctm-bg-tt" role="progressbar" style={{ width: getTTPercent(secondaryAssignee.estimateTime, secondaryAssignee.timeTaken, secondaryAssignee.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                {secondaryAssignee.timeTaken ? <span style={{ width: getTTPercent(secondaryAssignee.estimateTime, secondaryAssignee.timeTaken, secondaryAssignee.remainingTime) }}   >{secondaryAssignee.timeTaken}</span>
                                                    : ""}
                                            </div>
                                            <div className="progress-bar ctm-bg-rt" role="progressbar" style={{ width: getRTPercent(secondaryAssignee.estimateTime, secondaryAssignee.timeTaken, secondaryAssignee.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                {secondaryAssignee.remainingTime ? <span style={{ width: getRTPercent(secondaryAssignee.estimateTime, secondaryAssignee.timeTaken, secondaryAssignee.remainingTime) }} >{secondaryAssignee.remainingTime}</span>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>)
                            : ""}
                    </div>
                    <div className="comment-box w-100 float-left" style={props.commentsVisible ? {} : { display: 'none' }}>
                        <p className="font12">comment</p>
                        {props.task.commentsList.slice(0, 5).map((comment, i) =>
                            <div key={i} className="cmt-box w-100 float-left">
                                <p>
                                    <span>
                                        <strong className="date">{getDateFromTicks(comment.createdTime)}: </strong>
                                    </span>
                                    <span dangerouslySetInnerHTML={{ __html: linkify(comment.comments) }} />
                                </p>
                                <p>
                                    <span className="name-cmt"><b>{getNameOfUser(comment.userCreated)}</b></span>
                                    {comment.type !== 'user' ? <span className="t-hour"><b>
                                        TT{comment.timeTaken}H of TR{comment.timeRemaining}H</b></span> : ""}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Task;