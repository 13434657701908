import React from 'react';
import AppRoutes from './route/AppRoutes';
import Footer from '../src/components/footer/footer';
import {
  BrowserRouter as Router
} from "react-router-dom";

const App = () => {
  return (
    <Router>
      <AppRoutes />
      <Footer />
    </Router>
  )
}

export default App;