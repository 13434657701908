import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import apis from '../../services/apis';
import $ from 'jquery';
import { toast } from "react-toastify";
import apiUrls from '../../constants/apiUrls';
import { customFormDataPost } from '../../helper/api-helper/apiHerlpers';
import apiCommonParams from '../../constants/apiCommonParams';
import { useKeycloakState } from '../../Context/KeycloakContext';
class AddUserStory extends React.Component {
    
    constructor(props) {
        super(props);
        let storyModel = {
            id: 0,
            name: "",
            description: "",
            status: "",
            projectId: "",
            priority: 0,
            colorCode: "",
            comments: []
        };
        let storyCommentModel = {
            id: 0,
            text: "",
        };
        if (props.userStoryDetail) {
            storyModel.id = props.userStoryDetail.userStoryTO.userStoryId;
            storyModel.name = props.userStoryDetail.userStoryTO.title;
            storyModel.description = props.userStoryDetail.userStoryTO.description;
            storyModel.status = props.userStoryDetail.userStoryTO.status;
            storyModel.projectId = props.userStoryDetail.userStoryTO.projectId;
            storyModel.priority = props.userStoryDetail.userStoryTO.priority;
            storyModel.colorCode = props.userStoryDetail.userStoryTO.colorCode;
            storyModel.comments = props.userStoryDetail.commentsList || [];
        }

        let header_data = {
            masterProjectId: 0,
            masterReleaseId: 0,
            masterUserId: 0,
            userStoryId: 0,
            filterStartDate: 0,
            filterEndDate: 0
        }
        if (props.header_data) {
            header_data.masterProjectId = props.header_data.masterProjectId;
            header_data.masterReleaseId = props.header_data.masterReleaseId;
            header_data.masterUserId = props.header_data.masterUserId;
            header_data.filterStartDate = props.header_data.filterStartDate;
            header_data.filterEndDate = props.header_data.filterEndDate;
        }
        if (props.userStoryDetail) {
            header_data.userStoryId = props.userStoryDetail.userStoryTO.userStoryId ? props.userStoryDetail.userStoryTO.userStoryId : 0;
        }

        this.state = {
            projects: [],
            storyModel,
            storyCommentModel: storyCommentModel,
            header_data,
            userId:props.userId,
            isAdmin: props.isAdmin,
            
        }

        this.saveStory = this.saveStory.bind(this);
        this.getDateFromTicks = this.getDateFromTicks.bind(this);
        this.getNameOfUser = this.getNameOfUser.bind(this);
        this.saveStoryComment = this.saveStoryComment.bind(this);
        this.bindStoryComment = this.bindStoryComment.bind(this);
        this.deleteStoryById = this.deleteStoryById.bind(this);
    }

    async componentDidMount() {
        const storedToken = localStorage.getItem('keycloakToken');
        this.setState({
            projects: await apis.getProjects(this.state.userId,this.state.isAdmin,storedToken),
            storyStatusList: apis.storyStatusList,
        });
        this.bindStoryComment();
    }
    async bindStoryComment() {
        const storedToken = localStorage.getItem('keycloakToken');
        if (!this.state.storyModel.id)
            return false;

        var responseJson = await apis.getStoryCommentsById(this.state.storyModel.id,storedToken);
        this.setState(prevState => ({
            storyModel: {
                ...prevState.storyModel,
                comments: responseJson.commentsList !== null ? responseJson.commentsList : []
            }
        }));
    }

    async saveStoryComment() {
        const storedToken = localStorage.getItem('keycloakToken');
        var status = true;
        $('#storyCommentfrm .form-error.required').hide();
        $('#storyCommentfrm .required-field').each(function () {
            var value = $(this).val();
            if (value === '') {
                status = false;
                $(this).next('.form-error.required').show();
            }
        });
        if (!status)
            return false;
        var btn = $('#btnAddStotyComment');

        var comment = {
            "commentId": this.state.storyCommentModel.id,
            "comments": this.state.storyCommentModel.text,
            "entityId": this.state.storyModel.id,
            "applicationId": apiCommonParams.applicationid,
            "siteId": apiCommonParams.siteid,
            "userCreated": apiCommonParams.userid,
            "userModified": apiCommonParams.userid,
            "createdTime": new Date().getTime(),
            "updatedTime": new Date().getTime(),
            "timeTaken": 0.0,
            "type": null,
            "timeRemaining": 0.0,
            "entityType": "tm_user_story"
        }

        var formData = new FormData();
        formData.append('comment', JSON.stringify(comment));
        formData.append('swcmTicket', apiCommonParams.swcmTicket);
        btn.attr('disabled', 'disabled');
        btn.text('Please wait...');

        var data = await customFormDataPost(apiUrls.addCommentToStory, formData,storedToken);
        if (data.response && data.response === "success") {
            this.bindStoryComment();
            this.props.hideUserStory(false, null, this.state.header_data, true);
            toast.success("Comment has been added!", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error('Something went wrong, please try again later!', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        btn.removeAttr('disabled');
        btn.text('Save Comment');
    }

    async deleteStoryById() {
        const storedToken = localStorage.getItem('keycloakToken');
        if (!this.state.storyModel.id)
            return false;

        if (window.confirm('Are you sure you want to delete this user story?')) {
            var formData = new FormData();
            formData.append('swcmTicket', apiCommonParams.swcmTicket);
            formData.append('applicationid', apiCommonParams.applicationid);
            formData.append('siteid', apiCommonParams.siteid);
            formData.append('userstoryid', this.state.storyModel.id);

            var btn = $('#btnDeleteTask');
            btn.html('Please wait...');
            btn.attr('disabled', 'disabled')

            var data = await customFormDataPost(apiUrls.deleteStory, formData,storedToken);

            if (data.response && data.response === "success") {
                this.props.hideUserStory(false, null, this.state.header_data, true);
                toast.success("User story has been deleted!", {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error('Something went wrong, please try again later!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        }
    }

    hideValidationMessagesIfValid() {
        setTimeout(() => {
            $('#addStoryForm .required-field').each(function () {
                var value = $(this).hasClass("form-control") ? $(this).val() : $(this).find('select').val();
                if (value !== "" && value !== null) {
                    $(this).closest('.form-group').find('.form-error.required').hide();
                }
            });
        }, 500);
    }

    async saveStory() {
        const storedToken = localStorage.getItem('keycloakToken');
        var status = true;
        $('#addStoryForm .form-error.required').hide();
        $('#addStoryForm .required-field').each(function () {
            var value = $(this).hasClass("form-control") ? $(this).val() : $(this).find('select').val();

            if (!value) {
                status = false;
                $(this).next('.form-error.required').show();
            }
        });
        if (!status) {
            return false;
        }
        var btn = $('#btnAddStory');
        var form_data = new FormData();
        var userStory = {
            userStoryId: this.state.storyModel.id,
            title: this.state.storyModel.name,
            description: this.state.storyModel.description,
            projectId: this.state.storyModel.projectId,
            status: this.state.storyModel.status,
            colorCode: this.state.storyModel.colorCode,
            priority: 1,
            userCreated: apiCommonParams.userid,
            userModified: apiCommonParams.userid,
            createdTime: new Date().getTime(),
            updatedTime: new Date().getTime(),
            applicationId: apiCommonParams.applicationid,
            siteId: apiCommonParams.siteid
        }
        form_data.append('userstory', JSON.stringify(userStory));
        form_data.append('swcmTicket', apiCommonParams.swcmTicket);
        btn.attr('disabled', 'disabled');
        btn.text('Please wait...');
        var result = await customFormDataPost(apiUrls.saveStory, form_data,storedToken);
        btn.removeAttr('disabled');
        btn.text('SUBMIT');
        if (result.response && result.response === "success") {
            this.props.hideUserStory(false, null, this.state.header_data, true);
            var message = this.state.storyModel.id === 0 ? 'User story has been created successfully!' : 'User story has been updated successfully!';
            toast.success(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error('Something went wrong, please try again later!', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        this.setState({
            storyModel: {
                id: 0,
                name: "",
                description: "",
                status: "",
                projectId: "",
                colorCode: "",
                comments: []
            }
        });
    }

    getDateFromTicks(ticks) {
        var date = new Date(ticks);
        var monthNames = ["JAN", "FEB", "MAR", "APRIL", "MAY", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"];
        var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        var hh = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        hh = hh < 10 ? '0' + hh : hh;
        var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        return date.getFullYear() + '-' + monthNames[date.getMonth()] + '-' + dd + ' ' + hh + ':' + mm + (date.getHours() > 12 ? 'PM' : 'AM');
    }

    getNameOfUser(userId) {
        if (this.props.userList.length > 0) {
            let user = this.props.userList.filter(x => x.value === userId);
            return user.length > 0 ? user[0].label : "";
        }
    }

    render() {
        return (<div className="form-sec shadow-lg add-form-sec">
            <div className="form-blog w-100 float-left position-relative">
                <span onClick={() => { this.props.hideUserStory(false) }} className="close-form-icon"><i className="fa fa-times" aria-hidden="true"></i> </span>
                <h2>{this.state.storyModel.id === 0 ? "Add New User Story" : "Edit User Story"}</h2>
                <form id="addStoryForm" className="frm">
                    <div className="form-group">
                        <label htmlFor="">Name</label>
                        <input value={this.state.storyModel.name} onChange={e => {
                            var value = e.target.value;
                            this.setState(prevState => ({ storyModel: { ...prevState.storyModel, name: value } }))
                            this.hideValidationMessagesIfValid();
                        }}
                            type="text" className="form-control required-field" placeholder="Enter Name" />
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Story name is required</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Description</label>
                        <textarea value={this.state.storyModel.description} onChange={e => {
                            var value = e.target.value;
                            this.setState(prevState => ({ storyModel: { ...prevState.storyModel, description: value } }))
                            this.hideValidationMessagesIfValid();
                        }}
                            className="form-control required-field" rows="3" placeholder="Enter Description"></textarea>
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Description is required</span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Color code</label>
                        <input type="color" value={this.state.storyModel.colorCode} onChange={e => {
                            var value = e.target.value;
                            this.setState(prevState => ({ storyModel: { ...prevState.storyModel, colorCode: value } }))
                            this.hideValidationMessagesIfValid();
                        }}
                            className="form-control required-field" rows="3" placeholder="Enter color code" />
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Color code is required</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Status</label>
                        <Dropdown className="required-field" style={{ width: '100%' }} filter placeholder="-Select status-" value={this.state.storyModel.status} options={this.state.storyStatusList} onChange={e => {
                            var value = e.target.value;
                            this.setState(prevState => ({ storyModel: { ...prevState.storyModel, status: value } }))
                            this.hideValidationMessagesIfValid()
                        }} />

                        <span className="form-error required text-danger" style={{ display: 'none' }}>Status is required</span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Project</label>
                        <Dropdown className="required-field" style={{ width: '100%' }} filter placeholder="-Select project-" value={this.state.storyModel.projectId} options={this.state.projects} onChange={e => {
                            var value = e.target.value;
                            this.setState(prevState => ({ storyModel: { ...prevState.storyModel, projectId: value } }))
                            this.hideValidationMessagesIfValid()
                        }} disabled={this.state.storyModel.id === 0 ? false : true} />
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Project is required</span>
                    </div>
                    <button id="btnAddStory" type="button" onClick={this.saveStory} className="btn btn-primary ctm-btn w-100 mb-15 float-left">Submit</button>
                    {this.state.storyModel.id !== 0 ?
                        <button type="button" id="btnDeleteStory" onClick={this.deleteStoryById} className="btn btn-danger rounded-0 btn-block mb-15 font-weight-bold">Delete this user story</button>
                        : ""}
                </form>
                {this.state.storyModel.id !== 0 ?
                    <div id="storyCommentfrm" className="frm">
                        <div className="form-group">
                            <label htmlFor="">Comment</label>
                            <textarea className="form-control required-field" rows="3" placeholder="" value={this.state.storyCommentModel.text} onChange={e => {
                                var value = e.target.value;
                                this.setState(prevState => ({ storyCommentModel: { ...prevState.storyCommentModel, text: value } }))
                                if (value)
                                    $(e.target).closest('.form-group').find('.form-error.required').hide();
                            }}></textarea>
                            <span className="form-error required text-danger" style={{ display: 'none' }}>Comment is required</span>
                        </div>
                        <button type="button" id="btnAddStotyComment" onClick={this.saveStoryComment} className="btn btn-primary ctm-btn w-100 float-left mb-15">Save Comment</button>
                        <div className="comment-box-2 w-100 float-left">
                            {this.state.storyModel.comments.map((comment, i) =>
                                <div key={i} className="cmt-box w-100 float-left">
                                    <p>
                                        <span><strong className="date">{this.getDateFromTicks(comment.createdTime)}: </strong></span>
                                        {comment.comments}
                                    </p>
                                    <p>
                                        <span className="name-cmt"><b>{this.getNameOfUser(comment.userCreated)}</b></span>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div> : ""}
            </div>
        </div>);
    }
}
export default AddUserStory;