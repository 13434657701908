import React, { useState, useEffect,useContext } from 'react';
import './Dashboard.css';
import Header from '../../components/header/header';
import TaskBoard from '../../components/task/smarkTaskBoard';
import AddTask from '../../components/task/addTask';
import UserStory from '../../components/userStory/userStory';
import AddUserStory from '../../components/userStory/addUserStory';
import apis from '../../services/apis';
import { useSmarkDispatch, useSmarkState, useKeycloakState } from '../../Context/KeycloakContext';
import { trackPromise } from 'react-promise-tracker';
import { getTaskList } from '../../helper/context/actions';
import apiCommonParams from '../../constants/apiCommonParams';
import $ from 'jquery'



const Dashboard = (props) => {
    const contextDetails = useSmarkState();
    console.log("user=",contextDetails);
    const dispatch = useSmarkDispatch();

    const [showAddTask, _showHideAddTask] = useState(false);
    const [task, setTask] = useState({});
    const [showAddUserStory, _showHideAddUserStory] = useState(false);
    const [commentsVisible, _showComments] = useState(false);
    const [userListState, setUserListState] = useState([]);
    const [stateFromHeader, setStateFromHeader] = useState(null);
    const [userStoryDetailState, setUserStoryDetailState] = useState(0);
    const keycloackValue = useKeycloakState();
    const [loginuser, setUser] = useState()
    const storedToken = localStorage.getItem('keycloakToken');
    const showComments = function () {
        _showComments(!commentsVisible);   
        setHeightOfAllColumn(true);
    }
 
    const setHeightOfAllColumn = (reset) => {
        if(reset) 
            setTimeout(function(){ 
                $('.storyListView').each(function (i, e) {
                    try {
                        for (let item of Array.from(e.getElementsByClassName('taskDropArea'))) {
                            item.style.minHeight = "200px";
                        } 
                    }
                    catch {

                    }
                });
            },100);
        setTimeout(function(){ 
            $('.storyListView').each(function (i, e) {
                try { 
                    var maxHeight = Math.max(...Array.from(e.getElementsByClassName('taskDropArea')).map(x => x.clientHeight));
                    for (let item of Array.from(e.getElementsByClassName('taskDropArea'))) {
                        item.style.minHeight = maxHeight + "px";
                    }
                }
                catch {

                }
            });
        },300);
    }


    useEffect(() => {
        
        if (localStorage.getItem("currentUser")) {
            var user = JSON.parse(localStorage.getItem("currentUser"));
            setUser(user);
            
            if (user) {
                apiCommonParams.userid = user.userId;
                apiCommonParams.userCreated = user.userId;
                apiCommonParams.userModified = user.userId;
            }
        }
        getUserList(storedToken);
    }, [props]);

    async function getUserList() {
        setUserListState(await apis.getUsers(storedToken))
    }

    const showHideAddTask = async function (show, taskDetail, headerComData, forGetTaskList) {
        if (show) {
            setUserStoryListState(false);
            _showHideAddTask(false);
            _showHideAddUserStory(false)
        }

        if (headerComData) {
            setStateFromHeader(headerComData);
        }

        setTimeout(() => {
            setTask(taskDetail)
            _showHideAddTask(show);
        }, 100);

        if (forGetTaskList) {
            let data = {
                masterProjectId: headerComData['masterProjectId'],
                masterReleaseId: headerComData['masterReleaseId'],
                masterUserId: headerComData['masterUserId'],
                userStoryId: 0,
                filterStartDate: headerComData['filterStartDate'],
                filterEndDate: headerComData['filterEndDate']
            }

            callGetTaskList(data,storedToken);
        }
        document.body.scrollTop = 0;
  		document.documentElement.scrollTop = 0;
    }

    const callGetTaskList = (requestParam) => {
        trackPromise(
            getTaskList(dispatch, requestParam,storedToken).then((response) => {
                if (response && response.taskDetails && response.taskDetails.length > 0) {
                }
            }).catch((error) => {
                console.log(error);
            })
        );
    }

    const showHideUserStory = function (show, userStoryDetail, headerComData, forGetTaskList) {
        if (show) {
            _showHideAddTask(false);
            _showHideAddUserStory(false)
            setUserStoryListState(false)
        }

        if (headerComData) {
            setStateFromHeader(headerComData);
        }

        setTimeout(() => {
            setUserStoryDetailState(userStoryDetail);
            _showHideAddUserStory(show);
            //setUserListState(null);
        }, 100);

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // if (forGetTaskList) {
        //     let data = {
        //         masterProjectId: headerComData['masterProjectId'],
        //         masterReleaseId: headerComData['masterReleaseId'],
        //         masterUserId: headerComData['masterUserId'],
        //         userStoryId: headerComData['userStoryId'],
        //         filterStartDate: headerComData['filterStartDate'],
        //         filterEndDate: headerComData['filterEndDate']
        //     }

        //     callGetTaskList(data);
        // }
    }

    const [userStoryListState, setUserStoryListState] = useState(false);

    const showHideUserStoryList = async function (show, userStoryDetail, headerComData, story_type) {
        if (show) {
            setUserStoryListState(false);
            _showHideAddUserStory(false)
            _showHideAddTask(false);
        }

        // let userList = [];
        if (userStoryDetail) {
            userStoryDetail = await apis.getUserStoryById(userStoryDetail,storedToken);
            // userList = await apis.getUsers()
        }

        if (headerComData) {
            setStateFromHeader(headerComData);
        }

        setTimeout(() => {
            setUserStoryListState(show);
            setUserStoryDetailState(userStoryDetail);
            // setUserListState(userList);
            if (story_type === 'edit')
                _showHideAddUserStory(contextDetails.isAdminUser)
        }, 100);

    }

    const taskListProps = (headerComData, updateUserStoryDetail) => {
        setStateFromHeader(headerComData);
        setUserStoryDetailState(updateUserStoryDetail);
    }

    const hideAllAddForms = () => {
        setUserStoryListState(false);
        _showHideAddTask(false);
        _showHideAddUserStory(false)
    }

    return (
        <div>
            <Header hideAllAddForms={hideAllAddForms} showComments={showComments} addTask={showHideAddTask} addUserStory={showHideUserStory} userStoryList={showHideUserStoryList} getTaskList={taskListProps} isAdmin={contextDetails.isAdminUser} userId={contextDetails.userId}></Header>
            <TaskBoard setHeightOfAllColumn={setHeightOfAllColumn} commentsVisible={commentsVisible} editTask={showHideAddTask} header_data={stateFromHeader} editUserStory={showHideUserStory} hideUserStoryList={showHideUserStoryList} userStoryDetail={userStoryDetailState}></TaskBoard>

            {showAddTask ? <AddTask isAdmin={contextDetails.isAdminUser} userId={contextDetails.userId} hideAddTask={showHideAddTask} task={task} userList={userListState} header_data={stateFromHeader}></AddTask> : ""}

            {showAddUserStory ? <AddUserStory hideUserStory={showHideUserStory} isAdmin={contextDetails.isAdminUser} userId={contextDetails.userId} userStoryDetail={userStoryDetailState} userList={userListState} header_data={stateFromHeader}></AddUserStory> : ""}

            {userStoryListState ? <UserStory  hideUserStoryList={showHideUserStoryList} editUserStory={showHideUserStory} header_data={stateFromHeader}></UserStory> : ""}
        </div>
    )
}

export default Dashboard;
