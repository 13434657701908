import React from 'react';
import apis from '../../services/apis';
import $ from 'jquery';
import apiCommonParams from '../../constants/apiCommonParams';
import { customFormDataPost } from '../../helper/api-helper/apiHerlpers';
import apiUrls from '../../constants/apiUrls';
import { toast } from "react-toastify";
import { format } from 'react-string-format';
import { customFetch } from '../../helper/api-helper/apiHerlpers';
import { useKeycloakState } from '../../Context/KeycloakContext';

class Comments extends React.Component {
    
    constructor(props) {
        super(props);
        var isPrimaryAssignee = props.taskModel.primaryAssigneeId === props.loggedInUserId;
        var isPrimaryTester = props.taskModel.primaryTesterId === props.loggedInUserId;
        var isSecondaryAssignee = props.taskModel.secondaryAssignees.filter(x => x.secondaryAssigneeId === props.loggedInUserId).length > 0;
        var isSecondaryTester = props.taskModel.secondaryTesters.filter(x => x.secondaryTesterId === props.loggedInUserId).length > 0;

        var userNotInTask = false;
        var qaDTR = 0, devDTR = 0, dtr = 0,
            qaDTT = 0, devDTT = 0, dtt = 0,
            qaDOE = 0, devDOE = 0, doe = 0,
            commentType = 'user', showCommentType = false;
        if (isPrimaryAssignee) {
            devDTR = props.taskModel.paDTR;
            devDTT = props.taskModel.paDTT;
            devDOE = props.taskModel.paDOE;
        }

        else if (isSecondaryAssignee) {
            devDTR = props.taskModel.secondaryAssignees.filter(x => x.secondaryAssigneeId === props.loggedInUserId)[0].saDTR;
            devDTT = props.taskModel.secondaryAssignees.filter(x => x.secondaryAssigneeId === props.loggedInUserId)[0].saDTT;
            devDOE = props.taskModel.secondaryAssignees.filter(x => x.secondaryAssigneeId === props.loggedInUserId)[0].saDOE;
        }

        if (isPrimaryTester) {
            qaDTR = props.taskModel.ptQTR;
            qaDTT = props.taskModel.ptQTT;
            qaDOE = props.taskModel.ptQOE;
        }

        else if (isSecondaryTester) {
            qaDTR = props.taskModel.secondaryTesters.filter(x => x.secondaryTesterId === props.loggedInUserId)[0].stQTR;
            qaDTT = props.taskModel.secondaryTesters.filter(x => x.secondaryTesterId === props.loggedInUserId)[0].stQTT;
            qaDOE = props.taskModel.secondaryTesters.filter(x => x.secondaryTesterId === props.loggedInUserId)[0].stQOE;
        }

        if ((isPrimaryAssignee || isSecondaryAssignee) && (isPrimaryTester || isSecondaryTester)) {
            showCommentType = true;
            commentType = 'develop';
            dtr = devDTR;
            dtt = devDTT;
            doe = devDOE;
        }
        else if (isPrimaryAssignee || isSecondaryAssignee) {
            commentType = 'develop';
            dtr = devDTR;
            dtt = devDTT;
            doe = devDOE;
        }
        else if (isPrimaryTester || isSecondaryTester) {
            commentType = 'qa';
            dtr = qaDTR;
            dtt = qaDTT;
            doe = qaDOE;
        }
        else {
            userNotInTask = !this.props.isAdmin;
            props.setActiveDropdown(userNotInTask);
        }

        this.state = { 
            userNotInTask,
            comments: props.taskModel.comments,
            taskId: props.taskModel.taskId,
            showCommentType,
            commentModel: {
                text: "",
                spentTime: "",
                timeRemaining: dtr,
                defaultTimeRemaining: dtr,
                timeTaken: dtt,
                timeEstimate: doe,
                type: commentType,
                developRemainingTime: devDTR,
                qaRemainingTime: qaDTR,
                developTakenTime: devDTT,
                qaTakenTime: qaDTT,
                developEstimateTime: devDOE,
                qaEstimateTime: qaDOE
            },
        }
       
        this.users = apis.getProjects();
        this.getPercent = this.getPercent.bind(this);
        this.getNameOfUser = this.getNameOfUser.bind(this);
        this.saveComment = this.saveComment.bind(this);

    }    

    async saveComment() {
        const storedToken = localStorage.getItem('keycloakToken');
        var status = true;
        $('#commentfrm .form-error.required').hide();
        $('#commentfrm .required-field').each(function () {
            var value = $(this).val();
            if (value === '') {
                status = false;
                $(this).next('.form-error.required').show();
            }
        });
        if (!status)
            return false;
        var btn = $('#addComment');
        var comment = {
            "commentId": 0,
            "comments": this.state.commentModel.text,
            "entityId": this.state.taskId,
            "applicationId": apiCommonParams.applicationid,
            "siteId": apiCommonParams.siteid,
            "userCreated": apiCommonParams.userid,
            "userModified": apiCommonParams.userid,
            "createdTime": new Date().getTime(),
            "updatedTime": new Date().getTime(),
            "timeTaken": parseFloat(this.state.commentModel.spentTime === '' ? 0 : this.state.commentModel.spentTime),
            "type": this.state.commentModel.type,
            "timeRemaining": parseFloat(this.state.commentModel.timeRemaining),
            "entityType": "tm_task"
        }

        var formData = new FormData();
        formData.append('comment', JSON.stringify(comment));
        formData.append('swcmTicket', apiCommonParams.swcmTicket);
        btn.attr('disabled', 'disabled');
        btn.text('Please wait...');

        var data = await customFormDataPost(apiUrls.addCommentToTask, formData,storedToken);
        if (data.response && data.response === "success") {
            this.props.hideAddTask(false, null, this.props.header_data, true);
            toast.success('Comment has been added!', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error('Something went wrong, please try again later!', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        btn.removeAttr('disabled');
        btn.text('Save Comment');
    }

    getPercent(a, b) {
        return (a / b * 100).toFixed(0) + "%";
    }

    getDateFromTicks(ticks) {
        var date = new Date(ticks);
        var monthNames = ["JAN", "FEB", "MAR", "APRIL", "MAY", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"];
        var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        var hh = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        hh = hh < 10 ? '0' + hh : hh;
        var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        return date.getFullYear() + '-' + monthNames[date.getMonth()] + '-' + dd + ' ' + hh + ':' + mm + (date.getHours() > 12 ? 'PM' : 'AM');
    }


    getNameOfUser(userId) {
        if (this.props.userList && this.props.userList.length) {
            var user = this.props.userList.length !== null ? this.props.userList.filter(x => x.value === userId) : [];
            return user.length > 0 ? user[0].label : "";
        }
        return "";
    }

    async componentDidMount() { 
        const storedToken = localStorage.getItem('keycloakToken');
        var task = await customFetch((format(apiUrls.getTaskById, this.state.taskId)),storedToken);
        if(task.taskdetailsHB && task.taskdetailsHB.commentsList && task.taskdetailsHB.commentsList.length > 0){ 
            this.setState({                
                comments: task.taskdetailsHB.commentsList                
            });  
        }

        if (this.state.userNotInTask) {
            $("#addTaskForm input[type='text'], #addTaskForm textarea, #addTaskForm select, #addTaskForm input[type='number']").prop("disabled", true);
            $('#btnAddTask, #btnDeleteTask').remove();
            $('#addFirstSecondaryAssignee').closest('.row').remove();
            $('#addFirstSecondaryTester').closest('.row').remove();
        }
        else if (!this.props.isAdmin) {
            $("#addTaskForm select:not(#editTaskStatus), #addTaskForm input[type='number']").prop("disabled", true);
            $('#addFirstSecondaryAssignee').closest('.row').remove();
            $('#addFirstSecondaryTester').closest('.row').remove();
        }
    }

    render() {
        return (<div id="commentfrm" className="frm">
            <div className="form-group">
                <label htmlFor="">Comment</label>
                <textarea className="form-control required-field" rows="3" placeholder="" value={this.state.commentModel.text} onChange={e => {
                    var value = e.target.value;
                    this.setState(prevState => ({ commentModel: { ...prevState.commentModel, text: value } }))
                    if(value)
                        $(e.target).closest('.form-group').find('.form-error.required').hide();
                }}></textarea>
                <span className="form-error required text-danger" style={{ display: 'none' }}>Comment is required</span>
            </div>
            {this.state.commentModel.type !== 'user' ?
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label htmlFor="">Spent Time</label>
                        <input type="number" min="0" className="form-control required-field" placeholder="" value={this.state.commentModel.spentTime} onChange={e => {
                            var value = e.target.value;
                            var timeRemaining = this.state.commentModel.defaultTimeRemaining - value;
                            timeRemaining = timeRemaining < 0 ? 0 : timeRemaining;
                            this.setState(prevState => ({ commentModel: { ...prevState.commentModel, spentTime: value, timeRemaining } }))
                            if(value)
                                $(e.target).closest('.form-group').find('.form-error.required').hide();
                        }} />
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Spent time is required</span>
                    </div>
                    <div className="form-group col-sm-6">
                        <label htmlFor="">Time Remaining</label>
                        <input type="number" min="0" className="form-control required-field" placeholder="" value={this.state.commentModel.timeRemaining} onChange={e => {
                            var value = e.target.value;
                            this.setState(prevState => ({ commentModel: { ...prevState.commentModel, timeRemaining: value } }))
                            if(value)
                                $(e.target).closest('.form-group').find('.form-error.required').hide();
                        }} />
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Time remaining is required</span>
                    </div>
                </div> : ""}
            {this.state.commentModel.type !== 'user' ?
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label htmlFor="">Time Estimate</label>
                        <input type="number" min="0" readOnly={true} className="form-control" value={this.state.commentModel.timeEstimate} onChange={() => { }} />
                    </div>
                    <div className="form-group col-sm-6">
                        <label htmlFor="">Time Taken</label>
                        <input type="number" min="0" readOnly={true} className="form-control" value={this.state.commentModel.timeTaken} onChange={() => { }} />
                    </div>
                </div> : ""}
            {this.state.showCommentType ?
                <div className="form-group">
                    <label htmlFor="">Type</label>
                    <select value={this.state.commentModel.type} name="stName" className="form-control" onChange={e => {
                        var value = e.target.value;
                        var dtr = 0, dtt = 0, doe = 0;
                        if (value === 'develop') {
                            dtr = this.state.commentModel.developRemainingTime;
                            dtt = this.state.commentModel.developTakenTime;
                            doe = this.state.commentModel.developEstimateTime;
                        }
                        else if (value === 'qa') {
                            dtr = this.state.commentModel.qaRemainingTime;
                            dtt = this.state.commentModel.qaTakenTime;
                            doe = this.state.commentModel.qaEstimateTime;
                        }
                        this.setState(prevState => ({ commentModel: { ...prevState.commentModel, type: value, spentTime:0, defaultTimeRemaining:dtr, timeRemaining: dtr, timeTaken: dtt, timeEstimate: doe } }))
                    }}>
                        <option value="develop">Develop</option>
                        <option value="qa">QA</option>
                    </select>
                </div> : ""}
            <button type="button" id="addComment" onClick={this.saveComment} className="btn btn-primary ctm-btn w-100 float-left mb-15">Save Comment</button>
            <div className="comment-box-2 w-100 float-left">
                {this.state.comments.map((comment, i) =>
                    <div key={i} className="cmt-box w-100 float-left">
                        <p>
                            <span><strong className="date">{this.getDateFromTicks(comment.createdTime)}: </strong></span>
                            {comment.comments}
                        </p>
                        <p>
                            <span className="name-cmt"><b>{this.getNameOfUser(comment.userCreated)}</b></span>
                            {comment.type !== 'user' ? <span className="t-hour"><b>TT:{comment.timeTaken}H of TR:{comment.timeRemaining}H</b></span> : ""}
                        </p>
                    </div>
                )}
            </div>
        </div>);
    }
}

export default Comments;