import React, { useState,useEffect } from 'react';
import './reportheader.css';
import './Report4.css';
import Loader from "react-loader-spinner";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

const Report4= (props) => {
    const [report4, setReport4] = useState(props.r4value);
    const [Range, setRange] = useState(props.dateRange)
    
   
    //const report1 = props.rvalue;
    console.log("RRRRRRRRR=",report4)
    useEffect(() => {
     setReport4(props.r4value);
    }, [props.r4value]);

    $(document).ready(function () {
      setTimeout(function(){
      $('#yelpTable1').DataTable(
        
      );
       } ,10);
  });
  
  $(document).ready(function () {
    setTimeout(function(){
    $('#yelpTable2').DataTable();
     } ,10);
});
$(document).ready(function () {
  setTimeout(function(){
  $('#yelpTable3').DataTable();
   } ,10);
});
  


    return (
        <>
        
		 <div className="inner-scn">
         <div className="container report-container">
               
               <div className="row ">
                  <div className="col-md-12 fxt-bx mar-t">
                     <h4>Report 4</h4>
					 <p>Generated reports for the date {props.dateRange}</p>
                  </div>
               </div>
           </div>
           {(!props.loading) ?
           <>
               <div className="report-table top-table">
	<div id="tableArea">
	<table id="yelpTable1" className="display dataTable no-footer" style={{width: "100%"}} role="grid" aria-describedby="yelpTable_info">
		<thead>
			
		
		
				<tr className="head-style" role="row">
					<th className="sorting" tabindex="0" aria-controls="yelpTable1" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{width: "261px"}}>Development</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable1" rowspan="1" colspan="1" aria-label="Original estimate 1: activate to sort column ascending" style={{width: "260px"}}>Original estimate</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable1" rowspan="1" colspan="1" aria-label="Time taken: activate to sort column ascending" style={{width: "150px"}}>Time taken</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable1" rowspan="1" colspan="1" aria-label="Remaining: activate to sort column ascending" style={{width: "150px"}}>Remaining</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable1" rowspan="1" colspan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Total</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable1" rowspan="1" colspan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Net</th>
				</tr>
			</thead>
<tbody>			
				 {
          report4.section1.map((x,index) =>{
          return(
           <tr key={index} id="kvcQrj5CutHBfSgcg236EA" className="busList selected odd" role="row">
           <td>{x.project}</td>
           <td>{x.devEstimateTime}</td>
           <td>{x.devTimeTaken}</td>
           <td>{x.devRemainingTime}</td>
           <td>{x.devTotalTime}</td>
           <td>{x.devNetTime}</td>
          </tr>)

         })
        }
				{report4.section2.filter(develop => develop.type === "Development").map(x =>{
            return(
                  <tr id="GbKN-edIpqhNXH_iItk05w" className="busList selected odd" role="row">
                  <td className="total-bg">Total</td>
                  <td>{x.estimateTime}</td>
                  <td>{x.timeTaken}</td>
                  <td>{x.timeRemaining}</td>
                  <td>{x.totalTime}</td>
                  <td>{x.netTime}</td>					  
          </tr>)

   })
   }	
</tbody>
</table>
</div>
</div>
<div className="report-table">
<div id="tableArea">
	<table id="yelpTable2" className="display dataTable no-footer" style={{width: "100%"}} role="grid" aria-describedby="yelpTable_info">
		<thead>
   
				<tr className="head-style" role="row">
					<th className="sorting" tabindex="0" aria-controls="yelpTable2" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{width: "261px"}}>Quality Assurance</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable2" rowspan="1" colspan="1" aria-label="Original estimate 1: activate to sort column ascending" style={{width: "260px"}}>Original estimate</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable2" rowspan="1" colspan="1" aria-label="Time taken: activate to sort column ascending" style={{width: "150px"}}>Time taken</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable2" rowspan="1" colspan="1" aria-label="Remaining: activate to sort column ascending" style={{width: "150px"}}>Remaining</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable2" rowspan="1" colspan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Total</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable2" rowspan="1" colspan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Net</th>
				</tr>
				</thead>
				<tbody>
				 {
   report4.section1.map((x,index) =>{
       return(
           <tr key={index} id="kvcQrj5CutHBfSgcg236EA" className="busList selected even" role="row">
           <td>{x.project}</td>
           <td>{x.qaEstimateTime}</td>
           <td>{x.qaTimeTaken}</td>
           <td>{x.qaRemainingTime}</td>
           <td>{x.qaTotalTime}</td>
           <td>{x.qaNetTime}</td>
        </tr>)

   })
   }
        
           {report4.section2.filter(develop => develop.type === "Quality Assurance").map(x =>{
       return(
         <tr id="GbKN-edIpqhNXH_iItk05w" className="busList selected odd" role="row">
         <td className="total-bg">Total</td>
         <td>{x.estimateTime}</td>
          <td>{x.timeTaken}</td>
          <td>{x.timeRemaining}</td>
          <td>{x.totalTime}</td>
          <td>{x.netTime}</td>					  
      </tr>)

   })
   }
   </tbody>
   </table>
   </div>
   </div>
   <div className="report-table">
   <div id="tableArea">
	<table id="yelpTable3" className="display dataTable no-footer bot-table" style={{width: "100%"}} role="grid" aria-describedby="yelpTable_info">
		<thead>
						
				<tr className="head-style" role="row">
					<th class="sorting" tabindex="0" aria-controls="yelpTable3" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{width: "261px"}}>Grand Total</th>
					<th class="sorting" tabindex="0" aria-controls="yelpTable3" rowspan="1" colspan="1" aria-label="Original estimate 1: activate to sort column ascending" style={{width: "260px"}}>Original estimate</th>
					<th class="sorting" tabindex="0" aria-controls="yelpTable3" rowspan="1" colspan="1" aria-label="Time taken: activate to sort column ascending" style={{width: "150px"}}>Time taken</th>
					<th class="sorting" tabindex="0" aria-controls="yelpTable3" rowspan="1" colspan="1" aria-label="Remaining: activate to sort column ascending" style={{width: "150px"}}>Remaining</th>
					<th class="sorting" tabindex="0" aria-controls="yelpTable3" rowspan="1" colspan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Total</th>
					<th class="sorting" tabindex="0" aria-controls="yelpTable3" rowspan="1" colspan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Net</th>
				</tr>
				</thead>
				<tbody>
				 {
   report4.section1.map((x,index) =>{
       return(
           <tr key={index} id="kvcQrj5CutHBfSgcg236EA" className="busList selected even" role="row">
           <td>{x.project}</td>
           <td>{x.estimateTime}</td>
           <td>{x.timeTaken}</td>
           <td>{x.timeRemaining}</td>
           <td>{x.totalTime}</td>
           <td>{x.netTime}</td>
        </tr>)

   })
   }
       
            {report4.section2.filter(develop => develop.type === "Grnad Total").map(x =>{
       return(
         <tr id="GbKN-edIpqhNXH_iItk05w" className="busList selected odd" role="row">
         <td className="total-bg">Total</td>
         <td>{x.estimateTime}</td>
          <td>{x.timeTaken}</td>
          <td>{x.timeRemaining}</td>
          <td>{x.totalTime}</td>
          <td>{x.netTime}</td>					  
      </tr>)

   })
   }
  
						
		</tbody>
	</table>
</div>
	  </div>	
         </>
         : <div className="spinner">
             <Loader type="Oval" color="#191f39" height="70" width="70" />
           </div>}
           </div>
         </>
    )

}


export default Report4;
