import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import apiUrls from "../../constants/apiUrls";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { customFormDataPost } from "../../helper/api-helper/apiHerlpers";
import { confirmDialog } from "primereact/confirmdialog";
import apis from "../../services/apis";
import apiCommonParams from "../../constants/apiCommonParams";
import './EditProject.css';
import { useKeycloakState } from '../../Context/KeycloakContext'

const EditProject = (props) => {
  const [showList, setShowList] = useState(props.val);
  const [editProject, setEditProject] = useState(false);
  const [project, setProject] = useState(0);
  const [projects, setProjects] = useState([]);
  const keycloackValue = useKeycloakState();
  const storedToken = localStorage.getItem('keycloakToken');
  useEffect(() => {
    getProjects(storedToken);
  }, []);

  const getProjects = async () => {
    setProjects(await apis.getProjectsAllData(storedToken));
  };
  const onHideEditProject = () => {
    props.onHideEditProjectEvent(false);
    setShowList(false);
  };
  const projectDeleteconfirm2 = async (item) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => acceptFunc(item.projectId),
      reject,
    });
  };
  const acceptFunc = (project_id) => {
    var body = new FormData();
    body.append("swcmTicket", apiCommonParams.swcmTicket);
    body.append("applicationid", apiCommonParams.applicationid);
    body.append("siteid", apiCommonParams.siteid);
    body.append("projectid", project_id);
    trackPromise(
      customFormDataPost(apiUrls.deleteProject, body,storedToken)
        .then((response) => {
          props.loadMasterProjectDdl(storedToken);
          getProjects(storedToken);
          toast.success(response.response, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
    );
  };
  const reject = () => { };
  const onHideProject = () => {
    setEditProject(false);
    setShowList(false);
    props.onHideEditProjectEvent(false);
  };
  const editIconClickHandler = (item) => {
    setEditProject(true);
    setShowList(false);
    setProjectName(item.projectName);
    setProject(item);
    setExist(false);
  };
  const items = projects.map((x, index) => (
    <tr key={index}>
      <td>{x.projectName}</td>
      <td style={{ textAlign: "right" }}>
        <span onClick={() => editIconClickHandler(x)} value={x.projectName}>
          <i className="fas fa-pencil-alt"></i>
        </span>
        &nbsp;&nbsp;
        <span onClick={() => projectDeleteconfirm2(x)}>
          <i className="fas fa-trash-alt"></i>
        </span>
      </td>
    </tr>
  ));
  const [projectName, setProjectName] = useState("");
  const [error, setError] = useState(false);
  const [exist, setExist] = useState(false);
  const projectNameChangeHandler = (event) => {
    setProjectName(event.target.value);
    setError(false);
    setExist(false);
  };

  const submitButtonHandler = (event) => {
    event.preventDefault();
    if (projectName === "") {
      setError(true);
      return;
    }
    var checkDuplicate = new FormData();
    checkDuplicate.append("applicationid", apiCommonParams.applicationid);
    checkDuplicate.append("siteid", apiCommonParams.siteid);
    checkDuplicate.append("project", projectName);
    checkDuplicate.append("swcmTicket", apiCommonParams.swcmTicket);
    trackPromise(
      customFormDataPost(apiUrls.editProject, checkDuplicate,storedToken)
        .then((res) => {
          if (res.response === "success") {
            setExist(false);
            var body = new FormData();
            const data = {
              projectId: project.projectId,
              projectName: projectName,
              applicationId: apiCommonParams.applicationid,
              siteId: apiCommonParams.siteid,
              userCreated: project.userCreated,
              userModified: apiCommonParams.userModified,
              createdTime: project.createdTime,
              updatedTime: apiCommonParams.updatedTime,
            };
            body.append("project", JSON.stringify(data));
            body.append("swcmTicket", apiCommonParams.swcmTicket);
            trackPromise(
              customFormDataPost(apiUrls.saveProject, body,storedToken)
                .then((response) => {
                  if (response) {
                    props.loadMasterProjectDdl(storedToken);
                    getProjects(storedToken);
                    cancelButtonHandler();
                    toast.success("Project name updated", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }
                })
                .catch((error) => {
                  toast.error("Error", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                })
            );
          }
          else {
            setExist(true);

          }
        })
        .catch((error) => {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
    );

    props.loadMasterProjectDdl(storedToken);
  };
  const cancelButtonHandler = () => {
    setEditProject(false);
    setShowList(true);
  };
  return (
    <React.Fragment>
      <Modal show={showList} onHide={onHideEditProject}>
        <Modal.Header closeButton>
          <Modal.Title>All projects</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-scroll">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th>Project name</th>
                <th style={{ textAlign: "right" }}>Actions</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </table>
        </Modal.Body>
      </Modal>
      <Modal show={editProject} onHide={onHideProject}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="">Project name</label>
              <input
                required
                type="text"
                value={projectName}
                onChange={projectNameChangeHandler}
                className="form-control required-field"
                placeholder="Project name"
              />
              {error && (
                <span className="text-danger">Project name is required</span>
              )}
              {exist && (
                <span className="text-danger">Project name is exist</span>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelButtonHandler}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" onClick={submitButtonHandler}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default EditProject;
