import React from 'react';

const QaTask = (props) => { 

    const getRTPercent = (ot, tt, rt) => {
        tt = tt < 0 ? 0 : tt;
        rt = rt < 0 ? 0 : rt;
        var total = ot > (tt + rt) ? ot : (tt + rt);
        return (rt / total * 100).toFixed(0) + "%";
    }

    const getTTPercent = (ot, tt, rt) => {
        tt = tt < 0 ? 0 : tt;
        rt = rt < 0 ? 0 : rt;
        var total = ot > (tt + rt) ? ot : (tt + rt);
        return (tt / total * 100).toFixed(0) + "%";
    }

    const getOTPercent = (ot, tt, rt) => {
        tt = tt < 0 ? 0 : tt;
        rt = rt < 0 ? 0 : rt;
        var total = ot > (tt + rt) ? ot : (tt + rt);
        return (ot / total * 100).toFixed(0) + "%";
    }

    const getDateFromTicks = (ticks) => {
        var date = new Date(ticks);
        var monthNames = ["JAN", "FEB", "MAR", "APRIL", "MAY", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"];
        var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        var hh = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        hh = hh < 10 ? '0' + hh : hh;
        var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        return date.getFullYear() + '-' + monthNames[date.getMonth()] + '-' + dd + ' ' + hh + ':' + mm + (date.getHours() > 12 ? 'PM' : 'AM');
    }

    const getNameOfUser = (userId) => {
        if (props.usersList && props.usersList.length) {
            var user = props.usersList.length !== null ? props.usersList.filter(x => x.value === userId) : [];
            return user.length > 0 ? user[0].label : "";
        }
        return "";
    }

    const getLocation = (href) => {
        var l = document.createElement("a");
        l.href = href;
        return l;
    }

    const linkify = (comment) => {
        const reg = /\bhttps?:\/\/\S+/gi;
        return comment.replace(reg, (url) => { return "<a href='" + url + "' target='_blank'>" + getLocation(url).hostname + "</a>"; });
    }

    return (
        <div id={`dropableTask${props.task.taskId}`} data-taskid={props.task.taskId} key={props.task.taskId} className="float-left taskDragable">
            <div id={`task${props.task.taskId}`} onClick={() => props.editTask(true, props.task)} className="task-item w-100 float-left block shadow-sm" style={{ backgroundColor: props.task.colorCode }}>
                <div className="block-box w-100 float-left position-relative">
                    <div className="heading-tp">
                        <span className="user-heading float-left">{props.task.userStoryTitle}</span>
                    </div>
                    <span className="user-heading float-left">{props.task.title}</span>
                    <div className="clock-tp float-left">
                        <span><img src={process.env.PUBLIC_URL + '/images/clock.png'} alt="" /></span>
                        <span className="text-ot">QOE: {props.task.qaEstimateTime}H</span>
                        <span className="text-tt">QTT: {props.task.qaTimeTaken}H</span>
                        <span className="text-rt">QTR: {props.task.qaRemainingTime > 0 ? props.task.qaRemainingTime : 0}H</span>
                    </div>
                    <div className="w-100 float-left">
                        <div className="progress" style={{ height: '5px' }}>
                            <div className="progress-bar ctm-bg-ot" role="progressbar" style={{ width: getOTPercent(props.task.qaEstimateTime, props.task.qaTimeTaken, props.task.qaRemainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                {props.task.qaEstimateTime ? <span style={{ width: getOTPercent(props.task.qaEstimateTime, props.task.qaTimeTaken, props.task.qaRemainingTime) }}>{props.task.qaEstimateTime}</span>
                                    : ""}
                            </div>
                        </div>
                        <div className="progress" style={{ height: '5px' }}>
                            <div className="progress-bar ctm-bg-tt" role="progressbar" style={{ width: getTTPercent(props.task.qaEstimateTime, props.task.qaTimeTaken, props.task.qaRemainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                {props.task.qaTimeTaken ? <span style={{ width: getTTPercent(props.task.qaEstimateTime, props.task.qaTimeTaken, props.task.qaRemainingTime) }}>{props.task.qaTimeTaken}</span>
                                    : ""}
                            </div>
                            <div className="progress-bar ctm-bg-rt" role="progressbar" style={{ width: getRTPercent(props.task.qaEstimateTime, props.task.qaTimeTaken, props.task.qaRemainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                {props.task.qaRemainingTime ? <span style={{ width: getRTPercent(props.task.qaEstimateTime, props.task.qaTimeTaken, props.task.qaRemainingTime) }}>{props.task.qaRemainingTime}</span>
                                    : ""}
                            </div>
                        </div>
                    </div>
                    <div className="description-block w-100 float-left" dangerouslySetInnerHTML={{ __html: linkify(props.task.description) }} />
                    <div className="assignee w-100 float-left">
                        <div className="primery w-100 float-left">
                            <div className="w-60 float-left">
                                <span className="circle-box float-left">PT</span> <span className="assign-name">{props.task.primaryTester !== null ? props.task.primaryTester.user.firstName + " " + props.task.primaryTester.user.lastName : ""}</span>
                            </div>
                            <div className="w-40 float-left">{props.task.primaryTester.remainingTime > 0 ? props.task.primaryTester.remainingTime : 0}H, {props.task.primaryTester.timeTaken}H of {props.task.primaryTester.estimateTime}H</div>
                        </div>

                        <div className="w-100 float-left mb-1">
                            <div className="progress" style={{ height: '5px' }}>
                                <div className="progress-bar ctm-bg-ot" role="progressbar" style={{ width: getOTPercent(props.task.primaryTester.estimateTime, props.task.primaryTester.timeTaken, props.task.primaryTester.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    {props.task.primaryTester.estimateTime ? <span style={{ width: getOTPercent(props.task.primaryTester.estimateTime, props.task.primaryTester.timeTaken, props.task.primaryTester.remainingTime) }}>{props.task.primaryTester.estimateTime}</span>
                                        : ""}
                                </div>
                            </div>
                            <div className="progress" style={{ height: '5px' }}>
                                <div className="progress-bar ctm-bg-tt" role="progressbar" style={{ width: getTTPercent(props.task.primaryTester.estimateTime, props.task.primaryTester.timeTaken, props.task.primaryTester.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    {props.task.primaryTester.timeTaken ? <span style={{ width: getTTPercent(props.task.primaryTester.estimateTime, props.task.primaryTester.timeTaken, props.task.primaryTester.remainingTime) }}>{props.task.primaryTester.timeTaken}</span>
                                        : ""}
                                </div>
                                <div className="progress-bar ctm-bg-rt" role="progressbar" style={{ width: getRTPercent(props.task.primaryTester.estimateTime, props.task.primaryTester.timeTaken, props.task.primaryTester.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                    {props.task.primaryTester.remainingTime ? <span style={{ width: getRTPercent(props.task.primaryTester.estimateTime, props.task.primaryTester.timeTaken, props.task.primaryTester.remainingTime) }}>{props.task.primaryTester.remainingTime}</span>
                                        : ""}
                                </div>
                            </div>
                        </div>
                        {props.task.secondaryTesters !== null ?
                            props.task.secondaryTesters.map((secondaryTester, i) =>
                                <React.Fragment key={i}>
                                    <div className="primery secondry w-100 float-left">
                                        <div className="w-60 float-left">
                                            <span className="circle-box box-red float-left">ST</span> <span className="assign-name">{secondaryTester.user.firstName} {secondaryTester.user.lastName}</span>
                                        </div>
                                        <div className="w-40 float-left">{secondaryTester.remainingTime > 0 ? secondaryTester.remainingTime : 0}H,{secondaryTester.timeTaken}H of {secondaryTester.estimateTime}H</div>
                                    </div>
                                    <div className="w-100 float-left mb-1">
                                        <div className="progress" style={{ height: '5px' }}>
                                            <div className="progress-bar ctm-bg-ot" role="progressbar" style={{ width: getOTPercent(secondaryTester.estimateTime, secondaryTester.timeTaken, secondaryTester.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                {secondaryTester.timeTaken ? <span style={{ width: getOTPercent(secondaryTester.estimateTime, secondaryTester.timeTaken, secondaryTester.remainingTime) }}>{secondaryTester.estimateTime}</span>
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="progress" style={{ height: '5px' }}>
                                            <div className="progress-bar ctm-bg-tt" role="progressbar" style={{ width: getTTPercent(secondaryTester.estimateTime, secondaryTester.timeTaken, secondaryTester.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                {secondaryTester.timeTaken ? <span style={{ width: getTTPercent(secondaryTester.estimateTime, secondaryTester.timeTaken, secondaryTester.remainingTime) }} >{secondaryTester.timeTaken}</span>
                                                    : ""}
                                            </div>
                                            <div className="progress-bar ctm-bg-rt" role="progressbar" style={{ width: getRTPercent(secondaryTester.estimateTime, secondaryTester.timeTaken, secondaryTester.remainingTime) }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                {secondaryTester.remainingTime ? <span style={{ width: getRTPercent(secondaryTester.estimateTime, secondaryTester.timeTaken, secondaryTester.remainingTime) }} >{secondaryTester.remainingTime}</span>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>)
                            : ""}
                    </div>
                    <div className="comment-box w-100 float-left" style={props.commentsVisible ? {} : { display: 'none' }}>
                        <p className="font12">comment</p>
                        {props.task.commentsList.slice(0, 5).map((comment, i) =>
                            <div key={i} className="cmt-box w-100 float-left">
                                <p>
                                    <span>
                                        <strong className="date">{getDateFromTicks(comment.createdTime)}: </strong>
                                    </span>
                                    <span dangerouslySetInnerHTML={{ __html: linkify(comment.comments) }} />
                                </p>
                                <p>
                                    <span className="name-cmt"><b>{getNameOfUser(comment.userCreated)}</b></span>
                                    {comment.type !== 'user' ? <span className="t-hour"><b>
                                        TT{comment.timeTaken}H of TR{comment.timeRemaining}H</b></span> : ""}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QaTask;