import React from 'react';
import './reportheader.css';
import { Dropdown } from 'primereact/dropdown';
import { Dropdown as BoostrapDropdown } from 'react-bootstrap';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Logout from '../logout/logout';
import MasterSearch from '../masterSearch/masterSearch';
import { withRouter } from 'react-router-dom';
import Release from '../release/Release';
import EditProject from '../editProject/EditProject';
import EditRelease from '../editRelease/EditRelease';
import $ from 'jquery'
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import apis from '../../services/apis';
import { customFetch, customFetch1 } from '../../helper/api-helper/apiHerlpers';
import apiUrls from '../../constants/apiUrls';
import { format } from 'react-string-format';
import Report1 from './Report1';
import Report2 from './Report2';
import Report3 from './Report3';
import Report4 from './Report4';
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { useKeycloakState } from '../../Context/KeycloakContext';

class ReportHeader extends React.Component {
    
    constructor(props) {
        super(props);
        //Show task if URL have taskId
        var taskId = this.getURLParamByName('t');
        var projectId = null;
        var releaseId = null;
        if (!taskId) {
            projectId = this.getURLParamByName('p');
            releaseId = this.getURLParamByName('r');
        }

        this.state = {
            options:[
                {value:"report1", label:"Report 1"},
                {value:"report2", label:"Report 2"},
                {value:"report3", label:"Report 3"},
                {value:"report4", label:"Report 4"},
            ],
            loadTaskHit: 0,
            loadRunHit:0,
            isAdmin: this.props.isAdmin,
            userId: this.props.userId,
            taskId,
            report1:false,
            report2:false,
            report3:false,
            report4:false,
            loading:false,
            reportValue:null,
            generateValue:null,
            report1Details:false,
            report2Details:false,
            report3Details:false,
            report4Details:false,
            masterReport1: "",
            masterReport2:"",
            masterReport3:"",
            masterReport4:"",
            masterProjectId: projectId,
            masterProjects: [],
            masterReleaseId: releaseId,
            masterReleaseList: [],
            masterUserId: null,
            masterUsers: [],
            loggedInUserName: "Sonu B",
            showStoryList: false,
            showAddUserStory: false,
            runReport: true,
            addRelease: false,
            openProjectList: false,
            openReleaseList: false,
            showAddTask: false,

            showFilterPopup: false,
            filterStartDate: null,
            filterEndDate: null,

            activeFilterType: '',
            recentTaskModifiedDays: '0',
            taskModifiedBeforeDays: '0',
            filterDateRange: '',
            showFilterPopup: false
        }
        this.GenerateReport = this.GenerateReport.bind(this);
        this.masterProjectChange = this.masterProjectChange.bind(this);
        this.masterReportChange = this.masterReportChange.bind(this);
        this.masterReleaseChange = this.masterReleaseChange.bind(this);
        this.setValue1 = this.setValue1.bind(this);
        this.setValue2 = this.setValue2.bind(this);
        this.setValue3 = this.setValue3.bind(this);
        this.setValue4 = this.setValue4.bind(this);
        this.masterUserChange = this.masterUserChange.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.showAddTask = props.addTask;
        this.showAddUserStory = props.addUserStory;
        this.showStoryList = props.userStoryList;
        this.addProjectHandler = this.addProjectHandler.bind(this);
        this.hideProjectHandler = this.hideProjectHandler.bind(this);
        this.addReleaseHandler = this.addReleaseHandler.bind(this);
        this.hideReleaseHandler = this.hideReleaseHandler.bind(this);
        this.openProjectListHandler = this.openProjectListHandler.bind(this);
        this.hideEditProjectHandler = this.hideEditProjectHandler.bind(this);
        this.openReleaseListHandler = this.openReleaseListHandler.bind(this);
        this.hideReleaseListHandler = this.hideReleaseListHandler.bind(this);
        this.filterDateRangePickerChange = this.filterDateRangePickerChange.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.loadMasterReleaseDdl = this.loadMasterReleaseDdl.bind(this);
        this.loadMasterProjectDdl = this.loadMasterProjectDdl.bind(this);
        this.onFilterPopupDropdownToggle = this.onFilterPopupDropdownToggle.bind(this);
    }

    onFilterPopupDropdownToggle(showFilterPopup) {
        if (showFilterPopup) {
            this.setState({
                showFilterPopup
            });
        }
        else if (!$('.daterangepicker').is(':visible')) {
            this.setState({
                showFilterPopup
            });
        }
    }

    getURLParamByName(parameterName) {
        var result = null,
            tmp = [];
        window.location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result !== null && !isNaN(parseInt(result)) ? parseInt(result) : result;
    }

    hideReleaseListHandler() {
        this.setState({ openReleaseList: false })
    }
    openReleaseListHandler() {
        this.props.hideAllAddForms();
        this.setState({ openReleaseList: true })
    }
    hideEditProjectHandler() {
        this.setState({ openProjectList: false })
    }
    openProjectListHandler() {
        this.props.hideAllAddForms();
        this.setState({ openProjectList: true })
    }

    hideReleaseHandler() {
        this.setState({ addRelease: false })
    }
    addReleaseHandler() {
        this.props.hideAllAddForms();
        this.setState({ addRelease: true })
    }

    hideProjectHandler(show) {
        this.setState({ addProject: show })
    }

    addProjectHandler() {
        this.props.hideAllAddForms();
        this.setState({ addProject: true })
    }

    setFilter(e) {
        var activeFilter = this.state.activeFilterType;
        $('#filterPopup .form-error').html('');
        if (activeFilter.length === 0) {
            $('#filterPopup .form-error').html('No filter is selected');
            return;
        }
        var filterDays = 1;
        if (activeFilter === 'date-range') {
            if (this.state.filterDateRange === '') {
                $('#filterPopup .form-error').html('Date range is not selected');
                return;
            }

            else {
                var startDate = this.state.filterDateRange.split('-')[0].trim();
                var endDate = this.state.filterDateRange.split('-')[1].trim();
                this.setState({
                    filterStartDate: new Date(startDate + " 00:00:00"),
                    filterEndDate: new Date(endDate + " 23:59:59"),
                });
            }
        }
        else if (activeFilter === 'last-n-days') {
            if (this.state.recentTaskModifiedDays === '0') {
                $('#filterPopup .form-error').html('No of days are not selected');
                return;
            }
            var start = new Date();
            filterDays = parseInt(this.state.recentTaskModifiedDays);
            start.setDate(start.getDate() - filterDays);

            this.setState({
                filterStartDate: new Date(moment(start).format('YYYY-MM-DD') + " 00:00:00"),
                filterEndDate: new Date(moment(new Date()).format('YYYY-MM-DD') + " 23:59:59"),
                filterDateRange: ''
            });
        }
        else if (activeFilter === 'before-n-days') {
            if (this.state.taskModifiedBeforeDays === '0') {
                $('#filterPopup .form-error').html('No of days are not selected');
                return;
            }
            var filterEndDate = new Date();
            filterDays = parseInt(this.state.taskModifiedBeforeDays);
            filterEndDate.setDate(filterEndDate.getDate() - filterDays)
            this.setState({
                filterStartDate: null,
                filterEndDate: new Date(moment(filterEndDate).format('YYYY-MM-DD') + " 23:59:59"),
                filterDateRange: ''
            });
        }

        localStorage.setItem("timeFilter", JSON.stringify({ activeFilterType: activeFilter, filterDays, filterDateRange: this.state.filterDateRange }));

        if (e) $('body').click();
        //$('#filterPopupDropdown, #filterPopupDropdown #filterPopup').removeClass('show'); 

    }

    clearFilter() {
        $('#filterPopup .form-group').removeClass("active-drop-color");
        this.setState({
            filterStartDate: null,
            filterEndDate: null,
            activeFilterType: '',
            recentTaskModifiedDays: '0',
            taskModifiedBeforeDays: '0',
            filterDateRange: ''
        }, function () {
            localStorage.removeItem("timeFilter");
            //$('#filterPopupDropdown, #filterPopupDropdown #filterPopup').removeClass('show'); 
            $('body').click();
        });
    }

    setActiveFilterType(activeFilterType) {
        if (this.state.activeFilterType === activeFilterType) return;
        var recentTaskModifiedDays = '0', taskModifiedBeforeDays = '0', filterDateRange = '';
        if (activeFilterType === "before-n-days") {
            recentTaskModifiedDays = '0';
            filterDateRange = '';
        }
        else if (activeFilterType === 'last-n-days') {
            taskModifiedBeforeDays = '0';
            filterDateRange = '';
        }
        this.setState({
            activeFilterType,
            recentTaskModifiedDays,
            taskModifiedBeforeDays,
            filterDateRange
        });
    }

    filterDateRangePickerChange(start, end) {
        this.setState({
            filterStartDate: start.toDate(),
            filterEndDate: end.toDate(),
            filterDateRange: start.format('MM-DD-YYYY - ') + end.format('MM-DD-YYYY'),
          
        });
    }

    toggleFilter() {
        this.setState({
            showFilterPopup: !this.state.showFilterPopup
        });
    }

    async loadMasterProjectDdl() {
        const storedToken = localStorage.getItem('keycloakToken');
        this.setState({
            masterProjects: await apis.getProjects(this.state.userId,this.state.isAdmin,storedToken),
        })
    }

    async loadMasterReleaseDdl() {
        const storedToken = localStorage.getItem('keycloakToken');
        this.setState({
            masterReleaseList: await apis.getReleaseList(this.state.masterProjectId,storedToken),
        });
    }

    async componentWillMount() {
        const storedToken = localStorage.getItem('keycloakToken');
        if (localStorage.getItem("timeFilter")) {
            var timeFilter = JSON.parse(localStorage.getItem("timeFilter"));
            if (timeFilter) {
                var recentTaskModifiedDays = '', taskModifiedBeforeDays = '';

                if (timeFilter.activeFilterType === 'last-n-days')
                    recentTaskModifiedDays = timeFilter.filterDays;
                else if (timeFilter.activeFilterType === 'before-n-days')
                    taskModifiedBeforeDays = timeFilter.filterDays;

                if (timeFilter.activeFilterType === 'date-range') {
                    var dates = timeFilter.filterDateRange.split(' - ');
                    this.setState({
                        filterStartDate: new Date(dates[0]),
                        filterEndDate: new Date(dates[0]),
                        filterDateRange: timeFilter.filterDateRange,
                        activeFilterType: timeFilter.activeFilterType,
                        recentTaskModifiedDays,
                        taskModifiedBeforeDays,
                    }, function () {
                        this.setFilter();
                    });
                } else {
                    this.setState({
                        activeFilterType: timeFilter.activeFilterType,
                        recentTaskModifiedDays,
                        taskModifiedBeforeDays,
                    }, function () {
                        this.setFilter();
                    });
                }
            }
        }

        this.setState({
            masterProjects: await apis.getProjects(this.state.userId,this.state.isAdmin,storedToken),
            masterUsers: await apis.getUsers(storedToken),
            isAdmin: this.props.isAdmin
        })
        if (this.state.masterProjectId) {
            this.setState({
                masterReleaseList: await apis.getReleaseList(this.state.masterProjectId,storedToken)
            });
        }

    }

    async masterProjectChange(e) {
        const storedToken = localStorage.getItem('keycloakToken');
        this.setState({
            masterProjectId: e.target.value,
            masterReleaseId: null
        });

        this.setState({
            masterReleaseList: await apis.getReleaseList(e.target.value,storedToken),
        });
        
    }
    setValue1(){
        this.setState({
            report1:true,
            report2:false,
            report3:false,
            report4:false,
            reportValue:"report1",
            generateValue:"report1"
        });
    }
    setValue2(){
        this.setState({
            report2:true,
            report1:false,
            report3:false,
            report4:false,
            reportValue:"report2",
            generateValue:"report2"
        });
    }
    setValue3(){
        this.setState({
            report3:true,
            report1:false,
            report2:false,
            report4:false,
            reportValue:"report3",
            generateValue:"report3"
        });
    }

    setValue4(){
        this.setState({
            report4:true,
            report2:false,
            report3:false,
            report1:false,
            reportValue:"report4",
            generateValue:"report4"
        });
    }
    masterReportChange(e) {
        if(e.target.value === 'report1'){
        this.setState({
            report1:true,
            reportValue:e.target.value,
            generateValue:e.target.value
        });
        this.setState({
            report2:false,
        });
        this.setState({
            report3:false,
        });
        this.setState({
            report4:false,
        });
    }
    else if(e.target.value === "report2"){
        this.setState({
            report2:true,
            reportValue:e.target.value,
            generateValue:e.target.value
        });
        this.setState({
            report1:false,
        });
        this.setState({
            report3:false,
        });
        this.setState({
            report4:false,
        });
    }
    else if(e.target.value === "report3"){
        this.setState({
            report3:true,
            reportValue:e.target.value,
            generateValue:e.target.value
        });
        this.setState({
            report2:false,
        });
        this.setState({
            report1:false,
        });
        this.setState({
            report4:false,
        });
    }
    else if(e.target.value === "report4"){
        this.setState({
            report4:true,
            reportValue:e.target.value,
            generateValue:e.target.value
        });
        this.setState({
            report2:false,
        });
        this.setState({
            report3:false,
        });
        this.setState({
            report1:false,
        });
    }
    }
    masterReleaseChange(e) {
        this.setState({
            masterReleaseId: e.target.value,
        });
    }

    masterUserChange(e) {
        this.setState({
            masterUserId: e.target.value,
        });
    }

    showHeaders() {
        $('.headerDdlMenu').toggleClass('show');
    }

    

   async GenerateReport(e){
    const storedToken = localStorage.getItem('keycloakToken');
    this.setState({
        loading:true
    })
      if(e.target.value === 'report1'){
        console.log("Report1")
       
        if(this.state.masterProjectId === null){
            toast.warning('Please select Project', {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({
                loading:false
            })
        }
         else if(this.state.masterReleaseId === null){
            toast.warning('Please select Release', {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({
                loading:false
            })
        }
        if((this.state.masterProjectId !== null) &&(this.state.masterReleaseId !== null) ){
        
            this.setState({
                report1Details: true,
                report2Details: false,
                report3Details: false,
                report4Details: false,
                masterReport1: await customFetch1((format(apiUrls.report1, this.state.masterProjectId,this.state.masterReleaseId)),storedToken),
                loading:false
            });
            console.log("Report1=",this.state.masterReport1)
      }
        }
        else if(e.target.value === 'report2')
        {
            console.log("Report2")
            if(this.state.masterProjectId === null){
                toast.warning('Please select Project', {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({
                    loading:false
                })
            }
             else if(this.state.masterReleaseId === null){
                toast.warning('Please select Release', {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({
                    loading:false
                })
            }
            else if(this.state.filterDateRange === ''){
                toast.warning('Please select Date Range', {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({
                    loading:false
                })
            }
            if((this.state.masterProjectId !== null) && (this.state.masterReleaseId !== null) && (this.state.filterDateRange !== '')){

           
            var dates = this.state.filterDateRange.split(' - ');
           
            this.setState({
                report2Details: true,
                report1Details: false,
                report3Details: false,
                report4Details: false,
                loading:false,
                masterReport2:await customFetch1((format(apiUrls.report2, this.state.masterProjectId,this.state.masterReleaseId,dates[0],dates[1])),storedToken),
            });
    }
        }
        else if(e.target.value === 'report3')
        {
            console.log("Report3")
            if(this.state.masterProjectId === null){
                toast.warning('Please select Project', {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({
                    loading:false
                })
            }
             else if(this.state.masterReleaseId === null){
                toast.warning('Please select Release', {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({
                    loading:false
                })
            }
            else if(this.state.masterUserId === null){
                toast.warning('Please select User', {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({
                    loading:false
                })
            }
            else if(this.state.filterDateRange === ''){
                toast.warning('Please select Date Range', {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({
                    loading:false
                })
            }

            if((this.state.masterProjectId !== null) && (this.state.masterReleaseId !== null) && (this.state.filterDateRange !== '') && (this.state.masterUserId !== null)){
                var dates = this.state.filterDateRange.split(' - ');
                this.setState({
                report3Details: true,
                report2Details: false,
                report1Details: false,
                report4Details: false,
                loading:false,
                masterReport3:await customFetch1((format(apiUrls.report3, this.state.masterProjectId,this.state.masterReleaseId,dates[0],dates[1],this.state.masterUserId)),storedToken),
            
            });
        
        }
        }
        else if(e.target.value === 'report4')
        {
            
            console.log("Report4")

            if(this.state.masterUserId === null){
                toast.warning('Please select User', {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({
                loading:false
            })
            }
            else if(this.state.filterDateRange === ''){
                toast.warning('Please select Date Range', {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({
                    loading:false
                })
            }
            if((this.state.filterDateRange !== '') && (this.state.masterUserId !== null)){
                var dates = this.state.filterDateRange.split(' - ');
            this.setState({
                report4Details: true,
                report3Details: false,
                report2Details: false,
                report1Details: false,
                loading:false,
                masterReport4:await customFetch1((format(apiUrls.report4,this.state.masterUserId,dates[0],dates[1])),storedToken)
            
            });
           
        }
    }
        
    }

    render() {
        return (<div><div id="header_area">
            <header id="navbar" className="w-100 float-left top-bar">
            <div className="navbar-nav ml-auto st-m">
                    <Logout />
                </div>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <button onClick={this.showHeaders} className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse headerDdlMenu" id="navbarSupportedContent">
                        <div className="navbar-nav mr-auto">
                            <div>
                                <div className="form-inline">
                                <div className="report">
                                        <a className="rt-list add-form-btn" href="/report">
                                                <i className="fa fa-flag" aria-hidden="true"></i> Report
                                            </a>
                                        </div>
                                    <div className="1 st-hide"> &nbsp; </div>

                                <div className="3 st-w-100 report">
                                        <div className="form-group">
                                            <Dropdown className="master-dropdown" filter placeholder="Select report" value={this.state.reportValue} onChange={this.masterReportChange} options={this.state.options} showClear panelClassName="master-dropdown-panel" />
                                        </div>
                                    </div>
                                    <div className="1 st-hide"> &nbsp; </div>

                                    <div className="4 st-w-100">
                                        <div className="form-group">
                                            <Dropdown className="master-dropdown master-dropdown-first" filter showClear placeholder="Select project" value={this.state.masterProjectId} onChange={this.masterProjectChange} options={this.state.masterProjects} panelClassName="master-dropdown-panel" style={{ display: !((this.state.report1) || (this.state.report2) || (this.state.report3)) && 'none' }}  />
                                        </div>
                                    </div>

                                    <div className="1 st-hide"> &nbsp; </div>
                                    <div className="3 st-w-100">
                                        <div className="form-group">
                                            <Dropdown className="master-dropdown" filter placeholder="Select release" value={this.state.masterReleaseId} onChange={this.masterReleaseChange} options={this.state.masterReleaseList} showClear panelClassName="master-dropdown-panel" style={{ display: !((this.state.report1) || (this.state.report2) || (this.state.report3)) && 'none' }} />
                                        </div>
                                    </div>
                                    <div className="1 st-hide"> &nbsp; </div>
                                    
                                        <div className="3 st-w-100">
                                            <div className="form-group">
                                                <Dropdown className="master-dropdown" filter showClear placeholder="Select user" value={this.state.masterUserId} onChange={this.masterUserChange} options={this.state.masterUsers} panelClassName="master-dropdown-panel" style={{ display: !((this.state.report3) ||(this.state.report4)) && 'none' }} />
                                            </div>
                                        </div> 
                                    <div className="1 st-hide"> &nbsp; </div>
                                    <BoostrapDropdown id="filterPopupDropdown" onToggle={this.onFilterPopupDropdownToggle} show={this.state.showFilterPopup} style={{ display: !( (this.state.report2) || (this.state.report3) ||(this.state.report4)) && 'none' }}>
                                        <BoostrapDropdown.Toggle as="span" title="Date filter" className="btn btn-light st-pdng-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-minus" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <rect x="4" y="5" width="16" height="16" rx="2" />
                                                <line x1="16" y1="3" x2="16" y2="7" />
                                                <line x1="8" y1="3" x2="8" y2="7" />
                                                <line x1="4" y1="11" x2="20" y2="11" />
                                                <line x1="10" y1="16" x2="14" y2="16" />
                                            </svg>
                                        </BoostrapDropdown.Toggle>
                                        <BoostrapDropdown.Menu id="filterPopup" className="p-3 wd-200">
                                           
                                         
                                          
                                            <div className="dropdown-divider"></div>
                                            <div className="row">
                                                <div className="col-sm-8 filter-input">
                                                    <div onClick={() => { this.setActiveFilterType('date-range') }} data-type="date-range" className={this.state.activeFilterType === "date-range" ? "form-group form-inline mb-2 active-drop-color" : "form-group form-inline mb-2"}>
                                                        <label className="font13"><strong>Date Range</strong></label>
                                                        <DateRangePicker initialSettings={{ autoApply: true, maxDate: new Date() }} onCallback={this.filterDateRangePickerChange}>
                                                            <input type="text" id="daterange" className="form-control" value={this.state.filterDateRange} onChange={() => { }} />
                                                        </DateRangePicker>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 filter-button">
                                                    <button type="button" onClick={this.setFilter} id="setFilter" className="btn btn-sm btn-light border-dark ml-2 btnCloseFilterPopup"><small className="btnCloseFilterPopup">Set</small></button>
                                                    <button type="button" onClick={this.clearFilter} className="btn btn-sm btn-light border-dark ml-2 btnCloseFilterPopup"><small className="btnCloseFilterPopup">Clear</small></button>
                                                </div>
                                            </div>
                                        </BoostrapDropdown.Menu>
                                    </BoostrapDropdown>
                                   
                                    {/* <div className=""  onClick={this.GenerateReport} href="#" target="_blank"><a className="btn-clr" value={this.state.reportValue} id="reportgenerate" style={{ display: !( (this.state.report1) || (this.state.report2) || (this.state.report3) ||(this.state.report4)) && 'none' }}>Run Report</a></div>*/}
                                   {/* <div className="" onClick={this.GenerateReport} style={{ display: !( (this.state.report1) || (this.state.report2) || (this.state.report3) ||(this.state.report4)) && 'none' }}>
                                        <a className="rt-list add-form-btn" >
                                                <i className="fa fa-flag" aria-hidden="true"></i> Run Report
                                            </a>
                                        </div>*/}
                                        <button type="submit" className="btn-clr" value={this.state.reportValue} style={{ display: !( (this.state.report1) || (this.state.report2) || (this.state.report3) ||(this.state.report4)) && 'none' }} onClick={this.GenerateReport}>Run Report</button>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="navbar-nav ml-auto">
                            <ul className="rt-pnl">
                                
                            <li>
                                        <div className="scrum">
                                        <a className="rt-list add-form-btn" href="/dashboard">
                                                <i aria-hidden="true"></i> ScrumBoard
                                            </a>
                                        </div>
                                    </li>
                                
                               

                                <li className="hide-m-v">
                                    <Logout />
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

            </header >
            
            
           
        </div >
         {!((this.state.report1Details) || (this.state.report2Details) || (this.state.report3Details)|| (this.state.report4Details)) &&
          <>
          {!(this.state.loading) ? 
          <>
          <div className="container report-container">
         
         <div className="row">
         <div className="col-md-12">
   
            <div className="text-center my-4">
              <div className="header-title">
               <h4>Report 1</h4><p>Select Project and Release to generate report for each project.</p></div><div class="header-scn"><div class="hd-image"><img src={process.env.PUBLIC_URL + 'images/projrct-release-report-1.jpg'} alt="Report"/></div><div onClick={this.setValue1}><a href="#" class="btn btn-primary">Run Report</a>
               </div>
               </div>
               </div>
               <div className="col-md-12">
                 <a className="image-responsive "><img src={process.env.PUBLIC_URL + 'images/report1-smark.png'}/></a>
              </div>
               </div>
      </div>	
      </div>
      <hr/>
      <div className="container report-container">
        
        <div className="row">
        <div className="col-md-12">
  
           <div className="text-center my-4">
  <div className="header-title">
              <h4>Report 2</h4>
              <p>Select Project, Release, Start Date and End Date to generate report for each project.</p></div><div class="header-scn"><div class="hd-image"><img src={process.env.PUBLIC_URL + 'images/select-release-date-report-2.jpg'} alt="Report"/></div><div onClick={this.setValue2}><a href="#" class="btn btn-primary">Run Report</a>
              </div>
              </div>
              </div>
              <div className="col-md-12">
                <a className="image-responsive "><img src={process.env.PUBLIC_URL + 'images/repoer2-smark.png'}/></a>
             </div>
              </div>
     </div>	
     </div>
      <hr/>
      <div className="container report-container">
        
         <div className="row">
         <div className="col-md-12">
   
            <div className="text-center my-4">
            <div className="header-title">
               <h4>Report 3</h4><p>Select Project, Release, User, Start Date and End Date to generate report for each project.</p></div><div class="header-scn"><div class="hd-image"><img src={process.env.PUBLIC_URL + 'images/release-user.jpg'} alt="Report"/></div><div onClick={this.setValue3}><a href="#" class="btn btn-primary">Run Report</a>
               </div>
               </div>
               </div>
               <div className="col-md-12">
                 <a className="image-responsive "><img src={process.env.PUBLIC_URL + 'images/report3-smark.png'}/></a>
              </div>
               </div>
      </div>	
      </div>
      <hr/>
      <div className="container report-container">
        
         <div className="row">
           
         <div className="col-md-12">
         <div className="report-result">
            <div className="text-center my-4">
            <div className="header-title">
               <h4>Report 4</h4><p>Select User, Start Date and End Date to generate report for each project.</p></div><div class="header-scn"><div class="hd-image"><img src={process.env.PUBLIC_URL + 'images/user-date.jpg'} alt="Report"/></div><div onClick={this.setValue4}><a href="#" class="btn btn-primary">Run Report</a>
               </div>
               </div>
               </div>
               <div className="col-md-12">
                 <a className="image-responsive "><img src={process.env.PUBLIC_URL + 'images/report4-smark.png'}/></a>
              </div>
              </div>
               </div>
      </div>	
      </div>
   <hr/>
          </>: 
          <div className="spinner">
          <Loader type="Oval" color="#191f39" height="70" width="70" />
        </div>
          }
          </> }

         {((this.state.report1Details) && (this.state.masterReport1)) &&
         <>
         <Report1 rvalue={this.state.masterReport1} loading={this.state.loading}/>
         </>
         }

         {((this.state.report2Details) && (this.state.masterReport2)) &&
         <>
         <Report2 r2value={this.state.masterReport2} dateRange={this.state.filterDateRange} loading={this.state.loading} />
         </>
         }

         {((this.state.report3Details) && (this.state.masterReport3)) &&
         <>
         <Report3 r3value={this.state.masterReport3} dateRange={this.state.filterDateRange} loading={this.state.loading} />
         </>
         }

        {((this.state.report4Details) && (this.state.masterReport4)) &&
         <>
         <Report4 r4value={this.state.masterReport4} dateRange={this.state.filterDateRange} loading={this.state.loading} />
         </>
         }
       
        </div>
        
        );
    }
}

export default withRouter(ReportHeader);