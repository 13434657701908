import React, { useEffect, useState, useContext } from "react"
import { toast } from "react-toastify";
import { getTaskList } from "../../helper/context/actions";
import { useSmarkDispatch } from "../../Context/KeycloakContext";
import './masterSearch.css'
import { trackPromise } from 'react-promise-tracker';
import { KeycloackContext } from '../../Context/KeycloakContext';

const MasterSearch = (props) => {

    const [disable, setDisable] = useState(false);

    const dispatch = useSmarkDispatch();
    const storedToken = localStorage.getItem('keycloakToken');
    const masterSearchHandler = () => {
        props.getTaskList(props.data, 0);

        let data = {
            masterProjectId: props.data['masterProjectId'] ? props.data['masterProjectId'] : 0,
            masterReleaseId: props.data['masterReleaseId'] ? props.data['masterReleaseId'] : 0,
            masterUserId: props.data['masterUserId'] ? props.data['masterUserId'] : 0,
            userStoryId: 0,
            filterStartDate: props.data['filterStartDate'],
            filterEndDate: props.data['filterEndDate']
        }
        setDisable(true);
        trackPromise(
            getTaskList(dispatch, data, storedToken).then((response) => {
                if (response && response.taskDetails && response.taskDetails.length > 0) {
                    toast.success('Task list fetch successfully!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    toast.warning('No record found!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                setDisable(false);
            }).catch((error) => {
                console.log(error);
            })
        );
    }

    useEffect(() => {
        if (props.data.masterProjectId && props.data.masterReleaseId) {
            masterSearchHandler();
        }
    }, [props.loadTaskHit]);

    return (
        <span style={{ pointerEvents: disable ? 'none' : 'unset' }} title="Search" id="search" type="button" className="text-white btn btn-light btn-sm ml-2" onClick={masterSearchHandler} data-toggle="tooltip" data-placement="top">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
            </svg>
        </span>
    )
}
export default MasterSearch;