import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import AsyncSelect from 'react-select/async';
import { toast } from "react-toastify";
import apis from '../../services/apis';
import Comments from './comments';
import $ from 'jquery';
import apiUrls from '../../constants/apiUrls';
import { customFormDataPost } from '../../helper/api-helper/apiHerlpers';
import apiCommonParams from '../../constants/apiCommonParams';
import { useKeycloakState } from '../../Context/KeycloakContext';

class AddTask extends React.Component {
   
    constructor(props) {
        super(props);
        this.timeout = null;
        var tasksModel = {
            taskId: 0,
            name: "",
            description: "",
            status: "",
            projectId: "",
            releaseId: "",
            userStoryId: "",
            userStoryTitle: "",
            selectedUserStory: {},
            primaryAssigneeId: "",
            paDOE: "",
            secondaryAssignees: [{
                secondaryAssigneeId: null,
                saDOE: "",
                saDTR: "",
                saDTT: ""
            }],
            primaryTesterId: "",
            ptQOE: "",
            secondaryTesters: [{
                secondaryTesterId: null,
                stQOE: "",
                stQTR: "",
                stQTT: ""
            }],
            comments: []
        };

        if (props.task) {
            tasksModel.editUrl = `${window.location.origin}${window.location.pathname}?t=${props.task.taskId}`;
            tasksModel.taskId = props.task.taskId;
            tasksModel.name = props.task.title;
            tasksModel.description = props.task.description;
            tasksModel.status = props.task.status.toLowerCase();
            tasksModel.projectId = props.task.projectId;
            tasksModel.releaseId = props.task.releaseId;
            tasksModel.userStoryId = props.task.userStoryId;
            tasksModel.userStoryTitle = props.task.userStoryTitle;
            tasksModel.selectedUserStory = { value: props.task.userStoryId, label: props.task.userStoryTitle };
            tasksModel.getDefaultStoryies = [{ value: props.task.userStoryId, label: props.task.userStoryTitle }];

            if (props.task.primaryAssignee) {
                if (props.task.primaryAssignee.user)
                    tasksModel.primaryAssigneeId = props.task.primaryAssignee.user.userId;

                tasksModel.paDOE = props.task.primaryAssignee.estimateTime;
                tasksModel.paDTT = props.task.primaryAssignee.timeTaken;
                tasksModel.paDTR = props.task.primaryAssignee.remainingTime;
            }

            if (props.task.primaryTester) {
                if (props.task.primaryTester.user)
                    tasksModel.primaryTesterId = props.task.primaryTester.user.userId;

                tasksModel.ptQOE = props.task.primaryTester.estimateTime;
                tasksModel.ptQTR = props.task.primaryTester.remainingTime;
                tasksModel.ptQTT = props.task.primaryTester.timeTaken;
            }

            if (props.task.secondaryAssignees && props.task.secondaryAssignees.length > 0) {
                tasksModel.secondaryAssignees = props.task.secondaryAssignees.map(x => {
                    return {
                        showRemove: props.isAdmin && x.timeTaken === 0 && props.task.commentsList.filter(x1 => x1.userCreated === x.user.userId).length === 0,
                        secondaryAssigneeId: x.user.userId,
                        saDOE: x.estimateTime,
                        saDTR: x.remainingTime,
                        saDTT: x.timeTaken
                    }
                });
            }
            else {
                tasksModel.secondaryAssignees = [];
            }

            if (props.task.secondaryTesters && props.task.secondaryTesters.length > 0) {
                tasksModel.secondaryTesters = props.task.secondaryTesters.map(x => {
                    return {
                        showRemove: props.isAdmin && x.timeTaken === 0 && props.task.commentsList.filter(x1 => x1.userCreated === x.user.userId).length === 0,
                        secondaryTesterId: x.user.userId,
                        stQOE: x.estimateTime,
                        stQTR: x.remainingTime,
                        stQTT: x.timeTaken
                    }
                });
            }
            else {
                tasksModel.secondaryTesters = [];
            }
            tasksModel.comments = props.task.commentsList
        }

        let header_data = {
            masterProjectId: 0,
            masterReleaseId: 0,
            masterUserId: 0,
            userStoryId: 0,
            filterStartDate: 0,
            filterEndDate: 0
        }
        if (props.header_data) {
            header_data.masterProjectId = props.header_data.masterProjectId;
            header_data.masterReleaseId = props.header_data.masterReleaseId;
            header_data.masterUserId = props.header_data.masterUserId;
            header_data.userStoryId = 0;
            header_data.filterStartDate = props.header_data.filterStartDate;
            header_data.filterEndDate = props.header_data.filterEndDate;
        }

        this.state = {
            projects: [],
            releaseList: [],
            users: [],
            stories: [{ value: 'Open', lable: 'Open' },],
            aCount: 0,
            tasksModel,
            userId:props.userId,
            defaultStoryies: [],
            header_data,
            isDisabledDropdown: false,
            isAdmin: props.isAdmin,
            disabledEditDropdown: !props.isAdmin && tasksModel.taskId > 0
        }
        this.hideAddTask = props.hideAddTask;
        this.projectChange = this.projectChange.bind(this);
        this.statusChange = this.statusChange.bind(this);
        this.releaseChange = this.releaseChange.bind(this);
        this.secondaryAssigneeChange = this.secondaryAssigneeChange.bind(this);
        this.saDOEChange = this.saDOEChange.bind(this);
        this.addSecondaryAssignee = this.addSecondaryAssignee.bind(this);

        this.secondaryTesterChange = this.secondaryTesterChange.bind(this);
        this.stQOEChange = this.stQOEChange.bind(this);
        this.addSecondaryTester = this.addSecondaryTester.bind(this);

        this.addTask = this.addTask.bind(this);
        this.getStories = this.getStories.bind(this);
        this.getUserStoryMessage = this.getUserStoryMessage.bind(this);
        this.deleteSecondaryAssignee = this.deleteSecondaryAssignee.bind(this);
        this.setActiveDropdown = this.setActiveDropdown.bind(this);
        this.hideValidationMessagesIfValid = this.hideValidationMessagesIfValid.bind(this);
        $(document).on('keydown', 'input[type="number"]', function (e) {
            if (e.keyCode === 189 || e.keyCode === 109 || e.keyCode === 69) {
                e.preventDefault();
            }
        });
    }

    setActiveDropdown(isDisabledDropdown) {
        this.setState({ isDisabledDropdown });
    }

    getUserStoryMessage(e) {
        if (!this.state.tasksModel.projectId)
            return 'Select a project';
        else if (e.inputValue === '')
            return 'Please enter 1 or more characters';
        return 'Searching user story...';
    }

    async getUserStories(projectId, search) {
        const storedToken = localStorage.getItem('keycloakToken');
        var t = this;
        return new Promise(function (resolve) {
            clearTimeout(t.timeout);
            t.timeout = setTimeout(async () => {
                resolve(await apis.getTaskEditStories(projectId, search,storedToken));
            }, 1000);
        });
    }
    async getStories(e) {
        const storedToken = localStorage.getItem('keycloakToken');
        var projectId = this.state.tasksModel.projectId;
        if (projectId) {
            var a = await this.getUserStories(projectId, e,storedToken)
            return a;
        }
        return [];
    }
    async hideValidationMessagesIfValid(e, selector) {
        if (e === 'byselector') {
            $(selector).hide();
        }
        else {
            setTimeout(() => {
                $('#addTaskForm .required-field').each(function () {
                    var value = $(this).hasClass("form-control") ? $(this).val() : $(this).find('select').val();
                    if (value !== "" && value !== null) {
                        $(this).closest('.form-group').find('.form-error.required').hide();
                    }
                });
                $(".secondary-assignee").each(function (i, v) {
                    var saName = v.querySelector('[name ="saName"]').value;
                    var saDOE = v.querySelector('[name ="saDOE"]').value;
                    if ((saName === "" && saDOE === "") || (saName !== "" && saDOE !== "")) {
                        $(this).find('.sa-error-msg').remove();
                    }
                });
                $(".secondary-tester").each(function (i, v) {
                    var stName = v.querySelector('[name ="stName"]').value;
                    var stQOE = v.querySelector('[name ="stQOE"]').value;
                    if ((stName === "" && stQOE === "") || (stName !== "" && stQOE !== "")) {
                        $(this).find('.st-error-msg').remove();
                    }
                });
            }, 500);
        }
    }

    async addTask(e) {
        var status = true;
        $('#addTaskForm .form-error.required').hide();
        $('#addTaskForm .required-field').each(function () {
            var value = $(this).hasClass("form-control") ? $(this).val() : $(this).find('select').val();
            if (!value) {
                status = false;
                $(this).closest('.form-group').find('.form-error.required').show();
            }
        });
        if (this.state.tasksModel.userStoryId === "") {
            status = false;
            $('.user-story-required').show();
        }

        $('.sa-error-msg, .st-error-msg').remove();
        var saNames = [], saDOEs = [], stNames = [], stQOEs = [];
        var _assignees = [this.state.tasksModel.primaryAssigneeId];
        var _testers = [this.state.tasksModel.primaryTesterId];
        $(".secondary-assignee").each(function (i, v) {
            var saName = v.querySelector('[name ="saName"]').value;
            var saDOE = v.querySelector('[name ="saDOE"]').value;
            if (_assignees.includes(parseInt(saName)) && saName !== "") {
                status = false;
                $(this).append('<div class="col-sm-12 form-error text-danger st-error-msg">Can\'t add same person multiple times with the same role</div>');
            }
            if (saName !== "" && saDOE !== "") {
                saNames.push(parseInt(saName));
                saDOEs.push(saDOE);
            } else if (!_assignees.includes(parseInt(saName)) && (saName !== "" || saDOE !== "")) {
                $(this).append('<div class="col-sm-12 form-error text-danger sa-error-msg">SA name and SA DOE is required</div>');
                status = false;
            }
            _assignees.push(parseInt(saName));
        });
        $(".secondary-tester").each(function (i, v) {
            var stName = v.querySelector('[name ="stName"]').value;
            var stQOE = v.querySelector('[name ="stQOE"]').value;
            if (_testers.includes(parseInt(stName)) && stName !== "") {
                status = false;
                $(this).append('<div class="col-sm-12 form-error text-danger st-error-msg">Can\'t add same person multiple times with the same role</div>');
            }
            if (stName !== "" && stQOE !== "") {
                stNames.push(parseInt(stName));
                stQOEs.push(stQOE);
            } else if (!_testers.includes(parseInt(stName)) && (stName !== "" || stQOE !== "")) {
                $(this).append('<div class="col-sm-12 form-error text-danger st-error-msg">ST name and ST QOE is required</div>');
                status = false;
            }
            _testers.push(parseInt(stName));

        });

        if (status) {
            //apiUrls.addTask
            const storedToken = localStorage.getItem('keycloakToken');
            var btnAddTask = $('#btnAddTask');
            var formData = new FormData();
            var task = {
                taskId: this.state.tasksModel.taskId,
                title: this.state.tasksModel.name,
                description: this.state.tasksModel.description,
                releaseId: this.state.tasksModel.releaseId,
                projectId: this.state.tasksModel.projectId,
                primaryAssignee: parseInt(this.state.tasksModel.primaryAssigneeId),
                secondaryAssignee: saNames.join(),
                primaryTester: parseInt(this.state.tasksModel.primaryTesterId),
                secondaryTester: stNames.join(),
                applicationId: apiCommonParams.applicationid,
                siteId: apiCommonParams.siteid,
                userCreated: apiCommonParams.userid,
                userModified: apiCommonParams.userid,
                createdTime: new Date().getTime(),
                updatedTime: new Date().getTime(),
                devEstimateTime: parseFloat(this.state.tasksModel.paDOE),
                devTimeTaken: 0,
                devRemainingTime: 0,
                qaEstimateTime: parseFloat(this.state.tasksModel.ptQOE),
                qaTimeTaken: 0,
                qaRemainingTime: 0,
                status: this.state.tasksModel.status,
                userStoryId: this.state.tasksModel.userStoryId,
            }

            formData.append('task', JSON.stringify(task));
            formData.append('devPrimEstimate', parseFloat(this.state.tasksModel.paDOE));
            formData.append('devSecEstimate', saDOEs.join());
            formData.append('qaPrimEstimate', parseFloat(this.state.tasksModel.ptQOE));
            formData.append('qaSecEstimate', stQOEs.join());
            formData.append('swcmTicket', apiCommonParams.swcmTicket);

            btnAddTask.attr('disabled', 'disabled');
            btnAddTask.text('Please wait...');

            var data = await customFormDataPost(apiUrls.addTask, formData,storedToken);
            btnAddTask.removeAttr('disabled');
            btnAddTask.text('Submit');

            if (data.response && data.response === "success") {
                this.hideAddTask(false, null, this.state.header_data, true);
                var message = this.state.tasksModel.taskId === 0 ? 'Task has been created successfully!' : 'Task has been updated successfully!';
                toast.success(message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error('Something went wrong, please try again later!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    secondaryTesterChange(key, e) {
        var secondaryTesters = this.state.tasksModel.secondaryTesters;;
        secondaryTesters[key].secondaryTesterId = e.target.value;
        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, secondaryTesters } }))
        this.hideValidationMessagesIfValid(e);
    }

    stQOEChange(key, e) {
        var secondaryTesters = this.state.tasksModel.secondaryTesters;;
        secondaryTesters[key].stQOE = e.target.value;
        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, secondaryTesters } }))
        this.hideValidationMessagesIfValid(e);
    }

    addSecondaryTester() {
        var secondaryTesters = this.state.tasksModel.secondaryTesters;
        if (secondaryTesters)
            secondaryTesters.push({
                secondaryTesterId: null,
                stQOE: "",
                stQTT: 0,
                stQTR: 0,
            });
        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, secondaryTesters } }));

    }

    addSecondaryAssignee() {
        var secondaryAssignees = this.state.tasksModel.secondaryAssignees;
        if (secondaryAssignees)
            secondaryAssignees.push({
                secondaryAssigneeId: null,
                saDOE: "",
                saDTT: 0,
                saDTR: 0,
            });
        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, secondaryAssignees } }));

    }

    async deleteSecondaryAssignee(userId, type) {
        const storedToken = localStorage.getItem('keycloakToken');
        if (window.confirm("Are you sure you want to remove this user?")) {
            //removeTaskAssignee
            if (this.state.tasksModel.taskId > 0) {
                if (type === 'assignee') {
                    var secondaryAssignees = this.state.tasksModel.secondaryAssignees.filter(x => x.secondaryAssigneeId !== userId);
                    this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, secondaryAssignees } }));
                } else {
                    var secondaryTesters = this.state.tasksModel.secondaryTesters.filter(x => x.secondaryTesterId !== userId);
                    this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, secondaryTesters } }));
                }
                var formData = new FormData();
                formData.append('swcmTicket', apiCommonParams.swcmTicket);
                formData.append('applicationid', apiCommonParams.applicationid);
                formData.append('siteid', apiCommonParams.siteid);
                formData.append('userid', userId);
                formData.append('taskid', this.state.tasksModel.taskId);

                await customFormDataPost(apiUrls.removeTaskAssignee, formData,storedToken);
                toast.success("User has been removed from task!", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    async deleteTaskById(taskId) {
        const storedToken = localStorage.getItem('keycloakToken');
        if (!taskId)
            return false;

        if (window.confirm('Are you sure you want to delete this task?')) {
            var formData = new FormData();
            formData.append('swcmTicket', apiCommonParams.swcmTicket);
            formData.append('applicationid', apiCommonParams.applicationid);
            formData.append('siteid', apiCommonParams.siteid);
            formData.append('taskid', taskId);

            var btn = $('#btnDeleteTask');
            btn.html('Please wait...');
            btn.attr('disabled', 'disabled')

            var data = await customFormDataPost(apiUrls.deleteTask, formData,storedToken);

            if (data.response && data.response === "success") {
                this.hideAddTask(false, null, this.state.header_data, true);
                toast.success("Task has been deleted!", {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error('Something went wrong, please try again later!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        }
    }

    secondaryAssigneeChange(key, e) {
        var secondaryAssignees = this.state.tasksModel.secondaryAssignees;;
        secondaryAssignees[key].secondaryAssigneeId = e.target.value;
        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, secondaryAssignees } }))
        this.hideValidationMessagesIfValid(e);
    }

    saDOEChange(key, e) {
        var secondaryAssignees = this.state.tasksModel.secondaryAssignees;;
        secondaryAssignees[key].saDOE = e.target.value;
        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, secondaryAssignees } }))
        this.hideValidationMessagesIfValid(e);
    }

    async componentWillMount() {
        const storedToken = localStorage.getItem('keycloakToken');
        this.setState({
            projects: await apis.getProjects(this.state.userId,this.state.isAdmin,storedToken),
            users: this.props.userList,
        })

        if (this.props.task) {
            var releaseList = await apis.getReleaseList(this.props.task.projectId,storedToken);

            this.setState({
                releaseList: releaseList,
            });
        }
    }

    async projectChange(e) {
        const storedToken = localStorage.getItem('keycloakToken');
        this.setState({
            releaseList: [],
            userStoryId: ""
        })
        var value = e.target.value;
        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, projectId: value } }));
        var releaseList = await apis.getReleaseList(value,storedToken);
        this.setState({
            releaseList: releaseList,
        });
        this.hideValidationMessagesIfValid(e);
    }

    statusChange(e) {
        var value = e.target.value;
        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, status: value } }));
        this.hideValidationMessagesIfValid(e);
    }

    releaseChange(e) {
        var value = e.target.value;
        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, releaseId: value } }));
        this.hideValidationMessagesIfValid(e);
    }

    copyHtmlOnClick(e) { 
        var tempInput = document.createElement("input");
        tempInput.value = e.target.textContent;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput); 
        toast.success('Copied!')
    }

    render() {
        return (<div className="form-sec shadow-lg add-form-sec">
            <div className="form-blog w-100 float-left position-relative">
                <span onClick={() => { this.hideAddTask(false) }} className="close-form-icon"><i className="fa fa-times" aria-hidden="true"></i> </span>
                {this.state.tasksModel.editUrl ?
                    <div className="st-copy-btns">
                        <button type="button" className="btn btn-sm btn-dark" onClick={this.copyHtmlOnClick}>{this.state.tasksModel.taskId}</button>
                        <button type="button" className="btn btn-sm btn-dark" onClick={this.copyHtmlOnClick} title={this.state.tasksModel.editUrl}><p>{this.state.tasksModel.editUrl}</p></button>
                    </div> : ""}
                <h2>{this.props.task ? "Edit task" : "Add New Task"}</h2>
                <div className="task-inner-scroll">
                <form id="addTaskForm" className="frm">
                    <div className="form-group">
                        <label htmlFor="">Name</label>
                        <input value={this.state.tasksModel.name} onChange={e => {
                            var value = e.target.value;
                            this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, name: value } }))
                            this.hideValidationMessagesIfValid(e);
                        }}
                            type="text" className="form-control required-field" placeholder="Enter Name" />
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Task name is required</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Description</label>
                        <textarea value={this.state.tasksModel.description} onChange={e => {
                            var value = e.target.value;
                            this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, description: value } }))
                            this.hideValidationMessagesIfValid(e);
                        }}
                            className="form-control required-field" rows="3" placeholder="Enter Description"></textarea>
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Task description is required</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Status</label>
                        <Dropdown disabled={this.state.isDisabledDropdown} className="required-field" style={{ width: '100%' }} filter placeholder="-Select status-" value={this.state.tasksModel.status} onChange={this.statusChange} options={apis.statusList} />
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Task status is required</span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Project</label>
                        <Dropdown disabled={this.state.isDisabledDropdown || this.state.disabledEditDropdown} className="required-field" style={{ width: '100%' }} filter placeholder="-Select project-" value={this.state.tasksModel.projectId} onChange={this.projectChange} options={this.state.projects} />
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Project is required</span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Release</label>
                        <Dropdown disabled={this.state.isDisabledDropdown || this.state.disabledEditDropdown} className="required-field" style={{ width: '100%' }} filter placeholder="-Select release-" value={this.state.tasksModel.releaseId} onChange={this.releaseChange} options={this.state.releaseList} />
                        <span className="form-error required text-danger" style={{ display: 'none' }}>Release is required</span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">User Story</label>
                        {this.state.tasksModel.taskId > 0 && !this.state.isAdmin ?
                            <input defaultValue={this.state.tasksModel.userStoryTitle} disabled={true} type="text" className="form-control" />
                            :
                            <AsyncSelect value={this.state.tasksModel.selectedUserStory} isDisabled={this.state.isDisabledDropdown || this.state.disabledEditDropdown} style={{ width: '100%' }} filter placeholder="-Select user story-" onChange={selectedItem => {
                                var value = selectedItem === null ? "" : selectedItem.value;
                                this.setState(prevState => ({ tasksModel: { 
                                        ...prevState.tasksModel, 
                                        userStoryId: value, 
                                        selectedUserStory: { value: selectedItem.value, label: selectedItem.label }
                                    }
                                 }));
                                this.hideValidationMessagesIfValid("byselector", ".user-story-required");
                            }} defaultOptions={this.state.tasksModel.getDefaultStoryies} loadOptions={this.getStories}
                                loadingMessage={this.getUserStoryMessage} getOptionValue={(option) => option.value} getOptionLabel={(option) => option.label} />
                        }
                        <span className="form-error user-story-required required text-danger" style={{ display: 'none' }}>User Story is required</span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Primary Assignee</label>
                        <Dropdown disabled={this.state.isDisabledDropdown || this.state.disabledEditDropdown} className="required-field" style={{ width: '100%' }} filter placeholder="-Select Primary Assignee-" value={this.state.tasksModel.primaryAssigneeId} onChange={e => {
                            var value = e.target.value;
                            this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, primaryAssigneeId: value } }))
                            this.hideValidationMessagesIfValid(e);
                        }} options={this.state.users} />

                        <span className="form-error required text-danger" style={{ display: 'none' }}>Primary assignee is required</span>
                    </div>
                    {this.state.tasksModel.taskId === 0 ?
                        <div className="form-group">
                            <label htmlFor="">PA DOE</label>
                            <input min="0" value={this.state.tasksModel.paDOE} onChange={e => {
                                var value = e.target.value;
                                this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, paDOE: value } }))
                                this.hideValidationMessagesIfValid(e);
                            }}
                                type="number" className="form-control required-field" placeholder="Enter PA DOE" />
                            <span className="form-error required text-danger" style={{ display: 'none' }}>PA DOE is required</span>
                        </div>
                        :
                        <div className="w-100">
                            <div className="row">
                                <div className="form-group w30">
                                    <label htmlFor="" className="font12">PA DOE</label>
                                    <input min="0" value={this.state.tasksModel.paDOE} onChange={e => {
                                        var value = e.target.value;
                                        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, paDOE: value } }))
                                        this.hideValidationMessagesIfValid(e);
                                    }}
                                        type="number" className="form-control required-field" placeholder="Enter PA DOE" />
                                    <span className="form-error required text-danger" style={{ display: 'none' }}>PA DOE is required</span>
                                </div>
                                <div className="form-group w30">
                                    <label htmlFor="" className="font12">PA DTT</label>
                                    <input min="0" disabled={true} defaultValue={this.state.tasksModel.paDTT} type="number" className="form-control" placeholder="Enter PA DTT" />
                                </div>
                                <div className="form-group w30">
                                    <label htmlFor="" className="font12">PA DTR</label>
                                    <input min="0" disabled={true} defaultValue={this.state.tasksModel.paDTR} type="number" className="form-control" placeholder="Enter PA DTR" />
                                </div>

                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-sm-12">
                            <label htmlFor="" >Secondary Assignee</label>
                            {this.state.tasksModel.secondaryAssignees.length === 0 ?
                                <p>
                                    <span style={{ cursor: "pointer" }} id="addFirstSecondaryAssignee" onClick={this.addSecondaryAssignee}><i className="fa fa-plus" style={{ fontSize: '14px' }}></i> Add Secondary Assignee</span>
                                </p> : ""}

                            {this.state.tasksModel.secondaryAssignees.map((user, key) =>
                                this.state.tasksModel.taskId > 0 ?
                                    <div key={key} className="secondary-assignee">
                                        <div className="row">
                                            <div className="col-sm-11">
                                                <div className="form-group">
                                                    <label className="font10">Name</label>
                                                    <Dropdown showClear disabled={user.showRemove !== undefined} name="saName" value={user.secondaryAssigneeId} onChange={e => this.secondaryAssigneeChange(key, e)} style={{ width: '100%' }} filter placeholder="-Select Secondary Assignee-" options={this.state.users} />
                                                </div>
                                            </div>
                                            <div className="col-sm-1">
                                                {user.showRemove ?
                                                    <span id="removeAssignee" onClick={() => this.deleteSecondaryAssignee(user.secondaryAssigneeId, "assignee")} style={{ cursor: 'pointer', margin: '25px 10px 0 0' }} className="float-right" ><i className="fa fa-times" aria-hidden="true"></i> </span>
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <div className="form-group w30" style={{ paddingLeft: '0' }}>
                                                    <label htmlFor="" className="font12">SA DOE</label>
                                                    <input value={user.saDOE} onChange={e => this.saDOEChange(key, e)} type="number" min="0" name="saDOE" className="form-control" placeholder="Enter SA DOE" />
                                                </div>
                                                <div className="form-group w30 style={{paddingLeft:'0'}}">
                                                    <label htmlFor="" className="font12">SA DTT</label>
                                                    <input type="number" disabled="disabled" name="saDTT" defaultValue={user.saDTT} min="0" className="form-control" />
                                                </div>
                                                <div className="form-group w30 style={{paddingLeft:'0',paddingRight:'0'}}">
                                                    <label htmlFor="" className="font12">SA DTR</label>
                                                    <input type="number" disabled="disabled" name="saDTR" defaultValue={user.saDTR} min="0" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                {this.state.tasksModel.secondaryAssignees.length > 0 && (key + 1) === this.state.tasksModel.secondaryAssignees.length && this.state.isAdmin ?
                                                    <span className="plus-btn text-center" onClick={this.addSecondaryAssignee}><i className="fa fa-plus" aria-hidden="true"></i> </span>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row secondary-assignee" key={key}>
                                        <div className="col-sm-5">
                                            <div className="form-group">
                                                <label htmlFor="" className="font10">Name</label>
                                                <Dropdown showClear name="saName" value={user.secondaryAssigneeId} onChange={e => this.secondaryAssigneeChange(key, e)} style={{ width: '100%' }} filter placeholder="-Select Secondary Assignee-" options={this.state.users} />
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="form-group">
                                                <label htmlFor="" className="font10">SA DOE</label>
                                                <input value={user.saDOE} onChange={e => this.saDOEChange(key, e)} type="number" min="0" name="saDOE" className="form-control" placeholder="Enter SA DOE" />
                                            </div>
                                        </div>
                                        {this.state.tasksModel.secondaryAssignees.length > 0 && (key + 1) === this.state.tasksModel.secondaryAssignees.length ?
                                            <div className="col-sm-2">
                                                <span className="plus-btn text-center" onClick={this.addSecondaryAssignee}><i className="fa fa-plus" aria-hidden="true"></i> </span>
                                            </div> : ""}
                                    </div>
                            )}
                        </div >
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Primary Tester</label>
                        <Dropdown disabled={this.state.isDisabledDropdown || this.state.disabledEditDropdown} className="required-field" style={{ width: '100%' }} filter placeholder="-Select Primary Tester-" value={this.state.tasksModel.primaryTesterId} onChange={e => {
                            var value = e.target.value;
                            this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, primaryTesterId: value } }))
                            this.hideValidationMessagesIfValid(e);
                        }} options={this.state.users} />

                        <span className="form-error required text-danger" style={{ display: 'none' }}>Primary tester is required</span>
                    </div>

                    {this.state.tasksModel.taskId === 0 ?

                        <div className="form-group">
                            <label htmlFor="">PT QOE</label>
                            <input value={this.state.tasksModel.ptQOE} onChange={e => {
                                var value = e.target.value;
                                this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, ptQOE: value } }))
                                this.hideValidationMessagesIfValid(e);
                            }}
                                type="number" min="0" className="form-control required-field" placeholder="Enter PT QOE" />
                            <span className="form-error required text-danger" style={{ display: 'none' }}>PA QOE is required</span>
                        </div>
                        :
                        <div className="w-100">
                            <div className="row">
                                <div className="form-group w30">
                                    <label htmlFor="" className="font12">PT QOE</label>
                                    <input min="0" value={this.state.tasksModel.ptQOE} onChange={e => {
                                        var value = e.target.value;
                                        this.setState(prevState => ({ tasksModel: { ...prevState.tasksModel, ptQOE: value } }))
                                        this.hideValidationMessagesIfValid(e);
                                    }}
                                        type="number" className="form-control required-field" placeholder="Enter PT QOE" />
                                    <span className="form-error required text-danger" style={{ display: 'none' }}>PA QOE is required</span>
                                </div>
                                <div className="form-group w30">
                                    <label htmlFor="" className="font12">PT QTT</label>
                                    <input min="0" disabled={true} defaultValue={this.state.tasksModel.ptQTT} type="number" className="form-control" placeholder="Enter PT QTT" />
                                </div>
                                <div className="form-group w30">
                                    <label htmlFor="" className="font12">PT QTR</label>
                                    <input min="0" disabled={true} defaultValue={this.state.tasksModel.ptQTR} type="number" className="form-control" placeholder="Enter PT QTR" />
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-sm-12">
                            <label htmlFor="">Secondary Tester</label>
                            {this.state.tasksModel.secondaryTesters.length === 0 ?
                                <p>
                                    <span style={{ cursor: "pointer" }} id="addFirstSecondaryTester" onClick={this.addSecondaryTester} ><i className="fa fa-plus" style={{ fontSize: '14px' }}></i> Add Secondary Tester</span>
                                </p> : ""}

                            {this.state.tasksModel.secondaryTesters.map((user, key) =>
                                this.state.tasksModel.taskId > 0 ?
                                    <div key={key} className="secondary-tester">
                                        <div className="row">
                                            <div className="col-sm-11 m-view-st-c">
                                                <div className="form-group">
                                                    <label className="font10">Name</label>
                                                    <Dropdown showClear disabled={user.showRemove !== undefined} name="stName" value={user.secondaryTesterId} onChange={e => this.secondaryTesterChange(key, e)} style={{ width: '100%' }} filter placeholder="-Select Secondary Tester-" options={this.state.users} />
                                                </div>
                                            </div>
                                            <div className="col-sm-1 col-xs-2 st-close-b" >
                                                {user.showRemove ?
                                                    <span id="removeAssignee" onClick={() => this.deleteSecondaryAssignee(user.secondaryTesterId, "tester")} style={{ cursor: 'pointer', margin: '25px 10px 0 0' }} className="float-right"><i className="fa fa-times" aria-hidden="true"></i> </span>
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <div className="form-group w30" style={{ paddingLeft: '0' }}>
                                                    <label htmlFor="" className="font12">ST QOE</label>
                                                    <input type="number" value={user.stQOE} onChange={e => this.stQOEChange(key, e)} name="stQOE" min="0" className="form-control" placeholder="Enter ST QOE" />
                                                </div>
                                                <div className="form-group w30" style={{ paddingLeft: '0' }}>
                                                    <label htmlFor="" className="font12">ST QTT</label>
                                                    <input disabled="disabled" type="number" defaultValue={user.stQTT} name="stQTT" min="0" className="form-control" placeholder="Enter ST QTT" />
                                                </div>
                                                <div className="form-group w30" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                    <label htmlFor="" className="font12">ST QTR</label>
                                                    <input disabled="disabled" type="number" defaultValue={user.stQTR} name="stQTR" min="0" className="form-control" placeholder="Enter ST QTR" />
                                                </div>
                                            </div>
                                            {this.state.tasksModel.secondaryTesters.length > 0 && (key + 1) === this.state.tasksModel.secondaryTesters.length && this.state.isAdmin ?
                                                <div className="col-sm-2">
                                                    <span className="plus-btn text-center" onClick={this.addSecondaryTester} ><i className="fa fa-plus" aria-hidden="true"></i> </span>
                                                </div> : ""}
                                        </div>
                                    </div>
                                    :
                                    <div className="row secondary-tester" key={key}>
                                        <div className="col-sm-5">
                                            <div className="form-group">
                                                <label htmlFor="" className="font10">Name</label>
                                                <Dropdown name="stName" showClear value={user.secondaryTesterId} onChange={e => this.secondaryTesterChange(key, e)} style={{ width: '100%' }} filter placeholder="-Select Secondary Tester-" options={this.state.users} />
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="form-group">
                                                <label htmlFor="" className="font10">ST QOE</label>
                                                <input value={user.stQOE} onChange={e => this.stQOEChange(key, e)} type="number" min="0" name="stQOE" className="form-control" placeholder="Enter ST QOE" />
                                            </div>
                                        </div>
                                        {this.state.tasksModel.secondaryTesters.length > 0 && (key + 1) === this.state.tasksModel.secondaryTesters.length ?
                                            <div className="col-sm-2">
                                                <span className="plus-btn text-center" onClick={this.addSecondaryTester} ><i className="fa fa-plus" aria-hidden="true"></i> </span>
                                            </div> : ""}
                                    </div>

                            )}
                        </div>
                    </div>
                    <button id="btnAddTask" type="button" onClick={this.addTask} className="btn btn-primary ctm-btn w-100 float-left mb-15">Submit</button>
                    {this.state.tasksModel.taskId ?
                        <button type="button" id="btnDeleteTask" onClick={(e) => this.deleteTaskById(this.state.tasksModel.taskId)} className="btn btn-danger btn-block mt-2 mb-15 font-weight-bold">Delete this task</button> : ""}
                </form>

                {this.state.tasksModel.taskId > 0 ? <Comments setActiveDropdown={this.setActiveDropdown} hideAddTask={this.hideAddTask} taskModel={this.state.tasksModel} loggedInUserId={apiCommonParams.userid} userList={this.props.userList} isAdmin={this.state.isAdmin} header_data={this.state.header_data}></Comments> : ""}
                </div>
            </div >
        </div >)
    }
}

export default AddTask;