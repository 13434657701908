import React,{useContext} from "react"
import { Dropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

import { useSmarkDispatch, useSmarkState } from "../../Context/KeycloakContext";
import { useKeycloakState } from '../../Context/KeycloakContext';
import './logout.css'

const Logout = () => {
   
    const avatar = '/images/smark-profile-avatar.png';
    const { logout: keycloakLogout } = useKeycloakState();
    const history = useHistory();

    const userDetails = useSmarkState(); //read user details from context
    const dispatch = useSmarkDispatch(); // read dispatch method from context

    const handleLogout = () => {
        keycloakLogout();
        history.push('/');
       
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant="link" id="dropdown-basic" className="user-img cursor-pointer">
                <img src={avatar} alt="" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item><small>Logged in as {userDetails.fullName}</small></Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default Logout;
