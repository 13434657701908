import React, {useContext}from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSmarkState } from "../Context/KeycloakContext";
import { CommonRoutes, RestrictedRoutes } from "./route-config";
import {useKeycloakState } from "../Context/KeycloakContext";

const AppRoutes = () => {
    
    const userDetails = useSmarkState();
    const keycloackValue = useKeycloakState();
    return (
        <Switch>
            {
                CommonRoutes.map((route, index) => {
                    const { component: Component, ...rest } = route;
                    return (
                        <Route {...rest} key={index} render={matchProps => {
                            if (!userDetails.userId) {
                                return (
                                    <Component {...matchProps} />
                                )
                            } else {
                                return (
                                    <Redirect to="/dashboard" key={index} />
                                )
                            }
                        }
                        } />
                    )
                })
            }

            {
                RestrictedRoutes.map((route, index) => {
                    const { component: Component, ...rest } = route;
                    return (
                        <Route {...rest} key={index} render={matchProps => {
                            if (userDetails.userId ) {
                                return (
                                    <Component {...matchProps} />
                                )
                            } else {
                                return (
                                    <Redirect to="/" key={index} />
                                )
                            }
                        }
                        } />
                    )
                })
            }
<Route path='*' exact={true} render={matchProps => {(keycloackValue && keycloackValue.authenticated && userDetails.userId )?<Redirect to="/dashboard" />:<Redirect to="/" />} } />
          
        </Switch>
    )
}

export default AppRoutes;