import React, { useState,useEffect } from 'react';
import './reportheader.css';
import './Report1.css';
import Loader from "react-loader-spinner";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

const Report3= (props) => {
    const [report3, setReport3] = useState(props.r3value);
    const [Range, setRange] = useState(props.dateRange)
    
   
    //const report1 = props.rvalue;
    console.log("RRRRRRRRR=",report3)
    useEffect(() => {
     setReport3(props.r3value);
    }, [props.r3value]);

    $(document).ready(function () {
      setTimeout(function(){
      $('#yelpTable1').DataTable(
         
      );
       } ,10);
  });
  
  $(document).ready(function () {
    setTimeout(function(){
    $('#yelpTable2').DataTable();
     } ,10);
});
$(document).ready(function () {
  setTimeout(function(){
  $('#yelpTable3').DataTable();
   } ,10);
});


    return (
        <>
       
		<div className="inner-scn">
         <div className="container report-container">
               
               <div className="row ">
                  <div className="col-md-12 fxt-bx mar-t">
                     <h4>Report 3</h4>
					 <p>Generated reports for the date {props.dateRange}</p>
                  </div>
               </div>
            </div>
            {(!props.loading) ?
            <>      
 <div className="report-table top-table">	
<div id="tableArea">
	<table id="yelpTable1" className="display dataTable no-footer " style={{width: "100%"}} role="grid" aria-describedby="yelpTable_info">
		
		<thead>
				<tr className="head-style" role="row">
					<th className="sorting" tabindex="0" aria-controls="yelpTable1" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{width: "261px"}}>Net Work Time by People</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable1" rowspan="1" colspan="1" aria-label="Original estimate 1: activate to sort column ascending" style={{width: "260px"}}>Original estimate</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable1" rowspan="1" colspan="1" aria-label="Time taken: activate to sort column ascending" style={{width: "150px"}}>Time taken</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable1" rowspan="1" colspan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Net</th>
				</tr>
				</thead>
				<tbody>
				{
         report3.section3.map((x,index) =>{
             return(
                 <tr key={index} id="GbKN-edIpqhNXH_iItk04w" className="busList selected even" role="row">
                 <td>{x.user}</td>
                 <td>{x.estimateTime}</td>
                 <td>{x.timeTaken}</td>
                 <td>{x.netTime}</td>
              </tr>)
     
         })
         }
		 </tbody>
		 </table>
		 </div>
		 </div>
		 
 <div className="report-table">	
<div id="tableArea">
	<table id="yelpTable2" className="display dataTable no-footer" style={{width: "100%"}} role="grid" aria-describedby="yelpTable_info">
				<thead>		
				<tr className="head-style" role="row">
					<th className="sorting" tabindex="0" aria-controls="yelpTable2" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{width: "261px"}}>Development</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable2" rowspan="1" colspan="1" aria-label="Original estimate 1: activate to sort column ascending" style={{width: "260px"}}>Original estimate</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable2" rowspan="1" colspan="1" aria-label="Time taken: activate to sort column ascending" style={{width: "150px"}}>Time taken</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable2" rowspan="1" colspan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Net</th>
				</tr>
				</thead>
				<tbody>
				{report3.section2.filter(develop => develop.type === "Development").map(x =>{
 return(<tr id="GbKN-edIpqhNXH_iItk04w" className="busList selected even" role="row">
      <td>{x.user}</td>
      <td>{x.estimateTime}</td>
      <td>{x.timeTaken}</td>
      <td>{x.netTime}</td>
     </tr>)})}
				</tbody>
</table>
</div>
</div>		
<div className="report-table">	
<div id="tableArea">
	<table id="yelpTable3" className="display dataTable no-footer bot-table" style={{width: "100%"}} role="grid" aria-describedby="yelpTable_info">
				<thead>				
				<tr className="head-style" role="row">
					<th className="sorting" tabindex="0" aria-controls="yelpTable3" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{width: "261px"}}>Quality Assurance</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable3" rowspan="1" colspan="1" aria-label="Original estimate 1: activate to sort column ascending" style={{width: "260px"}}>Original estimate</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable3" rowspan="1" colspan="1" aria-label="Time taken: activate to sort column ascending" style={{width: "150px"}}>Time taken</th>
					<th className="sorting" tabindex="0" aria-controls="yelpTable3" rowspan="1" colspan="1" aria-label="Net: activate to sort column ascending" style={{width: "110px"}}>Net</th>
				</tr>
				</thead>
				<tbody>
				{report3.section2.filter(develop => develop.type === "Quality assurance").map(x =>{
        return(<tr id="GbKN-edIpqhNXH_iItk04w" className="busList selected even" role="row">
              <td>{x.user}</td>
              <td>{x.estimateTime}</td>
              <td>{x.timeTaken}</td>
              <td>{x.netTime}</td>
             </tr>)})}
		</tbody>
	</table>
</div></div>
           </>
             : <div className="spinner">
             <Loader type="Oval" color="#191f39" height="70" width="70" />
           </div>}
           </div>
         </>
    )

}


export default Report3;
