import React, { useRef, useState, useEffect } from 'react';
import './Report.css';
import ReportHeader from '../../components/reportheader/reportheader';
import TaskBoard from '../../components/task/smarkTaskBoard';
import AddTask from '../../components/task/addTask';
import UserStory from '../../components/userStory/userStory';
import AddUserStory from '../../components/userStory/addUserStory';
import apis from '../../services/apis';
import { useSmarkDispatch, useSmarkState } from '../../Context/KeycloakContext';
import { trackPromise } from 'react-promise-tracker';
import { getTaskList } from '../../helper/context/actions';
import apiCommonParams from '../../constants/apiCommonParams';
import { customFetch, customFetch1 } from '../../helper/api-helper/apiHerlpers';
import apiUrls from '../../constants/apiUrls';
import { format } from 'react-string-format';

const Report= (props) => {
    const contextDetails = useSmarkState();
    const dispatch = useSmarkDispatch();
     const [report1,setReport1] = useState({})
    const [showAddTask, _showHideAddTask] = useState(false);
    const [task, setTask] = useState({});
    const [report, setReport] = useState(null);
    const [showAddUserStory, _showHideAddUserStory] = useState(false);
    const [commentsVisible, _showComments] = useState(false);
    const [userListState, setUserListState] = useState([]);
    const [stateFromHeader, setStateFromHeader] = useState(null);
    const [userStoryDetailState, setUserStoryDetailState] = useState(0);
    const child = useRef();

    const showComments = function () {
        _showComments(!commentsVisible);
    }

    

    async function getUserList() {
        setUserListState(await apis.getUsers())
    }

    const handleOnClick = (e) => {
       if(e.target.value === "report1"){
        setReport("report1");
         }
      else if(e.target.value === "report2"){
        setReport("report2");
      }
      else if(e.target.value === "report3"){
        setReport("report3");
      }
      else if(e.target.value === "report4"){
        setReport("report4");
      }
    }
   
    

    return (
        <div>
            <ReportHeader isAdmin={contextDetails.isAdminUser} userId={contextDetails.userId}></ReportHeader>
            
           {/*  <div className="container">
           
            <div className="row style-row">
               <div className="col-md-12 fxt-bx">
                  <h4>Report 1</h4>
                  <br/>
                  <p>Select project and release to generate report for each project</p>
                 
                  <div className="col-md-12">  
               </div>				  
                     <a className="image-responsive btn-img "><img src="https://sh-design.sitepm.com/web-poc/smark/pp/20221228-02/img/projrct-release-report-1.jpg"  alt="Report"/></a><div value="report1" onClick={handleOnClick}><a href="#">Run Report</a></div>
                  </div>
                 
               <div className="col-md-12">
                  <a className="image-responsive report-image"><img src="https://sh-design.sitepm.com/web-poc/smark/pp/20221228-02/img/image-report-1png.png"/></a>
               </div>
            </div>
         </div>		
		  <div className="container">
           
            <div className="row style-row">
               <div className="col-md-12 fxt-bx">
                  <h4>Report 2</h4>
                  <br/>
                  <p>Select project, release, start date and end date to generate report for each project</p>
                 
                  <div className="col-md-12">  
               </div>				  
                     <a className="image-responsive btn-img"><img src="https://sh-design.sitepm.com/web-poc/smark/pp/20221228-02/img/select-release-date-report-2.jpg"  alt="Report"/></a><div value="report2" onClick={handleOnClick}><a href="#">Run Report</a></div>
                  </div>
                 
               <div className="col-md-12">
                  <a className="image-responsive report-image"><img src="https://sh-design.sitepm.com/web-poc/smark/pp/20221228-02/img/report2.png"/></a>
               </div>
            </div>
         </div>		
		 <div className="container">
           
            <div className="row style-row">
               <div className="col-md-12 fxt-bx">
                  <h4>Report 3</h4>
                  <br/>
                  <p>Select project, release, user, start date and end date to generate report for each project</p>
                 
                  <div className="col-md-12">  
               </div>				  
                     <a className="image-responsive btn-img user-rpt"><img src="https://sh-design.sitepm.com/web-poc/smark/pp/20221228-02/img/release-user.jpg" className="img-report"  alt="Report"/></a><div value="report3" onClick={handleOnClick}><a href="#">Run Report</a></div>
                  </div>
                 
               <div className="col-md-12">
                  <a className="image-responsive report-image"><img src="https://sh-design.sitepm.com/web-poc/smark/pp/20221228-02/img/report3.png"/></a>
               </div>
            </div>
         </div>		
		 <div className="container">
           
            <div className="row style-row">
               <div className="col-md-12 fxt-bx">
                  <h4>Report 4</h4>
                  <br/>
                  <p>Select user, start date and end date to generate report for each project</p>
                 
                  <div className="col-md-12">  
               </div>				  
                     <a className="image-responsive btn-img user-rpt"><img src="https://sh-design.sitepm.com/web-poc/smark/pp/20221228-02/img/user-date.jpg"  alt="Report"/></a><div value="report4" onClick={handleOnClick}><a href="#">Run Report</a></div>
                  </div>
                 
               <div className="col-md-12">
                  <a className="image-responsive report-image report-image-4"><img src="https://sh-design.sitepm.com/web-poc/smark/pp/20221228-02/img/report-4.png"/></a>
               </div>
            </div>
    </div>	*/}	
      </div>
        
    )
}

export default Report;
