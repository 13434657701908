import React from 'react';
import './header.css';
import { Dropdown } from 'primereact/dropdown';
import { Dropdown as BoostrapDropdown } from 'react-bootstrap';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Logout from '../logout/logout';
import MasterSearch from '../masterSearch/masterSearch';
import { withRouter } from 'react-router-dom';
import Project from '../project/Project';
import Release from '../release/Release';
import EditProject from '../editProject/EditProject';
import EditRelease from '../editRelease/EditRelease';
import $ from 'jquery'
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import apis from '../../services/apis';
import { customFetch } from '../../helper/api-helper/apiHerlpers';
import apiUrls from '../../constants/apiUrls';
import { format } from 'react-string-format';
import { useKeycloakState } from '../../Context/KeycloakContext';

class Header extends React.Component {
    
    constructor(props) {
        super(props);
        //Show task if URL have taskId
        var taskId = this.getURLParamByName('t');
        var projectId = null;
        var releaseId = null;
        if (!taskId) {
            projectId = this.getURLParamByName('p');
            releaseId = this.getURLParamByName('r');
        }
       
        const storedToken = localStorage.getItem('keycloakToken');
        this.state = {
            loadTaskHit: 0,
            isAdmin: this.props.isAdmin,
            userId: this.props.userId,
            taskId,
            masterProjectId: projectId,
            masterProjects: [],
            masterReleaseId: releaseId,
            masterReleaseList: [],
            masterUserId: null,
            masterUsers: [],
            loggedInUserName: "Sonu B",
            showStoryList: false,
            showAddUserStory: false,
            addProject: false,
            addRelease: false,
            openProjectList: false,
            openReleaseList: false,
            showAddTask: false,

            showFilterPopup: false,
            filterStartDate: null,
            filterEndDate: null,

            activeFilterType: '',
            recentTaskModifiedDays: '0',
            taskModifiedBeforeDays: '0',
            filterDateRange: '', 
        }
        this.masterProjectChange = this.masterProjectChange.bind(this);
        this.masterReleaseChange = this.masterReleaseChange.bind(this);
        this.masterUserChange = this.masterUserChange.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.showAddTask = props.addTask;
        this.showAddUserStory = props.addUserStory;
        this.showStoryList = props.userStoryList;
        this.addProjectHandler = this.addProjectHandler.bind(this);
        this.hideProjectHandler = this.hideProjectHandler.bind(this);
        this.addReleaseHandler = this.addReleaseHandler.bind(this);
        this.hideReleaseHandler = this.hideReleaseHandler.bind(this);
        this.openProjectListHandler = this.openProjectListHandler.bind(this);
        this.hideEditProjectHandler = this.hideEditProjectHandler.bind(this);
        this.openReleaseListHandler = this.openReleaseListHandler.bind(this);
        this.hideReleaseListHandler = this.hideReleaseListHandler.bind(this);
        this.filterDateRangePickerChange = this.filterDateRangePickerChange.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.loadMasterReleaseDdl = this.loadMasterReleaseDdl.bind(this);
        this.loadMasterProjectDdl = this.loadMasterProjectDdl.bind(this);
        this.onFilterPopupDropdownToggle = this.onFilterPopupDropdownToggle.bind(this);
    }

    onFilterPopupDropdownToggle(showFilterPopup) {
        if (showFilterPopup) {
            this.setState({
                showFilterPopup
            });
        }
        else if (!$('.daterangepicker').is(':visible')) {
            this.setState({
                showFilterPopup
            });
        }
    }

    getURLParamByName(parameterName) {
        var result = null,
            tmp = [];
        window.location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result !== null && !isNaN(parseInt(result)) ? parseInt(result) : result;
    }

    hideReleaseListHandler() {
        this.setState({ openReleaseList: false })
    }
    openReleaseListHandler() {
        this.props.hideAllAddForms();
        this.setState({ openReleaseList: true })
    }
    hideEditProjectHandler() {
        this.setState({ openProjectList: false })
    }
    openProjectListHandler() {
        this.props.hideAllAddForms();
        this.setState({ openProjectList: true })
    }

    hideReleaseHandler() {
        this.setState({ addRelease: false })
    }
    addReleaseHandler() {
        this.props.hideAllAddForms();
        this.setState({ addRelease: true })
    }

    hideProjectHandler(show) {
        this.setState({ addProject: show })
    }

    addProjectHandler() {
        this.props.hideAllAddForms();
        this.setState({ addProject: true })
    }

    setFilter(e) {
        var activeFilter = this.state.activeFilterType;
        $('#filterPopup .form-error').html('');
        if (activeFilter.length === 0) {
            $('#filterPopup .form-error').html('No filter is selected');
            return;
        }
        var filterDays = 1;
        if (activeFilter === 'date-range') {
            if (this.state.filterDateRange === '') {
                $('#filterPopup .form-error').html('Date range is not selected');
                return;
            }
            else {
                var startDate = this.state.filterDateRange.split('-')[0].trim();
                var endDate = this.state.filterDateRange.split('-')[1].trim();
                this.setState({
                    filterStartDate: new Date(startDate + " 00:00:00"),
                    filterEndDate: new Date(endDate + " 23:59:59"),
                });
            }
        }
        else if (activeFilter === 'last-n-days') {
            if (this.state.recentTaskModifiedDays === '0') {
                $('#filterPopup .form-error').html('No of days are not selected');
                return;
            }
            var start = new Date();
            filterDays = parseInt(this.state.recentTaskModifiedDays);
            start.setDate(start.getDate() - filterDays);

            this.setState({
                filterStartDate: new Date(moment(start).format('YYYY-MM-DD') + " 00:00:00"),
                filterEndDate: new Date(moment(new Date()).format('YYYY-MM-DD') + " 23:59:59"),
                filterDateRange: ''
            });
        }
        else if (activeFilter === 'before-n-days') {
            if (this.state.taskModifiedBeforeDays === '0') {
                $('#filterPopup .form-error').html('No of days are not selected');
                return;
            }
            var filterEndDate = new Date();
            filterDays = parseInt(this.state.taskModifiedBeforeDays);
            filterEndDate.setDate(filterEndDate.getDate() - filterDays)
            this.setState({
                filterStartDate: null,
                filterEndDate: new Date(moment(filterEndDate).format('YYYY-MM-DD') + " 23:59:59"),
                filterDateRange: ''
            });
        }

        localStorage.setItem("timeFilter", JSON.stringify({ activeFilterType: activeFilter, filterDays, filterDateRange: this.state.filterDateRange }));

        if (e) $('body').click();
        //$('#filterPopupDropdown, #filterPopupDropdown #filterPopup').removeClass('show'); 

    }

    clearFilter() {
        $('#filterPopup .form-group').removeClass("active-drop-color");
        this.setState({
            filterStartDate: null,
            filterEndDate: null,
            activeFilterType: '',
            recentTaskModifiedDays: '0',
            taskModifiedBeforeDays: '0',
            filterDateRange: ''
        }, function () {
            localStorage.removeItem("timeFilter");
            //$('#filterPopupDropdown, #filterPopupDropdown #filterPopup').removeClass('show'); 
            $('body').click();
        });
    }

    setActiveFilterType(activeFilterType) {
        if (this.state.activeFilterType === activeFilterType) return;
        var recentTaskModifiedDays = '0', taskModifiedBeforeDays = '0', filterDateRange = '';
        if (activeFilterType === "before-n-days") {
            recentTaskModifiedDays = '0';
            filterDateRange = '';
        }
        else if (activeFilterType === 'last-n-days') {
            taskModifiedBeforeDays = '0';
            filterDateRange = '';
        }
        this.setState({
            activeFilterType,
            recentTaskModifiedDays,
            taskModifiedBeforeDays,
            filterDateRange
        });
    }

    filterDateRangePickerChange(start, end) {
        this.setState({
            filterStartDate: start.toDate(),
            filterEndDate: end.toDate(),
            filterDateRange: start.format('MM/DD/YYYY - ') + end.format('MM/DD/YYYY')
        });
    }

    toggleFilter() {
        this.setState({
            showFilterPopup: !this.state.showFilterPopup
        });
    }

    async loadMasterProjectDdl() {
        const storedToken = localStorage.getItem('keycloakToken');
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.setState({
            masterProjects: await apis.getProjects(this.state.userId,this.state.isAdmin,storedToken),
        })
    }

    async loadMasterReleaseDdl() {
        const storedToken = localStorage.getItem('keycloakToken');
        this.setState({
            masterReleaseList: await apis.getReleaseList(this.state.masterProjectId,storedToken),
        });
    }

    async componentWillMount() {
        const storedToken = localStorage.getItem('keycloakToken');
      
        if (this.state.taskId && this.state.taskId > 0) {
            var task = await customFetch((format(apiUrls.getTaskById, this.state.taskId)),storedToken);
            if (task) {
                this.showAddTask(true, task.taskdetailsHB, this.state);
                this.setState({
                    loadTaskHit: ++this.state.loadTaskHit,
                    masterProjectId: task.taskdetailsHB.projectId,
                    masterReleaseId: task.taskdetailsHB.releaseId
                })
            }
        }
        if (localStorage.getItem("timeFilter")) {
            var timeFilter = JSON.parse(localStorage.getItem("timeFilter"));
            if (timeFilter) {
                var recentTaskModifiedDays = '', taskModifiedBeforeDays = '';

                if (timeFilter.activeFilterType === 'last-n-days')
                    recentTaskModifiedDays = timeFilter.filterDays;
                else if (timeFilter.activeFilterType === 'before-n-days')
                    taskModifiedBeforeDays = timeFilter.filterDays;

                if (timeFilter.activeFilterType === 'date-range') {
                    var dates = timeFilter.filterDateRange.split(' - ');
                    this.setState({
                        filterStartDate: new Date(dates[0]),
                        filterEndDate: new Date(dates[0]),
                        filterDateRange: timeFilter.filterDateRange,
                        activeFilterType: timeFilter.activeFilterType,
                        recentTaskModifiedDays,
                        taskModifiedBeforeDays,
                    }, function () {
                        this.setFilter();
                    });
                } else {
                    this.setState({
                        activeFilterType: timeFilter.activeFilterType,
                        recentTaskModifiedDays,
                        taskModifiedBeforeDays,
                    }, function () {
                        this.setFilter();
                    });
                }
            }
        }

        this.setState({
            masterProjects: await apis.getProjects(this.state.userId,this.state.isAdmin,storedToken),
            masterUsers: await apis.getUsers(storedToken),
            isAdmin: this.props.isAdmin
        })
        if (this.state.masterProjectId) {
            this.setState({
                masterReleaseList: await apis.getReleaseList(this.state.masterProjectId,storedToken)
            });
        }

    }

    async masterProjectChange(e) {
        const storedToken = localStorage.getItem('keycloakToken');
        this.setState({
            masterProjectId: e.target.value,
            masterReleaseId: null
        });

        if (e.target.value) {
            this.props.history.push(`/dashboard?p=${e.target.value}`);
        } else {
            this.props.history.push(`/dashboard`);
        }

        this.setState({
            masterReleaseList: await apis.getReleaseList(e.target.value,storedToken),
        });
    }

    masterReleaseChange(e) {
        this.setState({
            masterReleaseId: e.target.value,
        });

        if (e.target.value) {
            this.props.history.push(`/dashboard?p=${this.state.masterProjectId}&r=${e.target.value}`);
        } else if (this.state.masterProjectId) {
            this.props.history.push(`/dashboard?p=${this.state.masterProjectId}`);
        }
    }

    masterUserChange(e) {
        this.setState({
            masterUserId: e.target.value,
        });
    }

    showHeaders() {
        $('.headerDdlMenu').toggleClass('show');
    }

     ReportMethod = (event) => {
        event.preventDefault();
        this.props.history.push("/report");
    }

    render() {
        
        return (<div id="header_area">
            <header id="navbar" className="w-100 float-left top-bar">
                <div className="navbar-nav ml-auto st-m">
                    <Logout />
                </div>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <button onClick={this.showHeaders} className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse headerDdlMenu" id="navbarSupportedContent">
                        <div className="navbar-nav mr-auto">
                            <div>
                                <div className="form-inline">
                                    <div className="4 st-w-100">
                                        <div className="form-group">
                                            <Dropdown className="master-dropdown master-dropdown-first" filter showClear placeholder="Select project" value={this.state.masterProjectId} onChange={this.masterProjectChange} options={this.state.masterProjects} panelClassName="master-dropdown-panel" />
                                        </div>
                                    </div>

                                    <div className="1 st-hide"> &nbsp; </div>
                                    <div className="3 st-w-100">
                                        <div className="form-group">
                                            <Dropdown className="master-dropdown" filter placeholder="Select release" value={this.state.masterReleaseId} onChange={this.masterReleaseChange} options={this.state.masterReleaseList} showClear panelClassName="master-dropdown-panel" />
                                        </div>
                                    </div>
                                    <div className="1 st-hide"> &nbsp; </div>
                                    {this.state.isAdmin ?
                                        <div className="3 st-w-100">
                                            <div className="form-group">
                                                <Dropdown className="master-dropdown" filter showClear placeholder="Select user" value={this.state.masterUserId} onChange={this.masterUserChange} options={this.state.masterUsers} panelClassName="master-dropdown-panel" />
                                            </div>
                                        </div> : ""}
                                    <div className="1 st-hide"> &nbsp; </div>
                                    <BoostrapDropdown id="filterPopupDropdown" onToggle={this.onFilterPopupDropdownToggle} show={this.state.showFilterPopup}>
                                        <BoostrapDropdown.Toggle as="span" title="Date filter" className="btn btn-light st-pdng-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-minus" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <rect x="4" y="5" width="16" height="16" rx="2" />
                                                <line x1="16" y1="3" x2="16" y2="7" />
                                                <line x1="8" y1="3" x2="8" y2="7" />
                                                <line x1="4" y1="11" x2="20" y2="11" />
                                                <line x1="10" y1="16" x2="14" y2="16" />
                                            </svg>
                                        </BoostrapDropdown.Toggle>
                                        <BoostrapDropdown.Menu id="filterPopup" className="p-3 wd-200">
                                            <button type="button" className="btncrossFilterPopup close" aria-label="Close" style={{ display: 'none' }}>
                                                <span aria-hidden="true">&times; </span>
                                            </button>
                                            <div onClick={() => { this.setActiveFilterType('last-n-days') }} data-type="last-n-days" className={this.state.activeFilterType === "last-n-days" ? "form-group form-inline mb-2 active-drop-color" : "form-group form-inline mb-2"}>
                                                <label className="font13"><strong>Recent Task Modified</strong></label>
                                                <select className="ml-1" value={this.state.recentTaskModifiedDays} onChange={(e) => { var value = e.target.value; this.setState({ recentTaskModifiedDays: value }) }}>
                                                    <option>0</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                </select>
                                            </div>
                                            <div className="dropdown-divider"></div>
                                            <div onClick={() => { this.setActiveFilterType('before-n-days') }} data-type="before-n-days" className={this.state.activeFilterType === "before-n-days" ? "form-group form-inline mb-2 active-drop-color" : "form-group form-inline mb-2"}>
                                                <label className="font13"><strong>Task Modified Before</strong></label>
                                                <select className="ml-1" value={this.state.taskModifiedBeforeDays} onChange={(e) => { var value = e.target.value; this.setState({ taskModifiedBeforeDays: value }) }}>
                                                    <option>0</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                    <option>7</option>
                                                </select>
                                            </div>
                                            <div className="dropdown-divider"></div>
                                            <div className="row">
                                                <div className="col-sm-8 filter-input">
                                                    <div onClick={() => { this.setActiveFilterType('date-range') }} data-type="date-range" className={this.state.activeFilterType === "date-range" ? "form-group form-inline mb-2 active-drop-color" : "form-group form-inline mb-2"}>
                                                        <label className="font13"><strong>Date Range</strong></label>
                                                        <DateRangePicker initialSettings={{ autoApply: true, maxDate: new Date() }} onCallback={this.filterDateRangePickerChange}>
                                                            <input type="text" id="daterange" className="form-control" value={this.state.filterDateRange} onChange={() => { }} />
                                                        </DateRangePicker>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 filter-button">
                                                    <button type="button" onClick={this.setFilter} id="setFilter" className="btn btn-sm btn-light border-dark ml-2 btnCloseFilterPopup"><small className="btnCloseFilterPopup">Set</small></button>
                                                    <button type="button" onClick={this.clearFilter} className="btn btn-sm btn-light border-dark ml-2 btnCloseFilterPopup"><small className="btnCloseFilterPopup">Clear</small></button>
                                                </div>
                                            </div>
                                        </BoostrapDropdown.Menu>
                                    </BoostrapDropdown>
                                    <MasterSearch data={this.state} loadTaskHit={this.state.loadTaskHit} getTaskList={this.props.getTaskList} />

                                    <span title="Comments" onClick={this.props.showComments} type="button" className="text-white btn btn-light btn-sm ml-2 cmt-show">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-circle" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
                                            <line x1="12" y1="12" x2="12" y2="12.01" />
                                            <line x1="8" y1="12" x2="8" y2="12.01" />
                                            <line x1="16" y1="12" x2="16" y2="12.01" />
                                        </svg>
                                    </span>
                                    <span title="User stories" onClick={() => this.showStoryList(true, null, this.state, 'openStoryList')} type="button" className="text-white btn btn-light btn-sm ml-2 left-button-top" style={{ display: !(this.state.masterReleaseId) && 'none' }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-border-outer" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <rect x="4" y="4" width="16" height="16" rx="2" />
                                            <line x1="12" y1="8" x2="12" y2="8.01" />
                                            <line x1="8" y1="12" x2="8" y2="12.01" />
                                            <line x1="12" y1="12" x2="12" y2="12.01" />
                                            <line x1="16" y1="12" x2="16" y2="12.01" />
                                            <line x1="12" y1="16" x2="12" y2="16.01" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="navbar-nav ml-auto">
                            <ul className="rt-pnl">
                                {this.state.isAdmin ?
                                    <li>
                                        <BoostrapDropdown>
                                            <BoostrapDropdown.Toggle as="a" className="rt-list add-form-btn" id="dropdown-basic">
                                                <i className="fa fa-list-ul" aria-hidden="true"></i> Edit
                                            </BoostrapDropdown.Toggle>
                                            <BoostrapDropdown.Menu>
                                                <BoostrapDropdown.Item onClick={this.openProjectListHandler}>Project</BoostrapDropdown.Item>
                                                <BoostrapDropdown.Item onClick={this.openReleaseListHandler}>Release</BoostrapDropdown.Item>
                                            </BoostrapDropdown.Menu>
                                        </BoostrapDropdown>
                                    </li> : ""}

                                {/* {this.state.isAdmin ?
                                    <li>
                                        <BoostrapDropdown>
                                            <BoostrapDropdown.Toggle as="a" className="rt-list add-form-btn" id="dropdown-basic">
                                                <i className="fa fa-plus" aria-hidden="true"></i> Add New Project/Release
                                            </BoostrapDropdown.Toggle>
                                            <BoostrapDropdown.Menu>
                                                <BoostrapDropdown.Item onClick={this.addProjectHandler}>Project</BoostrapDropdown.Item>
                                                <BoostrapDropdown.Item onClick={this.addReleaseHandler}>Release</BoostrapDropdown.Item>
                                            </BoostrapDropdown.Menu>
                                        </BoostrapDropdown>
                                    </li> : ""} */}

                                <li>
                                    <BoostrapDropdown>
                                        <BoostrapDropdown.Toggle as="a" className="rt-list add-form-btn" id="dropdown-basic">
                                            <i className="fa fa-plus" aria-hidden="true"></i> Add New
                                        </BoostrapDropdown.Toggle>

                                        <BoostrapDropdown.Menu>
                                            <BoostrapDropdown.Item onClick={() => { this.showAddTask(true, null, this.state) }}>Task</BoostrapDropdown.Item>
                                            <BoostrapDropdown.Item onClick={() => { this.showAddUserStory(true, null, this.state) }}>User Story</BoostrapDropdown.Item>
                                            {
                                                this.state.isAdmin ?
                                                    <>
                                                        <BoostrapDropdown.Item onClick={this.addProjectHandler}>Project</BoostrapDropdown.Item>
                                                        <BoostrapDropdown.Item onClick={this.addReleaseHandler}>Release</BoostrapDropdown.Item>
                                                    </>
                                                    :
                                                    ""
                                            }
                                        </BoostrapDropdown.Menu>
                                    </BoostrapDropdown>
                                </li>
                                {this.state.isAdmin ?
                                    <li>
                                        <div className="">
                                        <a className="rt-list add-form-btn" onClick={this.ReportMethod}>
                                                <i className="fa fa-flag" aria-hidden="true"></i> Report
                                            </a>
                                        </div>
                                    </li> : ""}

                                <li className="hide-m-v">
                                    <Logout />
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

            </header >
            {this.state.addProject ? <Project loadMasterProjectDdl={this.loadMasterProjectDdl} onHideProjectEvent={this.hideProjectHandler}></Project> : ""}
            {this.state.addRelease ? <Release loadMasterReleaseDdl={this.loadMasterReleaseDdl} onHideReleaseEvent={this.hideReleaseHandler}></Release> : ""}
            {this.state.openProjectList ? <EditProject loadMasterProjectDdl={this.loadMasterProjectDdl} val={this.state.openProjectList} projects={this.state.masterProjects} onHideEditProjectEvent={this.hideEditProjectHandler}></EditProject> : ""}
            {this.state.openReleaseList ? <EditRelease loadMasterReleaseDdl={this.loadMasterReleaseDdl} val={this.state.openReleaseList} projects={this.state.masterProjects} onHideEditReleaseEvent={this.hideReleaseListHandler} ></EditRelease> : ""}
        </div >
        );
    }
}

export default withRouter(Header);