import React from 'react';
import './footer.css';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <footer id="footer">
                <div className="footer-container">
                    <p className="pad-lft cpyright smark-cpyright">Copyrights 2010 - {new Date().getFullYear()} Pagematics. All Rights Reserved.</p>
                    <div className="swcm-img">
                        <img className="img-responsive" src="/images/Powered-By-Smartwcm.png" alt="Smartwcm Logo" />
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;