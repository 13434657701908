
const userId = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")).userId
    : "";

const userName = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")).username
    : "";

const fullName = localStorage.getItem("currentUser")
    ? `${JSON.parse(localStorage.getItem("currentUser")).firstName} ${JSON.parse(localStorage.getItem("currentUser")).lastName}`
    : "";

const email = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")).email
    : "";

const token = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")).idToken
    : "";

const isAdmin = localStorage.getItem("currentUser") && JSON.parse(localStorage.getItem("currentUser")).isAdminUser;

export const initialState = {
    userId: "" || userId,
    userName: "" || userName,
    fullName: "" || fullName,
    email: "" || email,
    token: "" || token,
    isAdminUser: false || isAdmin,
    loading: false,
    errorMessage: null,
    taskList: null
};

export const SmarkReducer = (initialState, action) => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true
            };
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                email: action.payload.email,
                token: action.payload.idToken,
                userId: action.payload.userId,
                userName: action.payload.username,
                fullName: `${action.payload.firstName} ${action.payload.lastName}`,
                isAdminUser: action.payload.isAdminUser,
                loading: false
            };
        case "LOGOUT":
            return {
                ...initialState,
                userId: "",
                userName: "",
                fullName: "",
                token: ""
            };

        case "LOGIN_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };

        case "REQUEST_TASK_LIST":
            return {
                ...initialState,
                taskList: action.payload.taskDetails
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};