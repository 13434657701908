import SignIn from '../pages/signIn/Signin';
import SignUp from '../pages/signUp/SignUp';
import ForgotPassword from '../pages/forgotPwd/ForgotPwd';
import Dashboard from '../pages/dashboard/Dashboard';
import Report from '../pages/report/Report';
import ForgotUsername from '../pages/forgotUsername/forgotUsername';

const CommonRoutes = [
    {
        path: '/',
        component: SignIn,
        exact: true
    },
    {
        path: '/signup',
        component: SignUp,
        exact: true,
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        exact: true,
    },
    {
        path: '/forgot-username',
        component: ForgotUsername,
        exact: true,
    }
];

const RestrictedRoutes = [
    {
        path: '/dashboard',
        component: Dashboard,
        exact: true
    },
    {
        path: '/report',
        component:Report,
        exact: true
    }
];

export { CommonRoutes, RestrictedRoutes };
