import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './forgotUsername.css';
import '../signIn/common-style.css';
import '../signIn/login.css';
import { isValidEmail } from '../../helper/utils/utility';
import apiUrls from '../../constants/apiUrls';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import { customFormDataPost } from '../../helper/api-helper/apiHerlpers';
import apiCommonParams from '../../constants/apiCommonParams';

const ForgotUsername = () => {
    const history = useHistory();
    const initialState = {
        email: ''
    };
    const [formState, setFormState] = useState({ ...initialState });
    const [formErrors, setFormErrors] = useState({
        email: ''
    });
    const { email } = formState;

    const onChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });
        let errors = { ...formErrors }
        switch (name) {
            case 'email':
                errors.email = value.length < 1 ? 'This field is required!' : isValidEmail(value) ? '' : 'Email is not valid';
                break;
            default:
                break;
        }
        setFormErrors({ ...formErrors, ...errors });
    }

    const isFormValid = () => {
        let valid = true;
        if (formState.email.length === 0) {
            let errors = { ...formErrors }
            if (formState.email.length === 0) {
                errors.email = 'This field is required!';
            }

            setFormErrors({ ...formErrors, ...errors });
            return false;
        }
        return valid;
    }
    const submitMethod = (event) => {
        event.preventDefault();
        if (isFormValid()) {
            var body = new FormData();
            const data = {
                "state": null,
                "enabled": false,
                "source": null,
                "userVerifiationUUID": null,
                "paswordResetUUID": null,
                "profileImageType": null,
                "updatedTime": null,
                "address1": null,
                "isdCode1": null,
                "createdTime": null,
                "userModified": 0,
                "phone2": null,
                "address2": null,
                "userCreated": 0,
                "city": null,
                "zipCode": null,
                "profileImageUrl": null,
                "parentEmail": null,
                "isdCode2": null,
                "password": null,
                "username": formState.email,
                "userId": 0,
                "userStatus": null,
                "lastName": null,
                "applicationId": 179,
                "firstName": null,
                "email": null,
                "phone1": null,
                "userType": null,
                "passwordExpiryTime": null
            }
            body.append("userData", JSON.stringify(data));
            body.append("siteId", apiCommonParams.siteid);
            body.append("swcmTicket", apiCommonParams.swcmTicket);

            trackPromise(
                customFormDataPost(apiUrls.forgotPswd, body).then((res) => {
                    if (res.response === 'success') {
                        toast.success("Email sent successfully, please check your inbox", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setTimeout(() => {
                            history.push("/");
                        }, 500);
                    } else {
                        toast.warning(res.response, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }

                }).catch((error) => {
                    toast.error("Error", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            );
        }
    }

    const signinMethod = (event) => {
        event.preventDefault();
        history.push("/");
    }

    const signupMethod = (event) => {
        event.preventDefault();
        history.push("/signup");
    }

    const forgotPwdMethod = (event) => {
        event.preventDefault();
        history.push("/forgot-password");
    }

    return (
        // <div className="auth-wrapper">
        //     <div className="auth-inner">
        //         <form>
        //             <h3>Forgot Password</h3>
        //             <div className="form-group">
        //                 <label htmlFor="email">Username or Email address</label>
        //                 <input type="email" name="email" value={email} className="form-control mt-2" placeholder="Enter Username or Email" id="email" onChange={onChange} />
        //                 {formErrors.email.length > 0 && <small className="text-danger">{formErrors.email}</small>}
        //             </div>

        //             <button type="submit" onClick={submitMethod} className="btn btn-primary btn-block mt-3">Submit</button>
        //             <p className="forgot-password mt-2">
        //                 <span className="float-right"><button type="button" className="btn btn-link" onClick={signinMethod}>Sign In?</button></span>
        //             </p>
        //         </form>
        //     </div>
        // </div>

        <div className="login-back login-top">
            <div className="row">
                <div className="container">
                    <div className="col-lg-10 col-md-12 col-md-offset-1 row-eq-height">
                        <div className="col-md-5 col-sm-12 col-xs-12 login-inner login-screen">
                            <h2>Hello...</h2>
                            <p className="text-left login-P-text">Enter your email address to recover your username</p>
                            <hr />
                            <h4>Don't have an account?</h4>
                            <span className="account-button" onClick={signupMethod}>
                                <i className="fa fa-user" aria-hidden="true"></i> Sign Up
                            </span>
                        </div>
                        <div className="col-md-7 col-sm-12 col-xs-12 account-create login-screen">
                            <h2>Forgot Username</h2>
                            <form name="resetPassword" id="resetPassword" method="post">
                                <div className="form-group mar-top-30">
                                    <input type="email" name="email" value={email} className="form-control" id="email" onChange={onChange} placeholder="Email" />
                                    {/* <div id="errordiv"></div> */}
                                    {formErrors.email.length > 0 && <small className="text-danger">{formErrors.email}</small>}
                                </div>
                                <div className="form-group mar-top-10 mt-4">
                                    <div className="g-recaptcha" data-sitekey="6LfGCgkTAAAAAEILYdW8DNVnmwunLgS9JYYSWwgB"><div style={{ width: '304px', height: '78px' }}><div><iframe title="reCAPTCHA" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LfGCgkTAAAAAEILYdW8DNVnmwunLgS9JYYSWwgB&amp;co=aHR0cHM6Ly9zMy51cy1lYXN0LTEuYW1hem9uYXdzLmNvbTo0NDM.&amp;hl=en&amp;v=qljbK_DTcvY1PzbR7IG69z1r&amp;size=normal&amp;cb=gmsl10sik9zq" width="304" height="78" role="presentation" name="a-hcdg4slewkbl" frameBorder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe></div><textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response" style={{ width: '250px', height: '40px', border: '1px solid rgb(193, 193, 193)', margin: '10px 25px', padding: '0px', resize: 'none', display: 'none' }}></textarea></div><iframe style={{ display: 'none' }}></iframe></div>
                                    <div id="nocaptcha" className="error" style={{ display: 'none', color: 'red' }}>Verify yourself before submitting the form</div>
                                    <br />
                                </div>
                                <div className="form-group  mar-top-10 ">
                                    <button type="submit" onClick={submitMethod} className="btn btn-ar btn-primary mar-bot-30">Recover Username</button>
                                </div>
                                <div className="col-md-6 nopad smark-user">
                                    <p className="text-left"><span className="forgot" onClick={signinMethod}> <i className="fa fa-user" aria-hidden="true"></i> Sign In</span></p>
                                </div>
                                <div className="col-md-6 nopad smark-user">
                                    <p className="text-left"><span className="forgot" onClick={forgotPwdMethod} > <i className="fa fa-user" aria-hidden="true"></i> Forgot Password</span></p>
                                </div>
                                <div className="col-md-12">
                                    <img src={process.env.PUBLIC_URL + 'images/smark.png'} alt="Logo" width="150" className="img-responsive pull-right" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotUsername;
